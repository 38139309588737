export const enum CurrentEnvironment {
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MEDIUM_PHONE = 360,
  MAX_PHONE = 415,
  TABLET = 520,
}

export const enum CardPaymentFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const TERMS_OF_USE_LINK = 'https://gismart.com/terms-of-services/'
export const PRIVACY_POLICY_LINK = 'https://gismart.com/privacy-policy/'
export const SUBSCRIPTION_POLICY_LINK = 'https://gismart.com/terms-of-services/'
export const SUPPORT_MAIL = 'support@blessedpaths.com'

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const MIN_PASSWORD_LENGTH = 8

export const enum Color {
  PRIMARY = '#D9945A',
  ERROR = '#ea5873',
  WHITE = '#fff',
  DARK = '#2d3240',
  DARK_SECONDARY = '#2c3131',
  LIGHT = '#81848c',
  BLUE_DARK = '#17202a',
  BLUE_GRAY = '#4C4C4C',
  MIDNIGHT_BLUE = '#7590CE',
  GRAY = '#626262',
  CORAL_GRAY = '#f2f3f5',
  GRAY_SECONDARY = '#aaacb2',
  GRAY_LIGHT = '#e9e9e9',
  RED = '#e85e47',
  BLACK = '#000000',
  SKY_BASE = '#D7DFE5',
  SKY_LIGHT = '#DFE5EA',
  SKY_DARK = '#979CA0',
  INK_DARKEST = '#090A0A',
  INK_LIGHT = '#6C7072',
  GOLD_BASE = '#AD903C',
  GOLD_LIGHTER = '#FFD188',
  GOLD_DARKEST = '#7B6215',
  INK_LIGHTER = '#8C9093',
  GREEN_DARKEST = '#198155',
  GREEN_LIGHTER = '#ECFCE5',
  BLUE_DARKER = '#0065D0',
  BLUE_LIGHTER = '#48A7F8',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum Cohort {
  FLOW_1_BB_COPY = 'flow1_bb_copy',
  FLOW_1_INTRO_FAST = 'flow1_intro_fast',
  FLOW_1_INTRO_FAST2 = 'flow1_intro_fast2',
  FLOW_1_INTRO_LONG = 'flow1_intro_long',
  FLOW_1_INTRO_LONG_2248 = 'flow1_intro_long_2248',
  FLOW_1_INTRO_LONG_2247 = 'flow1_intro_long_2247',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  SUBSCRIPTIONS = 'subscriptions',
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPGRADE = 'upgrade',
  UPSELL = 'upsell',
}

export const enum SubscriptionTagsType {
  NO_TAX = 'no_tax',
}

export const enum OptionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export const enum Locale {
  ENGLISH = 'en',
  SPANISH = 'es',
}

export const APP_NAME = 'blessedpath'
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const DEFAULT_CURRENCY = 'usd'
export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const LANG_QUERY_PARAM = 'lang'
export const KEYWORD_PARAM = 'lp_dynamic'
export const COHORT_QUERY_PARAM = 'cohort'
export const INITIAL_LANGUAGE = 'en'

export const enum BillingCycle {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  TWELVE_MONTHS = 12,
}

export const enum SubscriptionProduct {
  SEVEN_DAY = 7,
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export const enum ScreenName {
  PURCHASE = 'onboarding',
  CHECKOUT = 'checkout',
}

export const enum Language {
  EN = 'en',
}

export const SUPPORTED_LOCALES = ['en']

export const VAT_INCLUDED_COUNTRY = ['US', 'CA', 'AU']

export const EU_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
]

export const enum CountryCode {
  UNITED_STATES = 'us',
  ARGENTINA = 'ar',
  COLOMBIA = 'co',
  PERU = 'pe',
  CHILE = 'cl',
  DOMINICAN_REPUBLIC = 'do',
  URUGUAY = 'uy',
  ECUADOR = 'ec',
  GUATEMALA = 'gt',
  VENEZUELA = 've',
  PARAGUAY = 'py',
  BOLIVIA = 'bo',
  NICARAGUA = 'ni',
  HONDURAS = 'hn',
  COSTA_RICA = 'cr',
  PANAMA = 'pa',
  EL_SALVADOR = 'sv',
  MEXICO = 'mx',
  PUERTO_RICO = 'pr',
  BRAZIL = 'br',
  INDIA = 'in',
  PHILIPPINES = 'ph',
  SOUTH_AFRICA = 'za',
  NIGERIA = 'ng',
  KENYA = 'ke',
  ZAMBIA = 'zm',
  TANZANIA = 'tz',
  ETHIOPIA = 'et',
  ZIMBABWE = 'zw',
  SENEGAL = 'sn',
  NAMIBIA = 'na',
}

export const enum CookieConsentOption {
  STRICTLY_NECESSARY = 'necessary',
  ANALYTICS_AND_PERFORMANCE = 'analytics',
  ADVERTIZING_AND_TARGETING = 'advertising',
  FUNCTIONAL_COOKIES = 'functional_cookies',
}

export const TRIAL_BILLING_CYCLE = {
  SEVEN_DAYS: 7,
}

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const enum UserAnswer {
  YES = 'yes',
  NO = 'no',
}

import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import logo from 'assets/images/logo-xl.png'
import trustedByChristians from 'assets/images/trusted-by-christians.png'

import { Cohort } from 'root-constants'

import { StyledIntroVariant1 as S } from './IntroVariant1.styles'
import { QUESTION } from './constants'

export const IntroVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const uuid = useSelector(selectUUID)
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({ question: QUESTION })
    window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
    dispatch(sendFacebookParamsAction())
    handleContinue('')
  }, [uuid, handleContinue, dispatch])

  const renderTitle = () => {
    switch (cohort) {
      case Cohort.FLOW_1_INTRO_FAST2:
        return t(`onboarding.intro.titleV2`)
      default:
        return t(`onboarding.intro.title`)
    }
  }

  return (
    <S.Root>
      <S.Container>
        <S.Title>{renderTitle()}</S.Title>
        <S.Logo src={logo} alt="BlessedPath" />
        <S.Subtitle>
          <Trans
            i18nKey="onboarding.intro.subtitle"
            components={[<strong />]}
          />
        </S.Subtitle>
        <S.Rating src={trustedByChristians} alt="" />
        <S.Button onClick={handleNextClick}>{t('actions.continue')}</S.Button>

        <S.Terms>
          <p>{t`onboarding.intro.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator />
          <PrivacyPolicyLink />
        </S.Terms>
      </S.Container>
    </S.Root>
  )
}

export const DAILY_PRACTICE_VALUES = {
  RESPECT: 'respect',
  GRATITUDE_AND_THANKFULNESS: 'gratitude_and_thankfulness',
  KINDNESS_AND_COMPARISON: 'kindness_and_comparison',
  HONESTY_AND_INTEGRITY: 'honesty_and_integrity',
  LOYALTY: 'loyalty',
}

export const DAILY_PRACTICE_MAP = [
  {
    value: DAILY_PRACTICE_VALUES.RESPECT,
    text: 'onboarding.dailyPractice.respect',
  },
  {
    value: DAILY_PRACTICE_VALUES.GRATITUDE_AND_THANKFULNESS,
    text: 'onboarding.dailyPractice.gratitudeAndThankfulness',
  },
  {
    value: DAILY_PRACTICE_VALUES.KINDNESS_AND_COMPARISON,
    text: 'onboarding.dailyPractice.kindnessAndComparison',
  },
  {
    value: DAILY_PRACTICE_VALUES.HONESTY_AND_INTEGRITY,
    text: 'onboarding.dailyPractice.honestyAndIntegrity',
  },
  {
    value: DAILY_PRACTICE_VALUES.LOYALTY,
    text: 'onboarding.dailyPractice.loyalty',
  },
]

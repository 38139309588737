import styled from 'styled-components'

import chevronDownIcon from 'assets/images/chevron-down-orange.png'

import { Color } from 'root-constants'

export const StyledPurchase = {
  Root: styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    background: #fafaff;
    min-height: calc(var(--full-height, 100dvh) - 52px);
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
    color: ${Color.BLUE_DARK};
  `,
  List: styled.ul`
    margin-bottom: 16px;
  `,
  ListItem: styled.li`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GRAY};
    margin-bottom: 8px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    & svg {
      flex-shrink: 0;
      margin-right: 8px;
    }
  `,
  Disclaimer: styled.p`
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    color: ${Color.LIGHT};

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  PaymentMethod: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
  `,
  PaymentMethodName: styled.span`
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.BLUE_DARK};
    & svg {
      margin-right: 8px;
    }
  `,
  SwitchPaymentMethod: styled.button`
    all: unset;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.PRIMARY};
    cursor: pointer;
    position: relative;
    padding-right: 24px;

    &::after {
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      background: url(${chevronDownIcon}) no-repeat center;
      background-size: 14px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
  PaymentContainer: styled.div`
    margin-bottom: 24px;
  `,
}

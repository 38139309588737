import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BottomBarPortal } from 'components/BottomBar'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import presenceFemaleImage from 'assets/images/presence-female-image.png'
import presenceMaleImage from 'assets/images/presense-male-image.png'

import { Gender, Language } from 'root-constants'

import { StyledPresence as S } from './Presence.styles'

export const Presence: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)

  const isFemale = userAnswers?.selectGenderV2 === Gender.FEMALE
  const imageUrl = isFemale ? presenceFemaleImage : presenceMaleImage

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.presence.title', { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <>
      <S.Wrapper>
        <S.ImageContainer>
          <S.Image src={imageUrl} />
        </S.ImageContainer>
        <Container>
          <S.Title>
            <Trans i18nKey="onboarding.presence.title" />
          </S.Title>
          <S.Subtitle>{t('onboarding.presence.subtitle')}</S.Subtitle>
        </Container>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          {t('actions.continue')}
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import starSvg from 'assets/images/sprite/star.svg'

import { StyledReviews as S } from './Reviews.styles'

export const Reviews = () => {
  const { t } = useTranslation()
  return (
    <S.Root>
      <Container fields={16}>
        <S.Subtitle>
          <Trans
            i18nKey="purchase2.reviews.subtitle"
            components={[<strong />]}
          />
        </S.Subtitle>
      </Container>
      <S.Emails>
        <S.Email>
          <span>{t('purchase2.reviews.emailAuthor1')}</span>
          <span>{t('purchase2.reviews.emailReceivedTime1')}</span>
        </S.Email>
        <S.Email>
          <span>{t('purchase2.reviews.emailAuthor2')}</span>
          <span>{t('purchase2.reviews.emailReceivedTime2')}</span>
        </S.Email>
      </S.Emails>
      <Container fields={16}>
        <S.Title>{t('purchase2.reviews.title')}</S.Title>
        <S.Review>
          <S.User>
            <S.Name>{t('purchase2.reviews.userName1')}</S.Name>
            <S.Age>{t('purchase2.reviews.userAge1')}</S.Age>
          </S.User>
          <S.Rating>
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
          </S.Rating>
          <S.Feedback>{t('purchase2.reviews.userFeedback1')}</S.Feedback>
        </S.Review>

        <S.Review>
          <S.User>
            <S.Name>{t('purchase2.reviews.userName2')}</S.Name>
            <S.Age>{t('purchase2.reviews.userAge2')}</S.Age>
          </S.User>
          <S.Rating>
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
          </S.Rating>
          <S.Feedback>{t('purchase2.reviews.userFeedback2')}</S.Feedback>
        </S.Review>

        <S.Review>
          <S.User>
            <S.Name>{t('purchase2.reviews.userName3')}</S.Name>
            <S.Age>{t('purchase2.reviews.userAge3')}</S.Age>
          </S.User>
          <S.Rating>
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
            <SvgImage svg={starSvg} />
          </S.Rating>
          <S.Feedback>{t('purchase2.reviews.userFeedback3')}</S.Feedback>
        </S.Review>
      </Container>
    </S.Root>
  )
}

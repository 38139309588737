import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledTransformFaith = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 375px;
    padding: 16px 24px 0;
    margin: 0 auto;
  `,
  Title: styled.h1`
    width: 327px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
  `,
  Subtitle: styled.h3`
    width: 327px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    padding: 8px 12px 0;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  `,
  Image: styled.img`
    width: 90%;
  `,
}

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.DARK,
    fontFamily: 'Inter,  sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: Color.INK_LIGHTER,
      fontWeight: 400,
    },
  },
  invalid: {
    color: Color.ERROR,
  },
}

export const commonInputStyles = css`
  color: ${Color.INK_DARKEST};
  height: 48px;
  background-color: ${Color.WHITE};
  border-radius: 8px;
  border: 1px solid ${Color.SKY_DARK};
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;
  padding: 12px 12px 12px;
  transition: border-color 0.2s ease-out;

  &[data-invalid='true'],
  &.StripeElement--invalid {
    border-color: ${Color.ERROR};
  }
`

export const StyledCardPaymentFormSeparateCheckoutLong = {
  Wrapper: styled.div`
    &[data-is-visible='false'] {
      display: none;
    }
    width: 327px;
    border-radius: 16px;
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    background-color: ${Color.WHITE};
  `,
  PaymentInformationContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  `,
  PaymentInformationText: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
  `,
  Form: styled.form`
    position: relative;
  `,
  Label: styled.p`
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: ${Color.INK_LIGHT};
    padding-bottom: 6px;
  `,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardExpiryContainer: styled.div`
    width: calc(50% - 5px);
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    width: 100%;
    outline: none;
    box-shadow: none;
    font-weight: 500;

    ::placeholder {
      color: ${Color.LIGHT};
      font-weight: 400;
    }
  `,
  StyledButton: styled(Button)`
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 48px;
    width: 100%;
    height: 48px;
    background-color: ${Color.INK_DARKEST};
    color: ${Color.GOLD_LIGHTER};
    padding: 16px 32px;
    transition: background-color 0.2s ease-out;

    &:disabled {
      background-color: ${Color.SKY_LIGHT};
      color: ${Color.SKY_DARK};
    }
  `,
}

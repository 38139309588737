import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

import { EMAIL_REGEXP } from 'root-constants'

export const useEmailInputField = (initialValue = ''): TInputFieldHook => {
  const { t } = useTranslation()
  const [email, setEmail] = useState<IInputFieldState>({
    value: initialValue,
    isValid: true,
    isFocused: false,
    validationText: '',
  })

  useEffect(() => {
    if (!EMAIL_REGEXP.test(email.value) && email.value !== '') {
      setEmail((prevState) => ({
        ...prevState,
        isValid: false,
      }))
      return
    }

    setEmail((prevState) => ({
      ...prevState,
      isValid: true,
    }))
  }, [email.value])

  useEffect(() => {
    setEmail((prevState) => ({
      ...prevState,
      validationText: !email.isValid ? t`signup.invalidEmail` : '',
    }))
  }, [email.isFocused, email.isValid, t])

  return [email, setEmail]
}

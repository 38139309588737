import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SOCIAL_CONFIDENCE_TYPE } from 'pages/questions/SocialConfidence/constants'

import { BottomBarPortal } from 'components/BottomBar'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'

import { StyledConfidenceResult as S } from './ConfidenceResult.styles'
import { CONFIDENCE_RESULT_MAP, QUESTION } from './constants'

export const ConfidenceResult: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)
  const socialConfidence = answers?.[PageId.SOCIAL_CONFIDENCE] as string

  const socialConfidenceResult =
    CONFIDENCE_RESULT_MAP[socialConfidence] ||
    CONFIDENCE_RESULT_MAP[SOCIAL_CONFIDENCE_TYPE.NOT_AT_ALL]

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <>
      <S.Wrapper>
        <S.ImageContainer>
          <S.Image src={socialConfidenceResult.imageUrl} />
        </S.ImageContainer>
        <Container>
          <S.Title>{t(socialConfidenceResult.title)}</S.Title>
          <S.Subtitle>{t(socialConfidenceResult.subtitle)}</S.Subtitle>
        </Container>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

import styled from 'styled-components'

import { Button as ButtonBase } from 'components/Button'

import introBackground from 'assets/images/intro-bg.jpg'

import { Color } from 'root-constants'

export const StyledIntroVariant1 = {
  Root: styled.div`
    position: relative;
    background: url(${introBackground}) no-repeat center;
    background-size: cover;
  `,
  Container: styled.div`
    max-width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px 16px;
    min-height: calc(var(--full-height) - 52px);
    height: 100%;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-family: 'Open Sans';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    margin: 32px 0 36px 0;
    color: ${Color.BLUE_DARK};
  `,
  Logo: styled.img`
    max-width: 120px;
    height: auto;
    vertical-align: top;
    margin-bottom: 24px;
  `,
  Subtitle: styled.h3`
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 36px;

    & strong {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-decoration-line: underline;
    }
  `,
  Rating: styled.img`
    margin-bottom: 16px;
  `,
  Terms: styled.div`
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: ${Color.GRAY};
    margin: 0 auto 16px;

    a {
      color: ${Color.GRAY};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.GRAY};
    margin: 0 5px;
    vertical-align: middle;
  `,
  Button: styled(ButtonBase)`
    margin: auto 0 16px;
  `,
}

import { DEFAULT_PAGE_CONFIG, PAGES_CONFIG } from 'pages-config'

import { PageId } from 'page-constants'

import { useGetPageInfo } from './useGetPageInfo'

export function usePageConfig() {
  const { currentPageId, isFirstPage } = useGetPageInfo()

  const pageConfig =
    PAGES_CONFIG[currentPageId as PageId] ?? DEFAULT_PAGE_CONFIG

  return {
    ...pageConfig,
    isBackButtonDisabled: pageConfig.isBackButtonDisabled || isFirstPage,
  }
}

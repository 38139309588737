import React, { InputHTMLAttributes, useState } from 'react'

import { ISvg, SvgImage } from 'components/SvgImage'

import eyeCloseIcon from 'assets/images/sprite/close-eye-password-icon.svg'
import eyeOpenIcon from 'assets/images/sprite/open-eye-password-icon.svg'

import { StyledInput as S } from './Input.styles'

const INPUT_TYPE = {
  PASSWORD: 'password',
  TEXT: 'text',
} as const

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  value: string
  labelName: string
  isValid?: boolean
  validationText?: string
  iconSvg?: ISvg
  hasVisibilityControl?: boolean
}

export const Input: React.FC<TProps> = ({
  value,
  labelName,
  isValid = true,
  validationText = '',
  hasVisibilityControl = false,
  iconSvg,
  ...props
}) => {
  const [isPasswordTextVisible, setIsPasswordTextVisible] = useState(false)

  const inputType =
    hasVisibilityControl && !isPasswordTextVisible
      ? INPUT_TYPE.PASSWORD
      : INPUT_TYPE.TEXT

  const passwordIconToRender =
    inputType === INPUT_TYPE.PASSWORD ? eyeCloseIcon : eyeOpenIcon

  return (
    <S.Wrapper>
      <S.Label>{labelName}</S.Label>

      <S.InputContainer>
        {iconSvg && <S.Icon svg={iconSvg} width={24} />}
        <S.Input
          type={inputType}
          value={value}
          hasVisibilityControl={hasVisibilityControl}
          {...props}
        />
        {hasVisibilityControl && (
          <S.VisibilityButton
            type="button"
            onClick={() => setIsPasswordTextVisible((prev) => !prev)}
          >
            <SvgImage svg={passwordIconToRender} width={24} />
          </S.VisibilityButton>
        )}
      </S.InputContainer>

      {!isValid && validationText && (
        <S.ErrorMessage>{validationText}</S.ErrorMessage>
      )}
    </S.Wrapper>
  )
}

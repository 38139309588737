export const LIFE_VISION_VALUES = {
  CLOSER_TO_GOD: 'closer_to_god',
  RENEWED_FAITH: 'renewed_faith',
  STRONGER_RELATIONSHIPS: 'stronger_relationships',
  CLEARER_PURPOSE: 'clearer_purpose',
  GREATER_SELF_DISCIPLINE: 'greater_self_discipline',
  MORE_INNER_PEACE: 'more_inner_peace',
}

export const LIFE_VISION_MAP = [
  {
    value: LIFE_VISION_VALUES.CLOSER_TO_GOD,
    text: 'onboarding.lifeVision.closerToGod',
  },
  {
    value: LIFE_VISION_VALUES.RENEWED_FAITH,
    text: 'onboarding.lifeVision.renewedFaith',
  },
  {
    value: LIFE_VISION_VALUES.STRONGER_RELATIONSHIPS,
    text: 'onboarding.lifeVision.strongerRelationships',
  },
  {
    value: LIFE_VISION_VALUES.CLEARER_PURPOSE,
    text: 'onboarding.lifeVision.clearerPurpose',
  },
  {
    value: LIFE_VISION_VALUES.GREATER_SELF_DISCIPLINE,
    text: 'onboarding.lifeVision.greaterSelfDiscipline',
  },
  {
    value: LIFE_VISION_VALUES.MORE_INNER_PEACE,
    text: 'onboarding.lifeVision.moreInnerPeace',
  },
]

export const QUESTION = 'I feel like I am capable of much more professionally.'

export const PROFESSIONAL_CONFIDENCE_TYPE = {
  STRONGLY_DISAGREE: 'strongly_disagree',
  SOMEWHAT_DISAGREE: 'somewhat_disagree',
  SOMEWHAT_AGREE: 'somewhat_agree',
  STRONGLY_AGREE: 'strongly_agree',
}
export const PROFESSIONAL_CONFIDENCE_OPTION = [
  {
    value: PROFESSIONAL_CONFIDENCE_TYPE.STRONGLY_DISAGREE,
    text: 'onboarding.professionalConfidence.stronglyDisagree',
  },
  {
    value: PROFESSIONAL_CONFIDENCE_TYPE.SOMEWHAT_DISAGREE,
    text: 'onboarding.professionalConfidence.somewhatDisagree',
  },
  {
    value: PROFESSIONAL_CONFIDENCE_TYPE.SOMEWHAT_AGREE,
    text: 'onboarding.professionalConfidence.somewhatAgree',
  },
  {
    value: PROFESSIONAL_CONFIDENCE_TYPE.STRONGLY_AGREE,
    text: 'onboarding.professionalConfidence.stronglyAgree',
  },
]

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { PaymentMethod } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'

import { eventLogger } from 'services/eventLogger.service'

import applePayIconGray from 'assets/images/sprite/apple-pay-mini-gray.svg'
import applePayIcon from 'assets/images/sprite/apple-pay-mini.svg'
import crossIcon from 'assets/images/sprite/close-icon-dark.svg'
import creditCardIconGray from 'assets/images/sprite/credit-card-gray.svg'
import creditCardIcon from 'assets/images/sprite/credit-card.svg'
import googlePayIconGray from 'assets/images/sprite/google-pay-mini-gray.svg'
import googlePayIcon from 'assets/images/sprite/google-pay-mini.svg'

import { StyledSelectPaymentMethod as S } from './SelectPaymentMethod.styles'

type TSelectPaymentMethod = {
  onClose: () => void
  onChange: (event) => void
  activePaymentButton: PaymentMethod.APPLE_PAY | PaymentMethod.GOOGLE_PAY | null
}

export const SelectPaymentMethod: React.FC<TSelectPaymentMethod> = ({
  onClose,
  onChange,
  activePaymentButton,
}) => {
  const { t } = useTranslation()

  const { paymentMethod } = usePurchaseStore()

  const isApplePaySelected = paymentMethod === PaymentMethod.APPLE_PAY
  const isGooglePaySelected = paymentMethod === PaymentMethod.GOOGLE_PAY
  const isCreditCardSelected = paymentMethod === PaymentMethod.CREDIT_CARD

  const handleClose = useCallback(() => {
    eventLogger.logPaymentMethodPopupTap()
    onClose()
  }, [onClose])

  return (
    <S.SelectPaymentMethod>
      <S.SelectPaymentMethodPanel>
        <Container fields={16}>
          <S.SelectPaymentMethodHeader>
            <S.SelectPaymentMethodTitle>
              {t('purchase.payment.selectPaymentMethodTitle')}
            </S.SelectPaymentMethodTitle>
            <S.CloseButton onClick={handleClose}>
              <SvgImage svg={crossIcon} width={24} />
            </S.CloseButton>
          </S.SelectPaymentMethodHeader>

          <S.SelectPaymentMethodControls>
            {activePaymentButton === PaymentMethod.APPLE_PAY && (
              <S.Label>
                <S.Checkbox
                  type="radio"
                  name="paymentMethod"
                  onChange={onChange}
                  defaultChecked={isApplePaySelected}
                  value={PaymentMethod.APPLE_PAY}
                />
                <S.PaymentMethodControl>
                  <SvgImage
                    svg={isApplePaySelected ? applePayIcon : applePayIconGray}
                    width={30}
                  />
                  <span>
                    {t('purchase.payment.displayedPaymentMethodName.applePay')}
                  </span>
                </S.PaymentMethodControl>
              </S.Label>
            )}
            {activePaymentButton === PaymentMethod.GOOGLE_PAY && (
              <S.Label>
                <S.Checkbox
                  type="radio"
                  name="paymentMethod"
                  onChange={onChange}
                  defaultChecked={isGooglePaySelected}
                  value={PaymentMethod.GOOGLE_PAY}
                />
                <S.PaymentMethodControl>
                  <SvgImage
                    svg={
                      isGooglePaySelected ? googlePayIcon : googlePayIconGray
                    }
                    width={32}
                  />
                  <span>
                    {t('purchase.payment.displayedPaymentMethodName.googlePay')}
                  </span>
                </S.PaymentMethodControl>
              </S.Label>
            )}
            <S.Label>
              <S.Checkbox
                type="radio"
                name="paymentMethod"
                value={PaymentMethod.CREDIT_CARD}
                onChange={onChange}
                defaultChecked={isCreditCardSelected}
              />
              <S.PaymentMethodControl>
                <SvgImage
                  svg={
                    isCreditCardSelected ? creditCardIcon : creditCardIconGray
                  }
                  width={15}
                />
                <span>
                  {t('purchase.payment.displayedPaymentMethodName.creditCard')}
                </span>
              </S.PaymentMethodControl>
            </S.Label>
          </S.SelectPaymentMethodControls>
        </Container>
      </S.SelectPaymentMethodPanel>
    </S.SelectPaymentMethod>
  )
}

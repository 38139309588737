import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PinnedButtonContainer } from 'common-styles'

import { StyledFindGuidance as S } from './FindGuidance.styles'
import { QUESTION } from './constants'

export const FindGuidance: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <S.Root>
      <Container>
        <Carousel
          style={{ maxHeight: '386px', paddingTop: '38px' }}
          slidesPerView="auto"
          loop
          speed={5000}
          direction="vertical"
          spaceBetween={38}
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <S.Card>
              <S.CardTitle>
                {t('onboarding.findGuidance.card1.title')}
              </S.CardTitle>
              <p>
                <Trans
                  i18nKey="onboarding.findGuidance.card1.description"
                  components={[<br />]}
                />
              </p>
            </S.Card>
          </SwiperSlide>
          <SwiperSlide>
            <S.Card>
              <S.CardTitle>
                {t('onboarding.findGuidance.card2.title')}
              </S.CardTitle>
              <p>
                <Trans
                  i18nKey="onboarding.findGuidance.card2.description"
                  components={[<br />]}
                />
              </p>
            </S.Card>
          </SwiperSlide>
          <SwiperSlide>
            <S.Card>
              <S.CardTitle>
                {t('onboarding.findGuidance.card3.title')}
              </S.CardTitle>
              <p>
                <Trans
                  i18nKey="onboarding.findGuidance.card3.description"
                  components={[<br />]}
                />
              </p>
            </S.Card>
          </SwiperSlide>
          <SwiperSlide>
            <S.Card>
              <S.CardTitle>
                {t('onboarding.findGuidance.card4.title')}
              </S.CardTitle>
              <p>
                <Trans
                  i18nKey="onboarding.findGuidance.card4.description"
                  components={[<br />]}
                />
              </p>
            </S.Card>
          </SwiperSlide>
        </Carousel>
        <S.Content>
          <S.Title>{t('onboarding.findGuidance.title')}</S.Title>
        </S.Content>
      </Container>
      <PinnedButtonContainer>
        <Button onClick={() => handleContinue('')}>
          {t('actions.continue')}
        </Button>
      </PinnedButtonContainer>
    </S.Root>
  )
}

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Option } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { SelectSubscriptionItem } from 'modules/purchase/components/SelectSubscriptionItem'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { OptionType } from 'root-constants'

import { StyledSubscriptionsBlock as S } from './SubscriptionsBlock.styles'

type TSubscriptionsBlockProps = {
  className?: string
}

export const SubscriptionsBlock: React.FC<TSubscriptionsBlockProps> = ({
  className = '',
}) => {
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  const handleChange = useCallback(
    (value: string) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper className={className}>
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
          withoutMargin
        >
          <SelectSubscriptionItem
            subscription={subscription}
            isSelected={subscription.id === selectedSubscriptionId}
          />
        </Option>
      ))}
    </S.Wrapper>
  )
}

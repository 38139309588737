import { ProgressHTMLAttributes } from 'react'

import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

interface IProps extends ProgressHTMLAttributes<HTMLProgressElement> {
  $color?: string
  $background?: string
}

export const StyledProgressBar = {
  Wrapper: styled(Container)`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 327px;
    z-index: 1;
    padding-top: 8px;
    background-color: ${Color.SKY_BASE};
  `,
  StepBlock: styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  `,
  StepBlockProgress: styled.progress<IProps>`
    width: 100%;
    overflow: hidden;
    background-color: ${Color.WHITE};
    height: 4px;
    appearance: none;
    border: none;
    display: block;
    color: ${Color.PRIMARY};
    border-radius: 100px;

    &::-webkit-progress-bar {
      background-color: ${Color.WHITE};
    }

    &::-webkit-progress-value {
      background-color: ${Color.GOLD_BASE};
      border-radius: 100px;
      transition: width 0.3s ease-out;
    }

    &[data-active='true'] {
      &::-webkit-progress-value {
        background-color: ${Color.GOLD_BASE};
      }
    }
  `,
}

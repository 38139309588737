import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { INSPIRATION_PERSON_TYPE } from 'pages/questions/InspirationPerson/constants'

import { BottomBarPortal } from 'components/BottomBar'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'

import { StyledInspirationPersonDetails as S } from './InspirationPersonDetails.styles'
import { INSPIRATION_PERSON_MAP, QUESTION } from './constants'

export const InspirationPersonDetails: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)
  const inspirationPerson = answers?.[PageId.INSPIRATION_PERSON] as string

  const personalityResult =
    INSPIRATION_PERSON_MAP[inspirationPerson] ||
    INSPIRATION_PERSON_MAP[INSPIRATION_PERSON_TYPE.MATTHEW]

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <>
      <S.Wrapper>
        <S.ImageContainer>
          <S.Image src={personalityResult.imageUrl} />
        </S.ImageContainer>
        <Container>
          <S.Title>{t('onboarding.inspirationPersonDetails.title')}</S.Title>
          <S.Subtitle>{t(personalityResult.subtitle)}</S.Subtitle>
        </Container>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

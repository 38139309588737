import styled from 'styled-components'

import greenStarIcon from 'assets/images/green-star-icon.png'

import { StyledButtonBase } from 'common-styles'
import { Color } from 'root-constants'

export const StyledDownload = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 15px;
  `,
  Status: styled.h2`
    max-width: 281px;
    margin: 40px auto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    background: url(${greenStarIcon}) no-repeat left center;
    background-size: 32px;
    padding-left: 44px;
  `,
  Title: styled.h1`
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: ${Color.GRAY};
    margin-bottom: 40px;
  `,
  ImageContainer: styled.figure`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 295px;
    margin: 0 auto 63px;
    background: ${Color.WHITE};
    border-radius: 24px;
    padding: 32px 0;
    box-shadow: 0px 8px 20px 0px #e8e8e8;
  `,
  Image: styled.img`
    max-width: 120px;
    margin-bottom: 24px;
  `,
  ImageCaption: styled.figcaption`
    font-size: 17px;
    font-style: normal;
    color: ${Color.BLUE_DARK};
    font-weight: 600;
    line-height: 24px;
  `,
  Description: styled.span`
    padding-bottom: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
  `,
  Button: styled.button`
    ${StyledButtonBase};
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  `,
}

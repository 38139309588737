import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledProfessionalConfidence = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 375px;
    padding: 36px 24px 0;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
    text-align: center;
  `,
  Subtitle: styled.h3`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
    text-align: center;
    padding: 8px 0 24px;
  `,
}

import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type TProps = {
  children: React.ReactNode
}

export const BottomBarPortal = ({ children }: TProps) => {
  const [bottomBarElement, setBottomBarElement] = useState<HTMLElement | null>(
    null,
  )

  useEffect(() => {
    setBottomBarElement(document.getElementById('bottom-bar'))
  }, [])

  return bottomBarElement ? createPortal(children, bottomBarElement) : null
}

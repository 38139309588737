import styled from 'styled-components'

import checkIcon from 'assets/images/check-icon-gold.svg'

import { Color } from 'root-constants'

export const StyledHighlightsV1 = {
  HighlightsTitle: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin: 32px 0 16px;
    text-align: center;
  `,
  Highlights: styled.ul`
    margin-bottom: 32px;
  `,
  Highlight: styled.li`
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 24px;
    margin-bottom: 16px;
    color: ${Color.INK_DARKEST};

    & strong {
      color: ${Color.BLUE_DARK};
    }

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url(${checkIcon}) no-repeat center;
      background-size: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
}

import { getPurchaseCommonEventParams } from 'modules/purchase/helpers/getPurchaseCommonEventParams'

import { IAppState } from 'models/store.model'

export const getPurchaseFailedEventParams = (state: IAppState) => {
  const {
    productId,
    stripePriceId,
    trialPeriodPrice,
    currency,
    screenName,
    trialPriceId,
    fullPrice,
    stripeAccountId,
    stripeAccountName,
  } = getPurchaseCommonEventParams(state)

  return {
    productId: trialPriceId ? productId : stripePriceId,
    priceDetails: {
      price: fullPrice,
      trial: !!trialPeriodPrice,
      currency,
    },
    screenName,
    stripeAccountId,
    stripeAccountName,
  }
}

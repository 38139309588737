import React from 'react'
import { useTranslation } from 'react-i18next'

import { Option } from 'components/Option'
import { SvgImage } from 'components/SvgImage'

import iconAccept from 'assets/images/sprite/accept.svg'
import iconDecline from 'assets/images/sprite/x.svg'

import { UserAnswer } from 'root-constants'

import { StyledDecisionButtons as S } from './DecisionButtons.styles'

export type TProps = {
  disabled?: boolean
  onChange: (answer: UserAnswer) => void
  backgroundColor?: string
  activeBackgroundColor?: string
  padding?: string
}

export const DecisionButtons: React.FC<TProps> = ({
  disabled,
  onChange,
  backgroundColor,
  activeBackgroundColor,
  padding,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <S.Container $padding={padding}>
      <Option
        value={UserAnswer.NO}
        onChange={() => onChange && onChange(UserAnswer.NO)}
        disabled={disabled}
        withoutMargin
        {...rest}
      >
        <S.Button
          $disabled={disabled}
          $backgroundColor={backgroundColor}
          $activeBackgroundColor={activeBackgroundColor}
        >
          <SvgImage svg={iconDecline} width={24} /> {t('commonComponents.no')}
        </S.Button>
      </Option>

      <Option
        value={UserAnswer.YES}
        onChange={() => onChange && onChange(UserAnswer.YES)}
        disabled={disabled}
        withoutMargin
        {...rest}
      >
        <S.Button
          $disabled={disabled}
          $backgroundColor={backgroundColor}
          $activeBackgroundColor={activeBackgroundColor}
        >
          <SvgImage svg={iconAccept} width={24} /> {t('commonComponents.yes')}
        </S.Button>
      </Option>
    </S.Container>
  )
}

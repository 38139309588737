import styled, { keyframes } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

const fadeInSlide = keyframes`
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

const fadeOutSlide = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(30px);
  }
`

export const StyledAnalyzingAnswers = {
  ProgressContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-bottom: 32px;
  `,
  Container: styled(Container)`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px 24px 0;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    padding-bottom: 24px;
  `,
  Subtitle: styled.h2`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
    text-align: center;
    padding-bottom: 4px;
  `,
  ReviewWrapper: styled.div<{ $isVisible: boolean }>`
    animation: ${({ $isVisible }) => ($isVisible ? fadeOutSlide : fadeInSlide)}
      0.3s ease-in-out;
  `,
  BoldTitleText: styled.strong`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
  `,
  BoldModalTitle: styled.span`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
  `,
}

import React from 'react'

import brands from 'assets/images/brands.png'

import { StyledFeatured as S } from './Featured.styles'

export const Featured = () => {
  return (
    <S.Root>
      <S.Title>As featured in:</S.Title>
      <img src={brands} alt="" />
    </S.Root>
  )
}

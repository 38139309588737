import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import { selectActionList, selectScreenName } from 'root-redux/selects/common'

import { useVatInfo } from 'hooks/useHasVatInfo'
import { useScrollToTop } from 'hooks/useScrollToTop'

import { createProductId } from 'helpers/createProductId'
import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import {
  CURRENCY_SYMBOLS,
  PaymentMethod,
  PaymentSystem,
  TextPosition,
} from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  CHECK_PAYPAL_REQUEST_BUTTON,
  setAvailableAlternativePaymentMethods,
} from 'modules/purchase/redux/actions/common'
import {
  select3DSecureIframeUrl,
  selectAvailablePaymentMethods,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import paymentServicesImage from 'assets/images/sprite/payment-services.svg'

import { goBack } from 'browser-history'
import { SubscriptionProduct } from 'root-constants'

import { CardPaymentFormSeparateCheckoutLong } from '../CardPaymentFormSeparateCheckout/CardPaymentFormSeparateCheckoutLong'
import { PaymentMethodOption } from '../PaymentMethodOption'
import { PaymentWaitingModalLong } from '../PaymentWaitingModal/PaymentWaitingModalLong'
import { PrimerPayPalButton } from '../PrimerPayPalButton'
import { StyledCheckoutSeparateMethodsLong as S } from './CheckoutSeparateMethodsLong.styles'
import {
  PAYMENT_METHODS_IMAGES_LONG,
  PAYMENT_METHODS_IMAGES_LONG_ALT,
  SUPPORTED_DEFAULT_ALTERNATIVE_PAYMENT_METHODS,
  SUPPORTED_DIGITAL_WALLET_PAYMENT_METHODS,
} from './constants'

type TCheckoutExtended = {
  paymentSystem?: PaymentSystem
  selectedSubscription: ISubscription
}

export const CheckoutSeparateMethodsLong: React.FC<TCheckoutExtended> = ({
  paymentSystem = PaymentSystem.STRIPE,
  selectedSubscription,
}: TCheckoutExtended) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const fetchingActionsList = useSelector(selectActionList)
  const hasVatInfo = useVatInfo()
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const screenName = useSelector(selectScreenName)
  const availableAlternativePaymentMethods = useSelector(
    selectAvailablePaymentMethods,
  )

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] =
    useState<boolean>(false)

  useScrollToTop()

  const {
    periodName,
    periodQuantity,
    trialPrice,
    price,
    currency,
    stripeAccountId,
    stripeAccountName,
    taxAmount,
    discountAmount,
    discountPercentage,
  } = usePurchaseStore()

  const isCheckoutReady =
    !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON) &&
    !fetchingActionsList.includes(CHECK_PAYPAL_REQUEST_BUTTON)

  const isCardSelected = selectedPaymentMethod === PaymentMethod.CREDIT_CARD
  const isPayPalSelected = selectedPaymentMethod === PaymentMethod.PAYPAL

  const isAppleGooglePayAvailable = (
    paymentMethods: PaymentMethod[],
  ): boolean =>
    SUPPORTED_DIGITAL_WALLET_PAYMENT_METHODS.some((paymentMethod) =>
      paymentMethods.includes(paymentMethod),
    )

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || price,
      }),
    [periodName, periodQuantity, trialPrice, price],
  )

  const getCalculatedPrice = useCallback(
    (fullPrice: number) => {
      if (!hasVatInfo) return fullPrice

      return Number(getCalculatedPriceWithoutVAT(fullPrice, taxAmount))
    },
    [hasVatInfo, taxAmount],
  )

  const handleBackClick = () => {
    eventLogger.logPaymentMethodPopupTap()
    goBack()
  }

  useLayoutEffect(() => {
    const selectedMethod = SUPPORTED_DEFAULT_ALTERNATIVE_PAYMENT_METHODS.find(
      (method) => availableAlternativePaymentMethods.includes(method),
    )

    setSelectedPaymentMethod(selectedMethod || PaymentMethod.CREDIT_CARD)
  }, [availableAlternativePaymentMethods])

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }
  }, [productId, screenName, stripeAccountId, stripeAccountName])

  const updateAvailablePaymentMethods = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (!availableAlternativePaymentMethods.includes(paymentMethod)) {
        dispatch(setAvailableAlternativePaymentMethods(paymentMethod))
      }
    },
    [availableAlternativePaymentMethods, dispatch],
  )

  const setPaymentMethod = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    eventLogger.logPaymentMethodTap()
    eventLogger.logPaymentMethodSelected(paymentMethod)
  }

  const subscription = useMemo(() => {
    if (selectedSubscription) {
      return {
        percentage: discountPercentage,
        totalAmount:
          selectedSubscription.trialPrices.durationDays ===
          SubscriptionProduct.SEVEN_DAY
            ? getCalculatedPrice(
                selectedSubscription.trialPrices.oldPrices.fullPrice,
              )
            : getCalculatedPrice(selectedSubscription.mainPrices.fullPrice),
        discountAmount,
      }
    }

    return {
      percentage: discountPercentage,
      totalAmount: 0,
      discountAmount,
    }
  }, [
    discountAmount,
    discountPercentage,
    getCalculatedPrice,
    selectedSubscription,
  ])

  if (threeDSecureIframeUrl) {
    return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
  }

  return (
    <S.Root>
      {!isCheckoutReady && <Spinner />}

      <S.ContentContainer isVisible={isCheckoutReady}>
        <S.PaymentsMethodsContainer>
          <S.PaymentMethodsTitle>
            {availableAlternativePaymentMethods.length
              ? t('paymentLong.selectPaymentTitle')
              : t('paymentLong.checkout')}
          </S.PaymentMethodsTitle>
          <S.CloseButton onClick={handleBackClick} />
        </S.PaymentsMethodsContainer>

        {!!availableAlternativePaymentMethods.length && (
          <S.PaymentMethodsWrapper>
            <PaymentMethodOption
              $isSelected={isCardSelected}
              onClick={() => setPaymentMethod(PaymentMethod.CREDIT_CARD)}
              svg={
                availableAlternativePaymentMethods
                  ? PAYMENT_METHODS_IMAGES_LONG_ALT[PaymentMethod.CREDIT_CARD]
                  : PAYMENT_METHODS_IMAGES_LONG[PaymentMethod.CREDIT_CARD]
              }
              $svgWidth={availableAlternativePaymentMethods ? 24 : 114}
              text={
                availableAlternativePaymentMethods ? (
                  <Trans i18nKey="checkoutExtended.creditCardShort" />
                ) : (
                  <Trans i18nKey="checkoutExtended.creditCard" />
                )
              }
              textPosition={
                availableAlternativePaymentMethods
                  ? TextPosition.BOTTOM
                  : TextPosition.TOP
              }
            />

            {availableAlternativePaymentMethods.includes(
              PaymentMethod.PAYPAL,
            ) && (
              <PaymentMethodOption
                $isSelected={isPayPalSelected}
                onClick={() => setPaymentMethod(PaymentMethod.PAYPAL)}
                svg={
                  isAppleGooglePayAvailable(availableAlternativePaymentMethods)
                    ? PAYMENT_METHODS_IMAGES_LONG_ALT[PaymentMethod.PAYPAL]
                    : PAYMENT_METHODS_IMAGES_LONG[PaymentMethod.PAYPAL]
                }
                $svgWidth={availableAlternativePaymentMethods ? 75 : 100}
              />
            )}

            {availableAlternativePaymentMethods.includes(
              PaymentMethod.GOOGLE_PAY,
            ) && (
              <PaymentMethodOption
                $isSelected={!isCardSelected && !isPayPalSelected}
                onClick={() => setPaymentMethod(PaymentMethod.GOOGLE_PAY)}
                svg={PAYMENT_METHODS_IMAGES_LONG[PaymentMethod.GOOGLE_PAY]}
                $svgWidth={62}
              />
            )}

            {availableAlternativePaymentMethods.includes(
              PaymentMethod.APPLE_PAY,
            ) && (
              <PaymentMethodOption
                $isSelected={!isCardSelected && !isPayPalSelected}
                onClick={() => setPaymentMethod(PaymentMethod.APPLE_PAY)}
                svg={PAYMENT_METHODS_IMAGES_LONG[PaymentMethod.APPLE_PAY]}
                $svgWidth={62}
              />
            )}
          </S.PaymentMethodsWrapper>
        )}

        {!!availableAlternativePaymentMethods.length && <S.Separator />}

        <S.PriceContainer>
          <S.PriceInfo>
            <S.PriceText>
              {t('paymentLong.subscriptionInfo.personalizedPlan')}
            </S.PriceText>
            <S.PriceTextAmount>
              <Trans
                i18nKey="paymentLong.subscriptionInfo.price"
                values={{
                  price: subscription.totalAmount,
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PriceTextAmount>
          </S.PriceInfo>

          <S.PriceInfo>
            <S.PriceText>
              <Trans
                i18nKey="paymentLong.subscriptionInfo.introductoryDiscount"
                values={{
                  discount: subscription.percentage,
                }}
              />
            </S.PriceText>
            <S.PriceTextDiscount>
              <Trans
                i18nKey="paymentLong.subscriptionInfo.discount"
                values={{
                  discountAmount: subscription.discountAmount,
                  currency: currency.toLocaleUpperCase(),
                }}
              />
            </S.PriceTextDiscount>
          </S.PriceInfo>
        </S.PriceContainer>

        <S.Separator />

        <S.DailyPaymentContainer>
          <S.DailyPaymentText>
            {t('paymentLong.subscriptionInfo.totalPerDay')}
          </S.DailyPaymentText>
          <S.DailyPaymentAmount>
            <Trans
              i18nKey="paymentLong.subscriptionInfo.price1"
              values={{
                price: getCalculatedPrice(
                  selectedSubscription.trialPrices.daily,
                ),
                currency: currency.toLocaleUpperCase(),
              }}
            />
          </S.DailyPaymentAmount>
        </S.DailyPaymentContainer>

        <S.TotalContainer>
          <S.TotalText>{t('paymentLong.subscriptionInfo.total')}</S.TotalText>
          <S.TotalAmount>
            <Trans
              i18nKey="checkoutExtended.totalAmount"
              values={{
                totalAmount: selectedSubscription.trialPrices.fullPrice,
                currency: currency.toLocaleUpperCase(),
                context: selectedSubscription.trialPrices.durationDays,
              }}
            />
          </S.TotalAmount>
        </S.TotalContainer>

        <S.PaymentFormWrapper>
          {paymentSystem === PaymentSystem.STRIPE && (
            <S.StripePaymentWrapper>
              <S.StripePaymentContainer
                isVisible={selectedPaymentMethod === PaymentMethod.CREDIT_CARD}
              >
                <CardPaymentFormSeparateCheckoutLong />
              </S.StripePaymentContainer>
              <S.StripePaymentContainer
                isVisible={
                  selectedPaymentMethod !== PaymentMethod.CREDIT_CARD &&
                  selectedPaymentMethod !== PaymentMethod.PAYPAL
                }
              >
                <StripeSeparatePaymentRequestButton
                  borderRadius={100}
                  setAlternativePaymentMethodCallback={
                    updateAvailablePaymentMethods
                  }
                />
              </S.StripePaymentContainer>
            </S.StripePaymentWrapper>
          )}

          <PrimerPayPalButton
            isVisible={selectedPaymentMethod === PaymentMethod.PAYPAL}
          />
        </S.PaymentFormWrapper>

        <S.Guarantee>
          <SvgImage svg={paymentServicesImage} width={160} />
          <S.GuaranteeText>
            {t('paymentLong.moneyBackGuarantee')}
          </S.GuaranteeText>
        </S.Guarantee>
      </S.ContentContainer>

      {(paymentSystem === PaymentSystem.STRIPE ||
        paymentSystem === PaymentSystem.PAYPAL) && (
        <PaymentWaitingModalLong
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
        />
      )}
    </S.Root>
  )
}

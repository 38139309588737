export const PARTS_TO_EXPLORE_VALUES = {
  GODS_LOVE: 'gods_love',
  UNDERSTANDING_SCRIPTURE: 'inderstanding_scripture',
  PRAYER_AND_MEDITATION: 'prayer_and_meditation',
  APPLYING_BIBLICAL_TEACHING: 'applying_biblical_teaching',
}

export const PARTS_TO_EXPLORE_MAP = [
  {
    value: PARTS_TO_EXPLORE_VALUES.GODS_LOVE,
    text: 'onboarding.partsToExplore.godsLove',
  },
  {
    value: PARTS_TO_EXPLORE_VALUES.UNDERSTANDING_SCRIPTURE,
    text: 'onboarding.partsToExplore.understandingScripture',
  },
  {
    value: PARTS_TO_EXPLORE_VALUES.PRAYER_AND_MEDITATION,
    text: 'onboarding.partsToExplore.prayerAndMeditation',
  },
  {
    value: PARTS_TO_EXPLORE_VALUES.APPLYING_BIBLICAL_TEACHING,
    text: 'onboarding.partsToExplore.applyingBiblicalTeaching',
  },
]

export const MOST_INSPIRED_TYPE = {
  EARLY_IN_THE_MORNING: 'erly_in_the_morning',
  DURING_QUIET_EVENINGS: 'during_quiet_evenings',
  WHENEVER_I_HAVE_FREE_TIME: 'whenever_i_have_free_time',
  MOMENTS_OF_PRAYER_AND_REFLACTION: 'moments_of_prayer_and_reflaction',
  DURING_CHURCH_OR_BIBLE_SESSIONS: 'during_church_or_biblr_sessions',
  OTHER: 'other',
}

export const MOST_INSPIRED_OPTION = [
  {
    value: MOST_INSPIRED_TYPE.EARLY_IN_THE_MORNING,
    text: 'onboarding.mostInspired.earlyInTheMorning',
  },
  {
    value: MOST_INSPIRED_TYPE.DURING_QUIET_EVENINGS,
    text: 'onboarding.mostInspired.duringQuietEvenings',
  },
  {
    value: MOST_INSPIRED_TYPE.WHENEVER_I_HAVE_FREE_TIME,
    text: 'onboarding.mostInspired.wheneverIHaveFreeTime',
  },
  {
    value: MOST_INSPIRED_TYPE.MOMENTS_OF_PRAYER_AND_REFLACTION,
    text: 'onboarding.mostInspired.momentsOfPrayerAndReflaction',
  },
  {
    value: MOST_INSPIRED_TYPE.DURING_CHURCH_OR_BIBLE_SESSIONS,
    text: 'onboarding.mostInspired.duringChurchOrBibleSessions',
  },
  {
    value: MOST_INSPIRED_TYPE.OTHER,
    text: 'onboarding.mostInspired.other',
  },
]

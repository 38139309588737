import slide1Image from 'assets/images/develop-deep-image.png'
import slide3Image from 'assets/images/life-changing-image.png'
import slide2Image from 'assets/images/unlock-stories-image.png'

export const SLIDES_LIST = [
  {
    imageUrl: slide1Image,
    text: 'purchaseLong.offers.offer1',
  },
  {
    imageUrl: slide2Image,
    text: 'purchaseLong.offers.offer2',
  },
  {
    imageUrl: slide3Image,
    text: 'purchaseLong.offers.offer3',
  },
]

export const QUESTION =
  'How confident do you feel about your financial situation?'

export const FINANCIAL_CONFIDENCE_TYPE = {
  NOT_CONFIDENT: 'not_confident',
  A_BIT_CONFIDENT: 'a_bit_confident',
  FAIRLY_CONFIDENT: 'fairly_confident',
  VERY_CONFIDENT: 'very_confident',
}
export const FINANCIAL_CONFIDENCE_OPTION = [
  {
    value: FINANCIAL_CONFIDENCE_TYPE.NOT_CONFIDENT,
    text: 'onboarding.financialConfidence.notConfident',
  },
  {
    value: FINANCIAL_CONFIDENCE_TYPE.A_BIT_CONFIDENT,
    text: 'onboarding.financialConfidence.aBitConfident',
  },
  {
    value: FINANCIAL_CONFIDENCE_TYPE.FAIRLY_CONFIDENT,
    text: 'onboarding.financialConfidence.fairlyConfident',
  },
  {
    value: FINANCIAL_CONFIDENCE_TYPE.VERY_CONFIDENT,
    text: 'onboarding.financialConfidence.veryConfident',
  },
]

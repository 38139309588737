import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import { POST_PAYMENT_PAGES, PageId } from 'page-constants'

export const useGetPageInfo = () => {
  const { pathname } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)

  const currentPageId = getPageIdFromPathName(pathname)

  const currentPageIndex = steps.findIndex(({ id }) => id === currentPageId)

  const isFirstPage = currentPageIndex === 0

  const isSecondPage = currentPageIndex === 1

  const isPostPaymentPage = POST_PAYMENT_PAGES.includes(currentPageId as PageId)

  const pagesWithProgressBar = useMemo(
    () => steps.filter(({ hasProgressBar }) => hasProgressBar),
    [steps],
  )

  const { hasHeader, hasProgressbar } = useMemo(
    () => ({
      hasHeader: steps[currentPageIndex]?.hasHeader || isPostPaymentPage,
      hasProgressbar: steps[currentPageIndex]?.hasProgressBar,
    }),
    [currentPageIndex, isPostPaymentPage, steps],
  )

  let currentSubscriptionPageId = '' as PageId
  let currentPaymentPageId = '' as PageId

  steps.forEach(({ isSubscriptions, isPayment, id }) => {
    if (isSubscriptions && !currentSubscriptionPageId) {
      currentSubscriptionPageId = id
    }

    if (isPayment && !currentPaymentPageId) currentPaymentPageId = id
  })

  const isPaymentPage = currentPaymentPageId === currentPageId

  return {
    currentPageId,
    hasHeader,
    hasProgressbar,
    pagesWithProgressBar,
    currentSubscriptionPageId,
    currentPaymentPageId,
    isFirstPage,
    isSecondPage,
    isPaymentPage,
    isPostPaymentPage,
  }
}

import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { answerWithCheckboxTheme } from 'storybook-ui'

import { AnswerWithCheckboxIcon } from 'components/AnswerWithCheckboxIcon'
import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color, Language, OptionType } from 'root-constants'

import { StyledGrowthMotivation as S } from './GrowthMotivation.styles'
import { GROWTH_MOTIVATION_MAP } from './constants'

export const GrowthMotivation: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = {
    name: pageId as string,
    type: OptionType.CHECKBOX,
    onChange: (answer: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, answer])
      } else {
        setAnswers(answers.filter((item) => item !== answer))
      }
    },
  }

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.growthMotivation.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Title>{t('onboarding.growthMotivation.title')}</S.Title>
      <S.Subtitle>{t('onboarding.growthMotivation.subtitle')}</S.Subtitle>
      {GROWTH_MOTIVATION_MAP.map(({ value, text }) => (
        <AnswerWithCheckboxIcon
          {...optionProps}
          minWidth="327px"
          height="64px"
          margin="0 0 8px 0"
          iconSize="24px"
          backgroundColor={Color.SKY_BASE}
          checkboxBackgroundColor={Color.SKY_BASE}
          theme={answerWithCheckboxTheme.BLESSED_PATH}
          key={`growth-motivation-${pageId}-${value}`}
          value={value}
        >
          <Trans>{text}</Trans>
        </AnswerWithCheckboxIcon>
      ))}
      <BottomBarPortal>
        <NavigationButton
          disabled={!answers.length}
          type="button"
          onClick={() => handleContinue(answers)}
        >
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </S.Wrapper>
  )
}

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Review } from 'components/CustomerReviews/Review'
import { DEFAULT_CUSTOMER_REVIEWS } from 'components/CustomerReviews/constants'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useNextStep } from 'hooks/useNextStep'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { TPageProps } from 'models/common.model'

import { PinnedButtonContainer } from 'common-styles'
import { PURCHASE_PAGES_MAP } from 'page-constants'
import { Cohort } from 'root-constants'

import { StyledReviews as S } from './Reviews.styles'
import { QUESTION } from './constants'

export const Reviews: React.FC<TPageProps> = ({ pageId }) => {
  const { search } = useLocation()
  const { t } = useTranslation()

  const uuid = useSelector(selectUUID)
  const cohort = useSelector(selectCurrentVariantCohort)
  const language = useSelector(selectLanguage)

  const pathToRedirect = useMemo(
    () =>
      getPathFromPageId({
        pageId: PURCHASE_PAGES_MAP[cohort as Cohort],
        cohort,
        uuid,
        language,
        currentSearch: search,
      }),
    [cohort, uuid, language, search],
  )

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: pathToRedirect,
  })

  return (
    <S.Root>
      <Container>
        {DEFAULT_CUSTOMER_REVIEWS.map((review) => (
          <Review
            key={review.name}
            icon={review.icon}
            name={review.name}
            review={review.review}
          />
        ))}
        <S.Title>{t('onboarding.worldwideCommunity.title')}</S.Title>
      </Container>
      <PinnedButtonContainer>
        <Button onClick={() => handleContinue('')}>
          {t('actions.continue')}
        </Button>
      </PinnedButtonContainer>
    </S.Root>
  )
}

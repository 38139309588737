import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDiveDeeper = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 375px;
    margin: 0 auto;
    padding: 36px 24px 0;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    margin-top: 32px;
  `,
  ImageContainer: styled.div`
    width: 257px;
    aspect-ratio: 1;
  `,
  Image: styled.img`
    width: 100%;
  `,
}

export const QUESTION =
  'I believe the Bible offers valuable guidance for addressing some of key life questions and challenges.'

export const BIBLE_GUIDANCE_TYPE = {
  STRONGLY_DISAGREE: 'strongly_disagree',
  SOMEWHAT_DISAGREE: 'somewhat_disagree',
  SOMEWHAT_AGREE: 'somewhat_agree',
  STRONGLY_AGREE: 'strongly_agree',
}
export const BIBLE_GUIDANCE_OPTION = [
  {
    value: BIBLE_GUIDANCE_TYPE.STRONGLY_DISAGREE,
    text: 'onboarding.bibleGuidance.stronglyDisagree',
  },
  {
    value: BIBLE_GUIDANCE_TYPE.SOMEWHAT_DISAGREE,
    text: 'onboarding.bibleGuidance.somewhatDisagree',
  },
  {
    value: BIBLE_GUIDANCE_TYPE.SOMEWHAT_AGREE,
    text: 'onboarding.bibleGuidance.somewhatAgree',
  },
  {
    value: BIBLE_GUIDANCE_TYPE.STRONGLY_AGREE,
    text: 'onboarding.bibleGuidance.stronglyAgree',
  },
]

import styled from 'styled-components'

import { Color } from 'root-constants'

type TDescriptionProps = {
  isInProgress?: boolean
  isFinished?: boolean
}

export const StyledProgressAnalyzer = {
  Wrapper: styled.div`
    width: 100%;
    height: 32px;
  `,
  ProgressWrapper: styled.div`
    margin-bottom: 24px;
    overflow: hidden;
    height: 4px;
    border-radius: 10px;
  `,
  Progress: styled.progress`
    width: 100%;
    height: 10px;
  `,
  Description: styled.p<TDescriptionProps>`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: ${({ isInProgress }) => (isInProgress ? 700 : 400)};
    font-weight: ${({ isFinished }) => isFinished && 400};
    color: ${({ isInProgress }) =>
      isInProgress ? Color.INK_DARKEST : Color.LIGHT};
    color: ${({ isFinished }) => isFinished && Color.INK_DARKEST};
    color: ${({ isFinished }) => isFinished && Color.INK_DARKEST};
    transition: 0.25s;
  `,
  Text: styled.span`
    margin-right: 10px;
    text-align: left;
  `,
  Value: styled.span`
    min-width: 40px;
    flex-shrink: 0;
    text-align: right;
  `,
}

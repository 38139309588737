import { PaymentMethod } from 'modules/purchase/constants'

import applePayActiveImage from 'assets/images/apple-pay-active.png'
import cardActiveImage from 'assets/images/card-icons-active.png'
import googlePayActiveImage from 'assets/images/google-pay-active.png'

export const PAYMENT_METHODS_IMAGES = {
  [PaymentMethod.APPLE_PAY]: applePayActiveImage,
  [PaymentMethod.GOOGLE_PAY]: googlePayActiveImage,
  [PaymentMethod.CREDIT_CARD]: cardActiveImage,
}

export const DYNAMIC_DISCOUNT_DEFAULT_TEXT = '50% Off Mega Sale'

import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
} from '@stripe/stripe-js'

import { SvgImage } from 'components/SvgImage'

import { resetErrorAction } from 'root-redux/actions/common'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import {
  EMPTY_FIELD_ERROR,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from 'modules/purchase/constants'
import {
  getDefaultPaymentErrorsState,
  getPaymentErrorStateBySubmitWithUntouchedFields,
} from 'modules/purchase/helpers/rootHelpers'
import {
  check3DSecure,
  purchaseAction,
} from 'modules/purchase/redux/actions/common'
import { select3DSecureIframeUrl } from 'modules/purchase/redux/selects/common'
import { TCreditCardField, TPaymentErrorState } from 'modules/purchase/types'

import securedSvg from 'assets/images/sprite/secured-payment.svg'

import { CardPaymentFieldName } from 'root-constants'

import {
  StyledCardPaymentFormSeparateCheckoutLong as S,
  stripeElementStyle,
} from './CardPaymentFormSeparateCheckoutLong.styles'

export const CardPaymentFormSeparateCheckoutLong: React.FC = () => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState('')

  const hasErrors = Object.values(errors).some(
    (error) => error.isShown && error.error,
  )
  const hasUntouchedFields = Object.values(errors).some(
    (error) => error.isShown && !error.isTouched,
  )
  const hasUncompletedFields = Object.values(errors).some(
    (field) => !field.isComplete,
  )
  const isFormValid = !hasErrors && !hasUntouchedFields && !hasUncompletedFields

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
      nextElemRef,
    }: {
      fieldName: CardPaymentFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
      nextElemRef?: RefObject<TCreditCardField>
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      if (nextElemRef && isComplete) {
        nextElemRef.current?.focus()
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: { isTouched: true, error, isComplete },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorStateBySubmitWithUntouchedFields(errors))
        return
      }

      if (hasErrors) return

      const card = elements?.getElement(CardNumberElement)
      const currentPageId = getPageIdFromPathName(pathname)

      if (!stripe || !card || !currentPageId) return

      dispatch(
        purchaseAction({
          stripe,
          card,
          name,
          paymentPageId: currentPageId,
        }),
      )
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      pathname,
      stripe,
      name,
      errors,
    ],
  )

  return (
    <S.Wrapper>
      <S.PaymentInformationContainer>
        <S.PaymentInformationText>{t`paymentLong.paymentForm.paymentInfo`}</S.PaymentInformationText>
        <SvgImage svg={securedSvg} width={24} />
      </S.PaymentInformationContainer>

      <S.Form onSubmit={handleSubmit}>
        <S.InputContainer>
          <S.Label>{t`paymentLong.paymentForm.cardNumber`}</S.Label>
          <S.CardNumberElement
            onReady={(elem) => elem.focus()}
            options={{
              showIcon: true,
              placeholder: '1234 1234 1234 1234',
              style: stripeElementStyle,
            }}
            onChange={({ empty, error, complete }) => {
              handleChange({
                fieldName: CardPaymentFieldName.NUMBER,
                isEmpty: empty,
                hasError: !!error,
                isComplete: complete,
                nextElemRef: cardExpiryElemRef,
              })
            }}
          />

          <S.CvvExpiryInputContainer>
            <S.CardExpiryContainer>
              <S.Label>{t`paymentLong.paymentForm.cardExpiry`}</S.Label>
              <S.CardExpiryElement
                onReady={(elem) => {
                  cardExpiryElemRef.current = elem
                }}
                options={{
                  placeholder: 'MM/YY',
                  style: stripeElementStyle,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: CardPaymentFieldName.EXPIRY,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                    nextElemRef: cvcElemRef,
                  })
                }}
              />
            </S.CardExpiryContainer>

            <S.CardCvcContainer>
              <S.Label>{t`paymentLong.paymentForm.securityNumber`}</S.Label>
              <S.CardCvcElement
                onReady={(elem) => {
                  cvcElemRef.current = elem
                }}
                options={{
                  placeholder: 'CVC',
                  style: stripeElementStyle,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: CardPaymentFieldName.CVC,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                    nextElemRef: cardholderNameElemRef,
                  })
                }}
              />
            </S.CardCvcContainer>
          </S.CvvExpiryInputContainer>

          <S.Label>{t`paymentLong.paymentForm.cardholderName`}</S.Label>
          <S.CardholderInput
            ref={cardholderNameElemRef}
            type="text"
            placeholder="Cardholder name"
            data-invalid={!!errors[CardPaymentFieldName.NAME].error}
            onChange={(e) => {
              const value = e.target.value.trim()

              setName(value)

              handleChange({
                fieldName: CardPaymentFieldName.NAME,
                isEmpty: false,
                hasError: false,
                isComplete: true,
              })
            }}
          />
        </S.InputContainer>

        <S.StyledButton type="submit" disabled={!stripe || !isFormValid}>
          {t`paymentLong.paymentForm.confirmPayment`}
        </S.StyledButton>
      </S.Form>
    </S.Wrapper>
  )
}

import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { BottomBarPortal } from 'components/BottomBar'
import { Input } from 'components/Input'
import { NavigationButton } from 'components/NavigationButton'

import { sendUserEmailAction } from 'root-redux/actions/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { useEmailInputField } from 'modules/email/hooks/useEmailInputField'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import giftImage from 'assets/images/gift.png'
import mailLogo from 'assets/images/sprite/mail-logo.svg'

import { goTo } from 'browser-history'

import { StyledEmail as S } from './Email.styles'

export const Email: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const [email, setEmail] = useEmailInputField()
  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  const { currentSubscriptionPageId } = useGetPageInfo()

  useEffect(() => {
    eventLogger.logEmailPageShow()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(
      sendUserEmailAction({
        email: email.value,
      }),
    )

    goTo({ pathname: currentSubscriptionPageId, search })
  }

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
    },
    [setEmail],
  )

  return (
    <>
      <S.Wrapper>
        <S.Title>
          <Trans i18nKey="onboarding.email.title" />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.email.subtitle" />
        </S.Subtitle>

        <S.Container>
          <S.DisclaimerGradient>
            <S.DisclaimerWrapper>
              <S.DisclaimerImage src={giftImage} alt="gift image" />
              <S.DisclaimerText>
                {t('onboarding.email.disclaimer')}
              </S.DisclaimerText>
            </S.DisclaimerWrapper>
          </S.DisclaimerGradient>

          <form id="email-form" onSubmit={handleSubmit}>
            <Input
              labelName={t('emailForm.label')}
              value={email.value}
              isValid={email.isValid}
              validationText={email.validationText}
              placeholder={t('emailForm.placeholder')}
              iconSvg={mailLogo}
              onChange={handleChange}
            />
          </form>
        </S.Container>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton
          disabled={!email.value || !isEmailValid}
          type="submit"
          form="email-form"
        >
          <Trans i18nKey="actions.getResults" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

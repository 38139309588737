export const FEEL_SCRIPTURE_TYPE = {
  INSPIRED_AND_LIFTED_UP: 'inspired_and_lifted_up',
  CALM_AND_PEACEFUL: 'calm_and_peaceful',
  FOCUS_AND_CLEAR: 'focus_and_clear',
  DO_NOT_SPEND_MUCH_TIME: 'do_not_spend_much_time',
  NOT_SURE: 'not_sure',
}
export const FEEL_SCRIPTURE_OPTION = [
  {
    value: FEEL_SCRIPTURE_TYPE.INSPIRED_AND_LIFTED_UP,
    text: 'onboarding.feelScripture.inspiredAndUplifted',
  },
  {
    value: FEEL_SCRIPTURE_TYPE.CALM_AND_PEACEFUL,
    text: 'onboarding.feelScripture.calmAndPeaceful',
  },
  {
    value: FEEL_SCRIPTURE_TYPE.FOCUS_AND_CLEAR,
    text: 'onboarding.feelScripture.focusedAndClear',
  },
  {
    value: FEEL_SCRIPTURE_TYPE.DO_NOT_SPEND_MUCH_TIME,
    text: 'onboarding.feelScripture.doNotSpendMuchTime',
  },
  {
    value: FEEL_SCRIPTURE_TYPE.NOT_SURE,
    text: 'onboarding.feelScripture.notSure',
  },
]

export const QUESTION =
  'I believe its important to have faith in something meaningful.'

export const FAITH_BELIEVE_TYPE = {
  STRONGLY_DISAGREE: 'strongly_disagree',
  SOMEWHAT_DISAGREE: 'somewhat_disagree',
  SOMEWHAT_AGREE: 'somewhat_agree',
  STRONGLY_AGREE: 'strongly_agree',
}
export const FAITH_BELIEVE_OPTION = [
  {
    value: FAITH_BELIEVE_TYPE.STRONGLY_DISAGREE,
    text: 'onboarding.faithBelieve.stronglyDisagree',
  },
  {
    value: FAITH_BELIEVE_TYPE.SOMEWHAT_DISAGREE,
    text: 'onboarding.faithBelieve.somewhatDisagree',
  },
  {
    value: FAITH_BELIEVE_TYPE.SOMEWHAT_AGREE,
    text: 'onboarding.faithBelieve.somewhatAgree',
  },
  {
    value: FAITH_BELIEVE_TYPE.STRONGLY_AGREE,
    text: 'onboarding.faithBelieve.stronglyAgree',
  },
]

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { StyledOffersSwiper as S } from './OffersSwiper.styles'
import { SLIDES_LIST } from './constants'

export const OffersSwiper = () => {
  const { t } = useTranslation()

  return (
    <S.CarouselContainer>
      <Carousel
        spaceBetween={50}
        slidesPerView={1}
        loop
        pagination={{ clickable: true }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {SLIDES_LIST.map(({ text, imageUrl }) => (
          <SwiperSlide key={text}>
            <S.SlideWrapper>
              <S.Image src={imageUrl} alt="" />
              <S.Title>{t('purchaseLong.offers.title')}</S.Title>
              <S.Text>{t(text)}</S.Text>
            </S.SlideWrapper>
          </SwiperSlide>
        ))}
      </Carousel>
    </S.CarouselContainer>
  )
}

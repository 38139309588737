import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import transformFaithImage from 'assets/images/transform-faith-image.png'

import { Language } from 'root-constants'

import { StyledTransformFaith as S } from './TransformFaith.styles'

export const TransformFaith: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.transformFaith.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleContinueClick = () => {
    eventLogger.logFirstPageCompleted({
      question: t('onboarding.transformFaith.title', { lng: Language.EN }),
    })
    handleContinue('')
  }

  return (
    <>
      <S.Wrapper>
        <S.Title>{t('onboarding.transformFaith.title')}</S.Title>
        <S.ImageContainer>
          <S.Image src={transformFaithImage} />
        </S.ImageContainer>
        <S.Subtitle>{t('onboarding.transformFaith.subtitle')}</S.Subtitle>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={handleContinueClick}>
          <Trans i18nKey="onboarding.transformFaith.CTA" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TCircle = {
  percentage: number
}

const CommonCircleStyles = css`
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #e9e9e9;
  stroke-width: 3;
  stroke-linecap: round;
`

export const StyledResults = {
  Root: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-bottom: 16px;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  Result: styled.li`
    display: flex;
    align-items: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;

    & div {
      margin-right: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Circle: styled.circle<TCircle>`
    stroke-dashoffset: ${({ percentage }) =>
      `calc(126px - (126px * ${percentage}) / 100)`};
  `,
}

export const StyledCircular = {
  Container: styled.div`
    display: grid;
    place-content: center;
    position: relative;
  `,
  CircularSVG: styled.svg`
    position: relative;
    width: 50px;
    height: 50px;
    transform: rotate(-90deg);
  `,
  Circle: styled.circle`
    ${CommonCircleStyles}
  `,
  ProgressCircle: styled.circle<{ percentage: number }>`
    ${CommonCircleStyles}
    stroke-dasharray: 126px;
    stroke-dashoffset: ${({ percentage }) =>
      `calc(126px - (126px * ${percentage}) / 100)`};
    stroke: #9cb3e8;
  `,
  PercentageText: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #17202a;
  `,
}

import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledHeader = {
  Wrapper: styled.header`
    position: relative;
    height: 52px;
    padding: 8px 84px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -0.5px 0px 0px rgba(0, 0, 0, 0.25) inset;
    background-color: ${Color.WHITE};
    z-index: 3;
  `,
  Image: styled.img`
    margin-right: 12px;
    width: 36px;
    height: 36px;
  `,
  Text: styled.span`
    font-family: 'Open Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.BLACK};
  `,
}

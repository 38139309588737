import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { setLanguageAction } from 'root-redux/actions/common'
import {
  selectCurrentVariant,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'

import { browserHistory } from 'browser-history'
import {
  COHORT_QUERY_PARAM,
  Cohort,
  INITIAL_LANGUAGE,
  LANG_QUERY_PARAM,
  SUPPORTED_LOCALES,
  SUPPORTED_LOCALES_MAP,
} from 'root-constants'

export const useLanguage = (isUnsubscribeFlow?: boolean): void => {
  const { pathname, search } = document.location
  const dispatch = useDispatch()
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const variant = useSelector(selectCurrentVariant, shallowEqual)

  useEffect(() => {
    if (!variant) return
    const URLParams = new URLSearchParams(search)
    const langQueryParam = URLParams.get(LANG_QUERY_PARAM) || ''
    const cohort = URLParams.get(COHORT_QUERY_PARAM)

    const supportedLocales = isUnsubscribeFlow
      ? SUPPORTED_LOCALES
      : SUPPORTED_LOCALES_MAP[(parentCohort as Cohort) || (cohort as Cohort)]
    if (!supportedLocales?.includes(langQueryParam)) {
      dispatch(setLanguageAction(INITIAL_LANGUAGE))
      browserHistory.replace({
        pathname,
        search: URLParams.toString(),
      })
      return
    }

    dispatch(setLanguageAction(langQueryParam))
  }, [dispatch, pathname, search, isUnsubscribeFlow, parentCohort, variant])
}

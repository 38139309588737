import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useCohortInfo } from 'hooks/useCohortInfo'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useScrollEvent } from 'hooks/useScrollEvent'
import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { getButtonTapEventData } from 'helpers/getButtonTapEventData'

import {
  DisclaimerV1,
  DisclaimerV2,
} from 'modules/purchase/components/Disclaimer'
import {
  HighlightsV1,
  HighlightsV2,
} from 'modules/purchase/components/Highlights'
import { SecuredPaymentBadge } from 'modules/purchase/components/SecuredPaymentBadge'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import boombergLogo from 'assets/images/sprite/boomberg-logo.svg'
import christianLogo from 'assets/images/sprite/christian-post-logo.svg'
import gismartLogo from 'assets/images/sprite/gismart-logo.svg'
import moneyBackImage from 'assets/images/sprite/money-back.svg'
import newYorkPostLogo from 'assets/images/sprite/new-york-post-logo.svg'
import secureTransactionImage from 'assets/images/sprite/secured-transaction.svg'

import { goTo } from 'browser-history'
import {
  Cohort,
  SUPPORT_MAIL,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import {
  BeforeAfterCards,
  BeforeAfterCardsV2,
} from '../../components/BeforeAfterCards'
import { OffersSwiper } from '../../components/OffersSwiper'
import { ResultsLong } from '../../components/ResultsLong'
import { ReviewsLong } from '../../components/ReviewsLong'
import { SubscriptionsIntroOfferBlock } from '../../components/SubscriptionsIntroOfferBlock'
import { TimerWithDiscount } from '../../components/TimerWithDiscount'
import { usePricesStatus, useTimerForTarget } from '../../hooks'
import { selectSubscription } from '../../redux/selects/common'
import { StyledPurchaseLong as S } from './PurchaseLong.styles'
import {
  DEFAULT_DISCOUNT,
  LONG_PAYWALL_TRACKED_BREAKPOINTS,
  SECURED_INFO,
  TEN_MINUTES,
} from './constants'

export const PurchaseLong: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { search } = useLocation()
  const { cohortToUse } = useCohortInfo()

  const isIntroLongCohort = cohortToUse === Cohort.FLOW_1_INTRO_LONG
  const context = !isIntroLongCohort ? 'v2' : ''

  const topSubscriptionsBlock = useRef<HTMLDivElement | null>(null)
  const timerElementRef = useRef<HTMLParagraphElement | null>(null)
  const topDuplicateTimerElementRef = useRef<HTMLSpanElement | null>(null)
  const timerContainerRef = useRef<HTMLDivElement | null>(null)

  const subscription = useSelector(selectSubscription)
  const { currentPaymentPageId } = useGetPageInfo()
  const { arePricesReady } = usePricesStatus()
  const { scrollEvent } = useScrollEvent(ScreenName.PURCHASE)

  const discount = useMemo(() => {
    return (
      subscription?.mainPrices.oldPrices.percentOfDiscount || DEFAULT_DISCOUNT
    )
  }, [subscription?.mainPrices.oldPrices.percentOfDiscount])

  useTimerForTarget(timerElementRef, TEN_MINUTES.IN_SECONDS, [
    topDuplicateTimerElementRef,
  ])

  useTrackPageScrollDepth({
    trackedBreakpoints: LONG_PAYWALL_TRACKED_BREAKPOINTS,
    eventCallback: scrollEvent,
  })

  useLayoutEffect(() => {
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        SubscriptionTagsType.NO_TAX,
      ),
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(setScreenNameAction(ScreenName.PURCHASE))

    googleAnalyticsLogger.logPaywallView()
  }, [dispatch])

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, shouldNavigate: boolean) => {
      const { buttonText, buttonOrder } = getButtonTapEventData(event)

      eventLogger.logPlanPageButtonClicked({
        buttonText,
        buttonNumber: buttonOrder,
        screenName: ScreenName.PURCHASE,
      })

      if (shouldNavigate) {
        goTo({ pathname: currentPaymentPageId, search })
      }
    },
    [currentPaymentPageId, search],
  )

  return arePricesReady ? (
    <>
      <S.TimerContainer ref={timerContainerRef}>
        <TimerWithDiscount
          discount={discount}
          topSubscriptionsBlock={topSubscriptionsBlock}
          timerElementRef={timerElementRef}
          onButtonClick={(event) => handleButtonClick(event, false)}
          buttonOrder={1}
        />
      </S.TimerContainer>

      <S.Wrapper>
        <S.Title>
          <Trans i18nKey={t('purchaseLong.title', { context })} />
        </S.Title>

        {isIntroLongCohort ? <BeforeAfterCards /> : <BeforeAfterCardsV2 />}

        {isIntroLongCohort ? <HighlightsV1 /> : <HighlightsV2 />}

        <S.DuplicateTimerTitle>
          {t('purchaseLong.offersTitle', { context })}
        </S.DuplicateTimerTitle>

        <S.DuplicateTimerContainer>
          <S.DuplicateTimerText>
            {t('purchaseLong.duplicateTimer')}
          </S.DuplicateTimerText>
          <S.DuplicateTimer>
            <span ref={topDuplicateTimerElementRef}>
              {!timerElementRef?.current?.hasAttribute('data-value')
                ? TEN_MINUTES.AS_STRING
                : null}
            </span>
            <span>{t('purchaseLong.min')}</span>
          </S.DuplicateTimer>
        </S.DuplicateTimerContainer>

        <S.SubscriptionsContainer ref={topSubscriptionsBlock}>
          <SubscriptionsIntroOfferBlock />
        </S.SubscriptionsContainer>

        <S.ClaimButton
          data-order={2}
          onClick={(event) => handleButtonClick(event, true)}
        >
          <p>{t('actions.getMyPlan')}</p>
        </S.ClaimButton>

        {!isIntroLongCohort && <SecuredPaymentBadge />}

        {isIntroLongCohort ? <DisclaimerV1 /> : <DisclaimerV2 />}

        <S.Separator />

        <S.GuaranteeWrapper>
          <SvgImage svg={moneyBackImage} width={125} />

          <S.GuaranteeTitle>
            {t('purchaseLong.moneyBackGuarantee.title')}
          </S.GuaranteeTitle>

          <S.GuaranteeText>
            {t('purchaseLong.moneyBackGuarantee.text')}
            <TermsOfUseLink />
          </S.GuaranteeText>
        </S.GuaranteeWrapper>

        <S.Separator />
        <OffersSwiper />

        <S.Separator />
        <ResultsLong />

        <S.Separator />
        <ReviewsLong />

        <S.FeaturedWrapper>
          <S.FeaturedTitle>{t('purchaseLong.featureIn')}</S.FeaturedTitle>
          <S.FeaturedLogos>
            <SvgImage svg={boombergLogo} width={98} height={46} />
            <SvgImage svg={newYorkPostLogo} width={164} height={46} />
            <SvgImage svg={christianLogo} width={98} height={46} />
          </S.FeaturedLogos>
        </S.FeaturedWrapper>
        <S.Separator />

        <S.SecuredInfoWrapper>
          <SvgImage svg={secureTransactionImage} width={125} />

          {SECURED_INFO.map(({ title, text, hasLink }) => (
            <S.SecureInfoItem key={title}>
              <S.SecuredInfoTitle>{t(title)}</S.SecuredInfoTitle>
              <S.SecuredInfoText>
                <Trans i18nKey={text} />
                {hasLink && (
                  <a href={`mailto: ${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>
                )}
              </S.SecuredInfoText>
            </S.SecureInfoItem>
          ))}
        </S.SecuredInfoWrapper>

        <S.RightsWrapper>
          <SvgImage svg={gismartLogo} width={100} />

          <S.RightsText>
            {t('footer.allRightsReserved', {
              year: new Date().getFullYear(),
            })}
          </S.RightsText>
        </S.RightsWrapper>
      </S.Wrapper>
    </>
  ) : (
    <Spinner />
  )
}

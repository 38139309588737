import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledBeforeAfterCardsV2 = {
  CardsHeader: styled.div`
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    margin: 24px 0 12px;
  `,
  HeaderSegment: styled.div<{ active?: boolean }>`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: ${({ active }) => (active ? 700 : 500)};
    color: ${({ active }) => (active ? Color.BLUE_DARKER : Color.INK_DARKEST)};
  `,
  Divider: styled.div`
    width: 1px;
    background: rgba(0, 0, 0, 0.18);
  `,
  Cards: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    margin-bottom: 32px;
  `,
  Card: styled.div`
    width: 158px;
    height: 184px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  Image: styled.img`
    max-height: 124px;
    object-fit: cover;
  `,
  Text: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: ${Color.INK_DARKEST};
  `,
}

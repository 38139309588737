import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { answerWithCheckboxTheme } from 'storybook-ui'

import { AnswerWithRadioIcon } from 'components/AnswerWithRadioIcon'
import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'
import { Notification } from 'components/Notification'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color, OptionType } from 'root-constants'

import { StyledStressLevel as S } from './StressLevel.styles'
import { ENERGY_LEVEL_OPTION, QUESTION, STRESS_LEVEL_TYPE } from './constants'

export const StressLevel: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const optionProps = {
    name: pageId as string,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      setSelectedOption(value)
    },
  }

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleContinueClick = () => {
    if (!selectedOption) return
    handleContinue(selectedOption)
  }

  return (
    <S.Wrapper>
      <S.Title>{t('onboarding.stressLevel.title')}</S.Title>
      <S.NotificationAnimation
        visible={selectedOption === STRESS_LEVEL_TYPE.ALL_THE_TIME}
      >
        <Notification
          title={t('onboarding.stressLevel.notificationTitle')}
          description={t('onboarding.stressLevel.notificationDescription')}
        />
      </S.NotificationAnimation>
      {ENERGY_LEVEL_OPTION.map(({ value, text }) => (
        <AnswerWithRadioIcon
          {...optionProps}
          minWidth="327px"
          height="64px"
          margin="0 0 8px 0"
          iconSize="24px"
          checkboxBorderRadius="32px"
          backgroundColor={Color.SKY_BASE}
          checkboxBackgroundColor={Color.SKY_BASE}
          theme={answerWithCheckboxTheme.BLESSED_PATH}
          key={value}
          value={value}
          checked={selectedOption === value}
        >
          <Trans>{text}</Trans>
        </AnswerWithRadioIcon>
      ))}
      {selectedOption && (
        <BottomBarPortal>
          <NavigationButton type="button" onClick={handleContinueClick}>
            <Trans i18nKey="actions.continue" />
          </NavigationButton>
        </BottomBarPortal>
      )}
    </S.Wrapper>
  )
}

import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

export const StyledTimerWithDiscount = {
  TimerContent: styled.div`
    margin: 0 auto;
    max-width: 375px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
  `,
  TopWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 8px;
  `,
  TimerTextWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 4px;
  `,
  TimerText: styled.p`
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    color: ${Color.INK_DARKEST};
  `,
  CountdownTime: styled.p`
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
    left: 2px;
  `,
  TopButton: styled(Button)`
    width: 157px;
    height: 48px;
    border-radius: 48px;
    background-color: ${Color.INK_DARKEST};
    padding: 16px 32px;

    & p {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: ${Color.GOLD_LIGHTER};
    }
  `,
}

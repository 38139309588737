import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDisclaimer = styled.p`
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  color: ${Color.INK_LIGHT};
  text-align: center;
  margin: 16px 0 40px;

  & a {
    font-weight: 500;
    color: ${Color.INK_DARKEST};
    text-decoration: underline;
  }
`

import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Featured } from 'components/Featured'
import { Spinner } from 'components/Spinner'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  getSubscriptionListAction,
  resetErrorAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useScrollToTarget } from 'hooks/useScrollToTarget'

import { createProductId } from 'helpers/createProductId'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import userCloseEyes from 'assets/images/user-close-eyes.png'
import userOpenEyes from 'assets/images/user-open-eyes.png'

import {
  Color,
  SUPPORT_MAIL,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { CheckoutSeparateMethods } from '../../components/CheckoutSeparateMethods'
import { MoneyBackGuarantee } from '../../components/MoneyBackGuarantee'
import { Offer } from '../../components/Offer'
import { Results } from '../../components/Results'
import { Reviews } from '../../components/Reviews'
import { SecurityInfo } from '../../components/SecurityInfo'
import { SubscriptionsIntroOfferBlock } from '../../components/SubscriptionsIntroOfferBlock'
import { CURRENCY_SYMBOLS } from '../../constants'
import {
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
  useTimerForTarget,
} from '../../hooks'
import { CHECK_PAYMENT_REQUEST_BUTTON } from '../../redux/actions/common'
import { selectSubscription } from '../../redux/selects/common'
import { StyledPurchaseV2 as S } from './PurchaseV2.styles'
import {
  DEFAULT_DISCOUNT,
  DISCLAIMER,
  HIGHLIGHTS_ORDER,
  TEN_MINUTES,
} from './constants'

export const PurchaseV2 = () => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const [isCheckoutShown, setIsCheckoutShown] = useState<boolean>(false)

  const dispatch: TAppDispatch = useDispatch()

  const topSubscriptionsBlock = useRef<HTMLDivElement | null>(null)
  const timerElementRef = useRef<HTMLParagraphElement | null>(null)
  const topDuplicateTimerElementRef = useRef<HTMLSpanElement | null>(null)
  const timerContainerRef = useRef<HTMLDivElement | null>(null)

  const subscription = useSelector(selectSubscription)

  const { arePricesReady } = usePricesStatus()
  const { scrollToTarget } = useScrollToTarget()

  const {
    trialPeriodDays,
    currency,
    trialPrice,
    price,
    periodQuantity,
    periodName,
    cohort,
  } = usePurchaseStore()
  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.PURCHASE,
  )

  const discount = useMemo(() => {
    return (
      subscription?.mainPrices.oldPrices.percentOfDiscount || DEFAULT_DISCOUNT
    )
  }, [subscription?.mainPrices.oldPrices.percentOfDiscount])

  useTimerForTarget(timerElementRef, TEN_MINUTES.IN_SECONDS, [
    topDuplicateTimerElementRef,
  ])

  useLayoutEffect(() => {
    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        SubscriptionTagsType.NO_TAX,
      ),
    )
  }, [dispatch])

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  useEffect(() => {
    dispatch(setScreenNameAction(ScreenName.PURCHASE))

    googleAnalyticsLogger.logPaywallView()
  }, [dispatch])

  useEffect(() => {
    const handleScroll = () => {
      if (timerContainerRef.current) {
        if (!timerContainerRef.current.getBoundingClientRect().top) {
          timerContainerRef.current.style.backgroundColor = Color.WHITE
          timerContainerRef.current.style.boxShadow = '0px 8px 20px 0px #E8E8E8'

          return
        }

        if (timerContainerRef.current.getBoundingClientRect().top) {
          timerContainerRef.current.style.backgroundColor = 'transparent'
          timerContainerRef.current.style.boxShadow = 'none'
        }
      }
    }

    document.body.addEventListener('scroll', handleScroll)

    return () => document.body.removeEventListener('scroll', handleScroll)
  }, [])

  const handleShowCheckout = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: ScreenName.PURCHASE,
    })

    setIsCheckoutShown(false)
  }, [dispatch, pathname, cohort, productId, setIsCheckoutShown])

  return arePricesReady ? (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <S.TimerContainer ref={timerContainerRef}>
            <S.TimerContent>
              <S.TimerText>
                <p>
                  {discount}% {t('purchase2.timer')}
                </p>
                <S.CountdownTime ref={timerElementRef}>
                  {!timerElementRef?.current?.hasAttribute('data-value')
                    ? TEN_MINUTES.AS_STRING
                    : null}
                </S.CountdownTime>
              </S.TimerText>
              <S.TopButton
                onClick={() => scrollToTarget(topSubscriptionsBlock)}
              >
                {t('actions.getMyPlan')}
              </S.TopButton>
            </S.TimerContent>
          </S.TimerContainer>
          <Container fields={16}>
            <S.Title>
              <Trans
                i18nKey="purchase2.title"
                components={[<strong />, <br />]}
              />
            </S.Title>

            <S.Cards>
              <S.Card>
                <img src={userCloseEyes} alt="" />
                <p>{t('purchase2.cta1')}</p>
              </S.Card>
              <S.ActiveCard>
                <img src={userOpenEyes} alt="" />
                <p>{t('purchase2.cta2')}</p>
              </S.ActiveCard>
            </S.Cards>

            <S.BenefitsTitle>{t('purchase2.benefits.title')}</S.BenefitsTitle>

            <S.Benefits>
              {HIGHLIGHTS_ORDER.map((benefitIndex) => {
                return (
                  <S.Benefit key={benefitIndex}>
                    <Trans
                      i18nKey={`purchase2.benefits.benefit${benefitIndex}`}
                      components={[<strong />, <br />]}
                    />
                  </S.Benefit>
                )
              })}
            </S.Benefits>
          </Container>
          <S.ContainerWrapper>
            <S.DuplicateTimer>
              <S.DuplicateTimerText>
                {t('purchase2.duplicateTimer')}{' '}
                <strong ref={topDuplicateTimerElementRef} />{' '}
                {t('purchase2.min')}
              </S.DuplicateTimerText>
            </S.DuplicateTimer>
            <S.SubscriptionsContainer ref={topSubscriptionsBlock}>
              <SubscriptionsIntroOfferBlock />
            </S.SubscriptionsContainer>
            <Button onClick={handleShowCheckout}>
              {t('actions.getMyPlan')}
            </Button>
          </S.ContainerWrapper>

          <Container fields={16}>
            <S.Disclaimer>
              <Trans
                i18nKey={DISCLAIMER[trialPeriodDays]}
                values={{
                  currency: CURRENCY_SYMBOLS[currency],
                  trialPrice,
                  price,
                }}
              />
              {t('purchase2.learnMore')} <TermsOfUseLink />{' '}
              {t('purchase2.emailContact')}{' '}
              <a href={`mailto: ${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>
            </S.Disclaimer>
            <Featured />

            <MoneyBackGuarantee />
            <Offer />
            <Results />
          </Container>
          <Reviews />

          <Container>
            <Button onClick={() => scrollToTarget(topSubscriptionsBlock)}>
              {t('actions.getMyPlan')}
            </Button>
            <SecurityInfo />
          </Container>
        </S.Wrapper>
      )}
      {isCheckoutShown && (
        <CheckoutSeparateMethods handleCloseCheckout={handleCloseCheckout} />
      )}
    </>
  ) : (
    <Spinner />
  )
}

import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Option } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { useCohortInfo } from 'hooks/useCohortInfo'

import { SelectSubscriptionItemV2 } from 'modules/purchase/components/SelectSubscriptionItemV2'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { selectSubscriptionId } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { OptionType } from 'root-constants'

import { SelectSubscriptionItemLong } from '../SelectSubscriptionItemLong'
import { StyledSubscriptionsIntroOfferBlock as S } from './SubscriptionsIntroOfferBlock.styles'

export const SubscriptionsIntroOfferBlock = () => {
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const { isLongFlow } = useCohortInfo()

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = subscriptions.find(
        (subscription) => subscription.id === value,
      )
      dispatch(
        setSelectedSubscriptionAction(checkedSubscription as ISubscription),
      )
    },
    [dispatch, subscriptions],
  )

  return (
    <S.Wrapper>
      {subscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
          withoutMargin
        >
          {isLongFlow ? (
            <SelectSubscriptionItemLong
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
            />
          ) : (
            <SelectSubscriptionItemV2
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
            />
          )}
        </Option>
      ))}
    </S.Wrapper>
  )
}

import { useEffect } from 'react'

import { useProgressAnimation } from 'hooks/useProgressAnimation'

type TUseProgressAnimationDataProps = {
  goToNextPage: () => void
  STATIC_ANIMATION_DURATION: number
  FIRST_STOP_COUNTER_AT: number
  SECOND_STOP_COUNTER_AT: number
  THIRD_STOP_COUNTER_AT: number
}

type TUseProgressAnimationDataReturnTypes = {
  firstCounter: number
  isFirstCounterFinished: boolean
  firstProgressAnimationPlayback: (stopAt?: number) => void
  secondCounter: number
  isSecondCounterFinished: boolean
  secondProgressAnimationPlayback: () => void
  thirdCounter: number
  isThirdCounterFinished: boolean
  thirdProgressAnimationPlayback: () => void
}

export const useProgressAnimationData = ({
  goToNextPage,
  STATIC_ANIMATION_DURATION,
  FIRST_STOP_COUNTER_AT,
  SECOND_STOP_COUNTER_AT,
  THIRD_STOP_COUNTER_AT,
}: TUseProgressAnimationDataProps): TUseProgressAnimationDataReturnTypes => {
  const {
    counter: firstCounter,
    isFinished: isFirstCounterFinished,
    progressAnimationPlayback: firstProgressAnimationPlayback,
  } = useProgressAnimation({
    duration: STATIC_ANIMATION_DURATION,
  })

  const {
    counter: secondCounter,
    isFinished: isSecondCounterFinished,
    progressAnimationPlayback: secondProgressAnimationPlayback,
  } = useProgressAnimation({
    duration: STATIC_ANIMATION_DURATION,
  })

  const {
    counter: thirdCounter,
    isFinished: isThirdCounterFinished,
    progressAnimationPlayback: thirdProgressAnimationPlayback,
  } = useProgressAnimation({
    duration: STATIC_ANIMATION_DURATION,
    callback: goToNextPage,
  })

  useEffect(() => {
    firstProgressAnimationPlayback(FIRST_STOP_COUNTER_AT)
  }, [firstProgressAnimationPlayback, FIRST_STOP_COUNTER_AT])

  useEffect(() => {
    isFirstCounterFinished &&
      secondProgressAnimationPlayback(SECOND_STOP_COUNTER_AT)
  }, [
    isFirstCounterFinished,
    secondProgressAnimationPlayback,
    SECOND_STOP_COUNTER_AT,
  ])

  useEffect(() => {
    isSecondCounterFinished &&
      thirdProgressAnimationPlayback(THIRD_STOP_COUNTER_AT)
  }, [
    isSecondCounterFinished,
    thirdProgressAnimationPlayback,
    THIRD_STOP_COUNTER_AT,
  ])

  return {
    firstCounter,
    isFirstCounterFinished,
    firstProgressAnimationPlayback,
    secondCounter,
    isSecondCounterFinished,
    secondProgressAnimationPlayback,
    thirdCounter,
    isThirdCounterFinished,
    thirdProgressAnimationPlayback,
  }
}

export const QUESTION =
  'I believe that having spiritual or religious habits can add meaning to my life.'

export const RELIGIOUS_HABITS_TYPE = {
  STRONGLY_DISAGREE: 'strongly_disagree',
  SOMEWHAT_DISAGREE: 'somewhat_disagree',
  SOMEWHAT_AGREE: 'somewhat_agree',
  STRONGLY_AGREE: 'strongly_agree',
}
export const RELIGIOUS_HABITS_OPTION = [
  {
    value: RELIGIOUS_HABITS_TYPE.STRONGLY_DISAGREE,
    text: 'onboarding.religiousHabits.stronglyDisagree',
  },
  {
    value: RELIGIOUS_HABITS_TYPE.SOMEWHAT_DISAGREE,
    text: 'onboarding.religiousHabits.somewhatDisagree',
  },
  {
    value: RELIGIOUS_HABITS_TYPE.SOMEWHAT_AGREE,
    text: 'onboarding.religiousHabits.somewhatAgree',
  },
  {
    value: RELIGIOUS_HABITS_TYPE.STRONGLY_AGREE,
    text: 'onboarding.religiousHabits.stronglyAgree',
  },
]

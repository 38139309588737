export const GROWTH_MOTIVATION_VALUES = {
  IMPROVING_WELL_BEING: 'improving_well_being',
  RECOVERING_SETBACK: 'recovering_setback',
  FINANCIAL_INDEPENDENCE: 'financial_independence',
  SPIRITUAL_GROWTH: 'spiritual_growth',
  CAREER_ADVANCEMENT: 'career_advancement',
  BETTER_RELATIONSHIP: 'better_relationship',
  PREPARING_PARENTHOOD: 'prepring_parenthood',
}

export const GROWTH_MOTIVATION_MAP = [
  {
    value: GROWTH_MOTIVATION_VALUES.IMPROVING_WELL_BEING,
    text: 'onboarding.growthMotivation.improvingWellBeing',
  },
  {
    value: GROWTH_MOTIVATION_VALUES.RECOVERING_SETBACK,
    text: 'onboarding.growthMotivation.recoveringSetback',
  },
  {
    value: GROWTH_MOTIVATION_VALUES.FINANCIAL_INDEPENDENCE,
    text: 'onboarding.growthMotivation.financialIndependence',
  },
  {
    value: GROWTH_MOTIVATION_VALUES.SPIRITUAL_GROWTH,
    text: 'onboarding.growthMotivation.spiritualGrowth',
  },
  {
    value: GROWTH_MOTIVATION_VALUES.CAREER_ADVANCEMENT,
    text: 'onboarding.growthMotivation.careerAdvancement',
  },
  {
    value: GROWTH_MOTIVATION_VALUES.BETTER_RELATIONSHIP,
    text: 'onboarding.growthMotivation.betterRelationships',
  },
  {
    value: GROWTH_MOTIVATION_VALUES.PREPARING_PARENTHOOD,
    text: 'onboarding.growthMotivation.preparingParenthood',
  },
]

import styled from 'styled-components'

import { TContainer } from './Container'

export const StyledContainer = {
  Container: styled.div<TContainer>`
    max-width: 375px;
    margin: 0 auto;
    padding: ${({ fields }) => `0 ${fields}px`};
  `,
}

import { useSelector } from 'react-redux'

import { selectIsUserSignedUp, selectUserStatus } from 'root-redux/selects/user'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { PageId } from 'page-constants'
import { PlatformOS } from 'root-constants'

export const useUserStatus = (): string => {
  const userStatus = useSelector(selectUserStatus)
  const isUserSignedUp = useSelector(selectIsUserSignedUp)
  const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID

  if (userStatus?.hasSubscription && isAndroid && !isUserSignedUp) {
    return PageId.SIGNUP
  }

  if (userStatus?.hasSubscription || isUserSignedUp) {
    return PageId.DOWNLOAD
  }

  return ''
}

import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledListenToBible = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    background: #fafaff;
    min-height: calc(var(--full-height, 100dvh) - 52px);
  `,
  ImageContainer: styled.div`
    aspect-ratio: 375/383;
    margin-bottom: 24px;

    @media screen and (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 375px;
      margin: 0 auto 24px;
    }
  `,
  Image: styled.img`
    width: 100%;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;
  `,
}

import styled from 'styled-components'

import orangeEmptyCheckIcon from 'assets/images/check-icon-empty-orange.svg'

import { Color } from 'root-constants'

type TProps = {
  isSelected: boolean
  isDefault?: boolean
  isOutOfStock?: boolean
}

export const StyledSelectSubscriptionItem = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 12px;
    border-radius: 10px;
    background: ${Color.WHITE};
    box-shadow: ${({ isSelected }) =>
      isSelected ? `0 8px 20px #dfe3f3` : `none`};
  `,
  Content: styled.div<TProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    min-height: 56px;
    border-radius: 12px;
    border: ${({ isSelected }) =>
      isSelected ? `1px solid rgba(0, 0, 0, 0.10)` : `1px solid #CACACA`};
    background: ${({ isSelected }) =>
      isSelected ? `${Color.PRIMARY}` : `${Color.WHITE}`};
    ${({ isSelected }) =>
      isSelected &&
      `
          min-height: 81px;
        `}

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border: 1px solid #555965;
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      transition: opacity 0.5s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
          background: #fff url(${orangeEmptyCheckIcon});
          opacity: 1;
          border: none;
        `}
    }
  `,
  PlanDetails: styled.div`
    width: 100%;
    padding-left: 36px;
    display: flex;
    flex-wrap: wrap;
  `,
  PlanPeriod: styled.span<TProps>`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${({ isSelected }) => (isSelected ? Color.WHITE : Color.BLUE_DARK)};
  `,
  PlanPrices: styled.div`
    flex-basis: 100%;
    margin-top: 5px;
    display: flex;
  `,
  PlanFullPrice: styled.span`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.CORAL_GRAY};
    margin-right: 8px;
  `,
  PlanDiscount: styled.span`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.PRIMARY};
    padding: 2px 10px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
  `,
  PlanMonthlyPrice: styled.span<TProps>`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${({ isSelected }) => (isSelected ? Color.CORAL_GRAY : '#A9A9A9')};
    margin: 0 0 0 auto;
  `,
  PlanMonthlyInfo: styled.span`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.CORAL_GRAY};
    margin: 0 0 0 auto;
  `,
}

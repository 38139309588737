import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { BottomBarPortal } from 'components/BottomBar'
import { Input } from 'components/Input'
import { NavigationButton } from 'components/NavigationButton'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendUserEmailAction } from 'root-redux/actions/user'

import { useEmailInputField } from 'modules/email/hooks/useEmailInputField'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import mailLogo from 'assets/images/sprite/mail-logo.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'

import { StyledEmailV2 as S } from './EmailV2.styles'

export const EmailV2: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const [email, setEmail] = useEmailInputField()
  const isEmailValid = useMemo(() => email.isValid, [email.isValid])

  useEffect(() => {
    eventLogger.logEmailPageShow()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(
      sendUserEmailAction({
        email: email.value,
      }),
    )

    goTo({ pathname: PageId.ANALYZING_ANSWERS, search })
  }

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
    },
    [setEmail],
  )

  return (
    <>
      <S.Wrapper>
        <S.Title>
          <Trans i18nKey="onboarding.emailV2.title" />
        </S.Title>

        <S.Container>
          <form id="email-form" onSubmit={handleSubmit}>
            <Input
              labelName={t('emailForm.label')}
              value={email.value}
              isValid={email.isValid}
              validationText={email.validationText}
              placeholder={t('emailForm.placeholder')}
              iconSvg={mailLogo}
              onChange={handleChange}
            />
          </form>

          <S.TermsOfConfirmation>
            <Trans
              i18nKey="onboarding.emailV2.termsOfConfirmation"
              components={{
                termsOfServices: (
                  <TermsOfUseLink translationKey="commonComponents.termsOfService" />
                ),
                privacyPolicy: <PrivacyPolicyLink />,
              }}
            />
          </S.TermsOfConfirmation>
        </S.Container>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton
          disabled={!email.value || !isEmailValid}
          type="submit"
          form="email-form"
        >
          <Trans i18nKey="actions.getResults" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

export const QUESTION =
  'I frequently feel tired, and my energy levels fluctuate.'

export const STRESS_LEVEL_TYPE = {
  NEVER: 'never',
  RARELY: 'rarely',
  OFTEN: 'often',
  ALL_THE_TIME: 'all_the_time',
}
export const ENERGY_LEVEL_OPTION = [
  {
    value: STRESS_LEVEL_TYPE.NEVER,
    text: 'onboarding.stressLevel.never',
  },
  {
    value: STRESS_LEVEL_TYPE.RARELY,
    text: 'onboarding.stressLevel.rarely',
  },
  {
    value: STRESS_LEVEL_TYPE.OFTEN,
    text: 'onboarding.stressLevel.often',
  },
  {
    value: STRESS_LEVEL_TYPE.ALL_THE_TIME,
    text: 'onboarding.stressLevel.allTheTime',
  },
]

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import lockIcon from 'assets/images/lock-icon.svg'

import { Color } from 'root-constants'

type TButtonContainerProps = {
  isSticky: boolean
}

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.DARK,
    fontFamily: 'Rubik,  sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '60px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: Color.LIGHT,
      fontWeight: 400,
    },
  },
  invalid: {
    color: Color.ERROR,
  },
}

export const commonInputStyles = css`
  color: ${Color.DARK};
  height: 64px;
  background-color: ${Color.WHITE};
  line-height: 64px;
  border-radius: 16px;
  border: 1px solid ${Color.GRAY_LIGHT};
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
  padding-left: 16px;
  transition: border-color 0.2s ease-out;

  &[data-invalid='true'],
  &.StripeElement--invalid {
    border-color: ${Color.ERROR};
  }
`

export const StyledCardPaymentForm = {
  Wrapper: styled.div`
    &[data-is-visible='false'] {
      display: none;
    }
  `,
  PaymentInformationContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  `,
  PaymentInformationText: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.DARK_SECONDARY};
  `,
  Protected: styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: #cacaca;
    padding-left: 18px;
    background: url(${lockIcon}) no-repeat 0 50%;
    background-size: 16px;
  `,
  Form: styled.form``,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  `,
  Label: styled.p`
    padding-bottom: 8px;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardExpiryContainer: styled.div`
    width: calc(50% - 5px);
  `,
  CardCvcContainer: styled.div`
    position: relative;
    width: calc(50% - 5px);
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
  `,
  CardCvcElementIcon: styled.img`
    position: absolute;
    top: 43px;
    right: 15px;
    max-width: 20px;
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    width: 100%;
    font-family: 'Rubik', sans-serif;
    outline: none;
    box-shadow: none;
    font-weight: 500;

    ::placeholder {
      color: ${Color.LIGHT};
      font-weight: 400;
    }
  `,
  Image: styled.img`
    margin: 0 auto 30px;
    max-width: 256px;
  `,
  ButtonContainer: styled.div<TButtonContainerProps>`
    ${({ isSticky }) =>
      isSticky &&
      `
        position: sticky;
        position: -webkit-sticky;
        bottom: 16px;
      `}
  `,
}

/* eslint-disable max-lines */
import { FindGuidance, ListenToBible, Reviews } from 'pages/questions'

import { Purchase, PurchaseV2 } from 'modules/purchase/pages'

import { IDynamicPage, TIdToPageMap } from 'models/common.model'

import { Cohort } from 'root-constants'

import { IntroVariant1 } from '../pages/intro'

export const enum PageId {
  INTRO = 'intro',
  FIND_GUIDANCE = 'findGuidance',
  LISTEN_STORIES = 'listenStories',
  REVIEWS = 'reviews',
  PURCHASE = 'purchase',
  PURCHASE_V2 = 'purchaseV2',
  DOWNLOAD = 'download',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  [PageId.INTRO]: IntroVariant1,
  [PageId.FIND_GUIDANCE]: FindGuidance,
  [PageId.LISTEN_STORIES]: ListenToBible,
  [PageId.REVIEWS]: Reviews,
  [PageId.PURCHASE]: Purchase,
  [PageId.PURCHASE_V2]: PurchaseV2,
}

export const DYNAMIC_PAGES_SET_VARIANT_1: IDynamicPage[] = [
  { id: PageId.INTRO },
  { id: PageId.FIND_GUIDANCE },
  { id: PageId.LISTEN_STORIES },
  { id: PageId.REVIEWS },
]

export const DYNAMIC_PAGES_MAP = {
  [Cohort.FLOW_1_BB_COPY]: [DYNAMIC_PAGES_SET_VARIANT_1],
  [Cohort.FLOW_1_INTRO_FAST]: [DYNAMIC_PAGES_SET_VARIANT_1],
}

export const PURCHASE_PAGES: { id: PageId }[] = [
  { id: PageId.PURCHASE },
  { id: PageId.PURCHASE_V2 },
]

export const PURCHASE_PAGES_MAP: Record<Cohort, PageId> = {
  [Cohort.FLOW_1_BB_COPY]: PageId.PURCHASE,
  [Cohort.FLOW_1_INTRO_FAST]: PageId.PURCHASE_V2,
}

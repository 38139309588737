import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { BottomBarPortal } from 'components/BottomBar'
import { Input } from 'components/Input'
import { NavigationButton } from 'components/NavigationButton'
import { Spinner } from 'components/Spinner'

import {
  SEND_USER_LEARNWORLDS_ACCOUNT,
  sendUserEmailAndPasswordAction,
} from 'root-redux/actions/user'
import { selectActionList } from 'root-redux/selects/common'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'

import { useEmailInputField } from 'modules/email/hooks/useEmailInputField'

import mailLogo from 'assets/images/sprite/mail-logo.svg'

import { usePasswordInputField } from '../hooks/usePasswordInputField'
import { StyledSignUp as S } from './SignUp.styles'

export const SignUp: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userOnboardingEmail = useSelector(selectUserOnboardingEmail)
  const fetchingActionsList = useSelector(selectActionList)

  const [email, setEmail] = useEmailInputField(userOnboardingEmail)
  const [password, setPassword] = usePasswordInputField()

  const isEmailValid = email.isValid
  const isPasswordValid = password.isValid

  const isLearnWorldsAccountCreated = !fetchingActionsList.includes(
    SEND_USER_LEARNWORLDS_ACCOUNT,
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(
      sendUserEmailAndPasswordAction({
        email: email.value,
        password: password.value,
      }),
    )
  }

  const handleEmailChange = useCallback(
    ({ target: { value } }) => {
      const emailValue = value.toLowerCase().trim()

      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
      }))
    },
    [setEmail],
  )

  const handlePasswordChange = useCallback(
    ({ target: { value } }) => {
      setPassword((prevState) => ({
        ...prevState,
        value,
      }))
    },
    [setPassword],
  )

  return !isLearnWorldsAccountCreated ? (
    <Spinner />
  ) : (
    <>
      <S.Wrapper>
        <S.Title>
          <Trans i18nKey="signup.title" />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="signup.subtitle" />
        </S.Subtitle>

        <S.Container>
          <S.Form id="login-form" onSubmit={handleSubmit}>
            <Input
              labelName={t('emailForm.label')}
              value={email.value}
              isValid={email.isValid}
              validationText={email.validationText}
              placeholder={t('emailForm.placeholder')}
              iconSvg={mailLogo}
              onChange={handleEmailChange}
            />

            <Input
              value={password.value}
              isValid={password.isValid}
              labelName={t('signup.passwordLabel')}
              hasVisibilityControl
              placeholder={t('signup.passwordPlaceholder')}
              validationText={password.validationText}
              onChange={handlePasswordChange}
            />
          </S.Form>
        </S.Container>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton
          disabled={
            !email.value || !password.value || !isEmailValid || !isPasswordValid
          }
          type="submit"
          form="login-form"
        >
          <Trans i18nKey="signup.CTA" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

export const enum CurrentEnvironment {
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MEDIUM_PHONE = 360,
  MAX_PHONE = 415,
  TABLET = 520,
}

export const enum CardPaymentFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const TERMS_OF_USE_LINK = 'https://gismart.com/terms-of-services/'
export const PRIVACY_POLICY_LINK = 'https://gismart.com/privacy-policy/'

export const enum Color {
  PRIMARY = '#D9945A',
  ERROR = '#ea5873',
  WHITE = '#fff',
  DARK = '#2d3240',
  DARK_SECONDARY = '#2c3131',
  LIGHT = '#81848c',
  BLUE_DARK = '#17202a',
  BLUE_GRAY = '#4C4C4C',
  MIDNIGHT_BLUE = '#7590CE',
  GRAY = '#626262',
  CORAL_GRAY = '#f2f3f5',
  GRAY_SECONDARY = '#aaacb2',
  GRAY_LIGHT = '#e9e9e9',
  RED = '#e85e47',
  BLACK = '#000000',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum Cohort {
  FLOW_1_BB_COPY = 'flow1_bb_copy',
  FLOW_1_INTRO_FAST = 'flow1_intro_fast',
  FLOW_1_INTRO_FAST2 = 'flow1_intro_fast2',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPGRADE = 'upgrade',
  UPSELL = 'upsell',
}

export const enum SubscriptionTagsType {
  NO_TAX = 'no_tax',
}

export const enum OptionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export const enum Locale {
  ENGLISH = 'en',
  SPANISH = 'es',
}

export const APP_NAME = 'blessedpath'
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const DEFAULT_CURRENCY = 'usd'
export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const LANG_QUERY_PARAM = 'lang'
export const KEYWORD_PARAM = 'lp_dynamic'
export const COHORT_QUERY_PARAM = 'cohort'
export const INITIAL_LANGUAGE = 'en'

export const enum BillingCycle {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  TWELVE_MONTHS = 12,
}

export const enum SubscriptionProduct {
  SEVEN_DAY = 7,
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export const enum ScreenName {
  PURCHASE = 'onboarding',
  CHECKOUT = 'checkout',
}

export const enum Language {
  EN = 'en',
}

export const SUPPORTED_LOCALES = ['en']

export const VAT_INCLUDED_COUNTRY = ['US', 'CA', 'AU']

export const EU_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
]

export const enum CookieConsentOption {
  STRICTLY_NECESSARY = 'necessary',
  ANALYTICS_AND_PERFORMANCE = 'analytics',
  ADVERTIZING_AND_TARGETING = 'advertising',
  FUNCTIONAL_COOKIES = 'functional_cookies',
}

export const TRIAL_BILLING_CYCLE = {
  SEVEN_DAYS: 7,
}

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useScrollToTarget } from 'hooks/useScrollToTarget'

import { TEN_MINUTES } from 'modules/purchase/pages/Purchase/constants'

import { StyledTimerWithDiscount as S } from './TimerWithDiscount.styles'

type TProps = {
  discount: number
  topSubscriptionsBlock: React.RefObject<HTMLDivElement>
  timerElementRef: React.RefObject<HTMLParagraphElement>
  onButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  buttonOrder: number
}

export const TimerWithDiscount: FC<TProps> = ({
  discount,
  topSubscriptionsBlock,
  timerElementRef,
  onButtonClick,
  buttonOrder,
}) => {
  const { t } = useTranslation()
  const { scrollToTarget } = useScrollToTarget()

  return (
    <S.TimerContent>
      <S.TopWrapper>
        <S.TimerTextWrapper>
          <S.TimerText>
            {discount}% {t('purchaseLong.topTimerDisclaimer')}
          </S.TimerText>
          <S.CountdownTime ref={timerElementRef}>
            {!timerElementRef?.current?.hasAttribute('data-value')
              ? TEN_MINUTES.AS_STRING
              : null}
          </S.CountdownTime>
        </S.TimerTextWrapper>
      </S.TopWrapper>
      <S.TopButton
        data-order={buttonOrder}
        onClick={(event) => {
          onButtonClick(event)
          scrollToTarget(topSubscriptionsBlock)
        }}
      >
        <p>{t('actions.ctaGetMyPlan')}</p>
      </S.TopButton>
    </S.TimerContent>
  )
}

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledPeopleLikeYou as S } from './PeopleLikeYou.styles'
import { CHART_DATA, QUESTION } from './constants'

export const PeopleLikeYou: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <>
      <S.Wrapper>
        <S.Container>
          <S.Title>{t('onboarding.peopleLikeYou.title')}</S.Title>
          <S.Subtitle>{t('onboarding.peopleLikeYou.subtitle')}</S.Subtitle>
          <S.Chart>
            {CHART_DATA.map(({ title, height, background }) => (
              <S.ChartBarContainer key={title}>
                <S.ChartBarTitle>{t(title)}</S.ChartBarTitle>
                <S.ChartBar $height={height} $background={background} />
              </S.ChartBarContainer>
            ))}
          </S.Chart>
        </S.Container>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { OptionCard } from 'components/OptionCard'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Gender, Language, OptionType } from 'root-constants'

import { StyledSelectAgeV2 as S } from './SelectAgeV2.styles'
import { MEN_AGE_OPTIONS, WOMEN_AGE_OPTIONS } from './constants'

export const SelectAgeV2: React.FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const userAnswers = useSelector(selectAnswers)

  const isFemale = userAnswers?.selectGenderV2 === Gender.FEMALE
  const ageOptions = isFemale ? WOMEN_AGE_OPTIONS : MEN_AGE_OPTIONS

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.selectAge.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId as string,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
      setAnswer(value)
    },
  }

  return (
    <S.Wrapper>
      <S.Topic>{t('onboarding.selectAge.title')}</S.Topic>
      <S.CardsContainer>
        {ageOptions.map(({ value, imageUrl }) => (
          <OptionCard
            key={value}
            imageUrl={imageUrl}
            value={value}
            label={value}
            checked={answer === value}
            disabled={isAnswersDisabled}
            {...optionProps}
          />
        ))}
      </S.CardsContainer>
    </S.Wrapper>
  )
}

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectCurrentVariantCohort,
  selectGoogleAdsConversions,
} from 'root-redux/selects/common'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PagePath } from 'root-constants'

import { useGetPageInfo } from './useGetPageInfo'

export const useGAPageTracker = (): void => {
  const { pathname } = useLocation()
  const { isFirstPage, isSecondPage } = useGetPageInfo()
  const cohort = useSelector(selectCurrentVariantCohort)
  const googleAdsConversions = useSelector(selectGoogleAdsConversions)

  useEffect(() => {
    const currentOnboardingVariant = cohort ? `/${cohort}` : ``

    if (window.location.href.includes(PagePath.LOCALHOST)) return

    const currentLocation = `${pathname}${currentOnboardingVariant}`

    if (googleAdsConversions) {
      googleAnalyticsLogger.configure(googleAdsConversions)
    }

    if (isFirstPage) {
      googleAnalyticsLogger.logFirstPageView()
    }

    if (isSecondPage) {
      googleAnalyticsLogger.logFirstPageCompleted()
    }

    googleAnalyticsLogger.logPageView(currentLocation)
  }, [pathname, cohort, googleAdsConversions, isFirstPage, isSecondPage])
}

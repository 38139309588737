import { PERSONALITY_TYPE } from 'pages/questions/PersonalityType/constants'

import analyticalImage from 'assets/images/personality-result-analitycal.png'
import bothImage from 'assets/images/personality-result-both.png'
import emotionalImage from 'assets/images/personality-result-emotional.png'

export const QUESTION = 'Personality type result'

export const PERSONALITY_RESULT_MAP = {
  [PERSONALITY_TYPE.THINKER]: {
    title: 'onboarding.personalityResult.titleAnalytical',
    subtitle: 'onboarding.personalityResult.subtitleAnalytical',
    imageUrl: analyticalImage,
  },
  [PERSONALITY_TYPE.FEELER]: {
    title: 'onboarding.personalityResult.titleEmotional',
    subtitle: 'onboarding.personalityResult.subtitleEmotional',
    imageUrl: emotionalImage,
  },
  [PERSONALITY_TYPE.BOTH]: {
    title: 'onboarding.personalityResult.titleBoth',
    subtitle: 'onboarding.personalityResult.subtitleBoth',
    imageUrl: bothImage,
  },
}

import React, { useMemo } from 'react'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { StyledProgressBar as S } from './LinearProgressBar.styles'

export const ProgressBar: React.FC = () => {
  const { currentPageId, hasProgressbar, pagesWithProgressBar } =
    useGetPageInfo()

  const currentPageIndex = useMemo(() => {
    return (
      pagesWithProgressBar.findIndex((step) => step.id === currentPageId) + 1
    )
  }, [currentPageId, pagesWithProgressBar])

  return !hasProgressbar ? null : (
    <S.Wrapper>
      <S.StepBlock>
        <S.StepBlockProgress
          data-active={currentPageIndex > 1}
          value={currentPageIndex}
          max={pagesWithProgressBar.length}
        />
      </S.StepBlock>
    </S.Wrapper>
  )
}

import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledOffersSwiper = {
  CarouselContainer: styled.div`
    max-width: 100%;

    .swiper-container-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: flex;
      justify-content: center;
      bottom: 0;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 6px;
      background: ${Color.SKY_DARK};
      opacity: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet-active {
      background: ${Color.GOLD_DARKEST};
    }
  `,
  SlideWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px 14px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 7/4;
  `,
  Image: styled.img`
    width: 100%;
  `,
  Title: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin: 24px 0 8px;
    color: ${Color.INK_DARKEST};
  `,
  Text: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    padding-bottom: 24px;
  `,
}

import men18 from 'assets/images/image-man-18-34.png'
import men35 from 'assets/images/image-man-35-44.png'
import men45 from 'assets/images/image-man-45-54.png'
import men55 from 'assets/images/image-man-55.png'
import women18 from 'assets/images/image-women-18-34.png'
import women35 from 'assets/images/image-women-35-44.png'
import women45 from 'assets/images/image-women-45-54.png'
import women55 from 'assets/images/image-women-55.png'

export const WOMEN_AGE_OPTIONS = [
  {
    value: '18-34',
    imageUrl: women18,
  },
  {
    value: '35-44',
    imageUrl: women35,
  },
  {
    value: '45-54',
    imageUrl: women45,
  },
  {
    value: '55+',
    imageUrl: women55,
  },
]

export const MEN_AGE_OPTIONS = [
  {
    value: '18-34',
    imageUrl: men18,
  },
  {
    value: '35-44',
    imageUrl: men35,
  },
  {
    value: '45-54',
    imageUrl: men45,
  },
  {
    value: '55+',
    imageUrl: men55,
  },
]

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledResults as S } from './Results.styles'

const StaticCircularProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <div className="circular">
      <svg>
        <circle cx="25" cy="25" r="22" />
        <S.Circle cx="25" cy="25" r="22" percentage={percentage} />
      </svg>
      <div className="percentage">
        {percentage}
        <span>%</span>
      </div>
    </div>
  )
}

export const Results = () => {
  const { t } = useTranslation()
  return (
    <S.Root>
      <S.Title>
        <Trans
          i18nKey="purchase2.results.title"
          components={[<strong />, <br />]}
        />
      </S.Title>
      <ul>
        <S.Result>
          <StaticCircularProgressBar percentage={82} />
          {t('purchase2.results.result1')}
        </S.Result>
        <S.Result>
          <StaticCircularProgressBar percentage={71} />
          {t('purchase2.results.result2')}
        </S.Result>
        <S.Result>
          <StaticCircularProgressBar percentage={45} />
          {t('purchase2.results.result3')}
        </S.Result>
      </ul>
    </S.Root>
  )
}

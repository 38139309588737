import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import finalTouchImage from 'assets/images/final-touch-image.png'

import { Language } from 'root-constants'

import { StyledFinalTouch as S } from './FinalTouch.styles'

export const FinalTouch: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.finalTouch.title', { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <>
      <S.Wrapper>
        <S.ImageContainer>
          <S.Image src={finalTouchImage} />
        </S.ImageContainer>
        <Container>
          <S.Title>
            <Trans i18nKey="onboarding.finalTouch.title" />
          </S.Title>
          <S.Subtitle>{t('onboarding.finalTouch.subtitle')}</S.Subtitle>
        </Container>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

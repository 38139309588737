import { SOCIAL_CONFIDENCE_TYPE } from 'pages/questions/SocialConfidence/constants'

import absolutConfidenceImage from 'assets/images/confidence-strong.png'
import nonConfidenceImage from 'assets/images/confidence-unsure.png'

export const QUESTION = 'Confidence result'

const notConfidentResult = {
  title: 'onboarding.confidenceResult.titleNotConfident',
  subtitle: 'onboarding.confidenceResult.subtitleNotConfident',
  imageUrl: nonConfidenceImage,
}

export const CONFIDENCE_RESULT_MAP = {
  [SOCIAL_CONFIDENCE_TYPE.NOT_AT_ALL]: notConfidentResult,
  [SOCIAL_CONFIDENCE_TYPE.NOT_REALLY]: notConfidentResult,
  [SOCIAL_CONFIDENCE_TYPE.MOSTLY]: notConfidentResult,
  [SOCIAL_CONFIDENCE_TYPE.ABSOLUTELY]: {
    title: 'onboarding.confidenceResult.titleConfident',
    subtitle: 'onboarding.confidenceResult.subtitleConfident',
    imageUrl: absolutConfidenceImage,
  },
}

import menBeforeImage from 'assets/images/men-closed-image.png'
import menAfterImage from 'assets/images/men-opened-image.png'
import womenBeforeImage from 'assets/images/user-close-eyes.png'
import womenAfterImage from 'assets/images/user-open-eyes.png'

import { Gender } from 'root-constants'

export const IDS = {
  BEFORE: 'before',
  AFTER: 'after',
} as const

export type TBeforeAfterId = typeof IDS[keyof typeof IDS]

export interface IBeforeAfterOption {
  id: TBeforeAfterId
  value: string
  imageUrl: string
  imageAlt: string
  header: string
}

export type TOptionsByGender = Record<Gender, IBeforeAfterOption[]>

const createBeforeAfterOptions = (
  beforeImage: string,
  afterImage: string,
): IBeforeAfterOption[] => [
  {
    id: IDS.BEFORE,
    value: 'purchaseLong.beforeAfterCards.before',
    imageUrl: beforeImage,
    imageAlt: `before BlessedPath`,
    header: 'purchaseLong.beforeAfterCards.now',
  },
  {
    id: IDS.AFTER,
    value: 'purchaseLong.beforeAfterCards.after',
    imageUrl: afterImage,
    imageAlt: `after BlessedPath`,
    header: 'purchaseLong.beforeAfterCards.goal',
  },
]

export const OPTIONS_BY_GENDER: TOptionsByGender = {
  [Gender.FEMALE]: createBeforeAfterOptions(womenBeforeImage, womenAfterImage),
  [Gender.MALE]: createBeforeAfterOptions(menBeforeImage, menAfterImage),
}

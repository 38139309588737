import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

import { HEADER_POSITIONS, THeaderPositions } from './ReviewV2'

const commonTextStyles = css`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.INK_DARKEST};
`

export const StyledReviewV2 = {
  ReviewContainer: styled(Container)`
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    border: 1px solid rgba(0, 0, 0, 0.18);
  `,
  Header: styled.div<{ position: THeaderPositions }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ position }) =>
      position === HEADER_POSITIONS.TOP
        ? `margin-bottom: 16px;`
        : `margin-top: 16px;`}
  `,
  NameWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  ReviewerName: styled.p`
    ${commonTextStyles};
    font-weight: 700;
  `,
  ReviewerAge: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    margin-left: 8px;
    align-self: center;
    color: ${Color.INK_LIGHT};
  `,
  Title: styled.p<{ extra?: boolean }>`
    ${commonTextStyles};
    font-weight: 700;
    ${({ extra }) => extra && 'padding-bottom: 8px;'}
  `,
  Rating: styled.div`
    display: flex;
    flex-direction: row;
    gap: 2px;
  `,
  Comment: styled.p`
    ${commonTextStyles};
    font-weight: 400;
  `,
}

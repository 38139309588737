export const DEEPING_FAITH_TYPE = {
  FEELING_CLOSER_TO_GOD: 'feeling_closer_to_god',
  UNDERSTANDING_GOD: 'understanding_god',
  GAINING_WISDOM: 'gaining_wisdom',
  GROWING_SPIRITUALLY: 'growing_spiritually',
}
export const DEEPING_FAITH_OPTION = [
  {
    value: DEEPING_FAITH_TYPE.FEELING_CLOSER_TO_GOD,
    text: 'onboarding.deepingFaith.feelingCloserToGod',
  },
  {
    value: DEEPING_FAITH_TYPE.UNDERSTANDING_GOD,
    text: 'onboarding.deepingFaith.understandingGod',
  },
  {
    value: DEEPING_FAITH_TYPE.GAINING_WISDOM,
    text: 'onboarding.deepingFaith.gainingWisdom',
  },
  {
    value: DEEPING_FAITH_TYPE.GROWING_SPIRITUALLY,
    text: 'onboarding.deepingFaith.growingSpiritually',
  },
]

import React from 'react'

import bellSvg from 'assets/images/sprite/bell.svg'

import { StyledNotification as S } from './Notification.styles'

export interface INotificationProps {
  title: string
  description: string
  className?: string
}

export const Notification: React.FC<INotificationProps> = ({
  className = '',
  title,
  description,
}) => {
  return (
    <S.Root className={className}>
      <S.Icon svg={bellSvg} width={24} />
      <S.TextContainer>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.TextContainer>
    </S.Root>
  )
}

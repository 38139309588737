export const QUESTION = 'My current habits align with my long-term goals.'

export const GOALS_ALIGNMENT_TYPE = {
  STRONGLY_DISAGREE: 'strongly_disagree',
  SOMEWHAT_DISAGREE: 'somewhat_disagree',
  SOMEWHAT_AGREE: 'somewhat_agree',
  STRONGLY_AGREE: 'strongly_agree',
}
export const GOALS_ALIGNMENT_OPTION = [
  {
    value: GOALS_ALIGNMENT_TYPE.STRONGLY_DISAGREE,
    text: 'onboarding.goalsAlignment.stronglyDisagree',
  },
  {
    value: GOALS_ALIGNMENT_TYPE.SOMEWHAT_DISAGREE,
    text: 'onboarding.goalsAlignment.somewhatDisagree',
  },
  {
    value: GOALS_ALIGNMENT_TYPE.SOMEWHAT_AGREE,
    text: 'onboarding.goalsAlignment.somewhatAgree',
  },
  {
    value: GOALS_ALIGNMENT_TYPE.STRONGLY_AGREE,
    text: 'onboarding.goalsAlignment.stronglyAgree',
  },
]

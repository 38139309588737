import { useCallback, useMemo } from 'react'

import { createProductId } from 'helpers/createProductId'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { ScreenName } from 'root-constants'

import { useLogSalePageShown } from './useLogSalePageShown'
import { usePurchaseStore } from './usePurchaseStore'

type TReturnPurchaseAnalytics = {
  sendGeneralPurchaseEvents: (shouldPurchaseShownBeLogged?: boolean) => void
}

export const usePurchaseAnalytics = (
  screenName: ScreenName = ScreenName.PURCHASE,
): TReturnPurchaseAnalytics => {
  const {
    uuid,
    periodName,
    periodQuantity,
    price,
    subscriptions,
    trialPrice,
    stripeAccountId,
    stripeAccountName,
  } = usePurchaseStore()

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || price,
      }),
    [periodName, periodQuantity, trialPrice, price],
  )

  useLogSalePageShown({ subscriptions, screenName })

  const sendGeneralPurchaseEvents = useCallback(
    (shouldPurchaseShownBeLogged = true) => {
      if (shouldPurchaseShownBeLogged) {
        eventLogger.logPurchaseShown({
          productId,
          screenName,
          stripeAccountId,
          stripeAccountName,
        })
      }

      window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })

      googleAnalyticsLogger.logCheckoutStarted(subscriptions)
    },
    [
      uuid,
      subscriptions,
      productId,
      screenName,
      stripeAccountId,
      stripeAccountName,
    ],
  )

  return { sendGeneralPurchaseEvents }
}

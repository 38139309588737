import React from 'react'
import { useTranslation } from 'react-i18next'

import { eventLogger } from 'services/eventLogger.service'

import { TERMS_OF_USE_LINK } from 'root-constants'

import { OuterLink } from '../OuterLink'

interface ITermsOfUseLinkProps {
  translationKey?: string
}

export const TermsOfUseLink: React.FC<ITermsOfUseLinkProps> = ({
  translationKey = `commonComponents.termsOfUse`,
}) => {
  const { t } = useTranslation()

  return (
    <OuterLink
      href={TERMS_OF_USE_LINK}
      onClick={() => eventLogger.logTermsOfUseClicked()}
    >
      {t(translationKey)}
    </OuterLink>
  )
}

import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledReviews = {
  Root: styled.div`
    position: relative;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;
    color: ${Color.BLUE_DARK};
  `,
  Subtitle: styled.h3`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-bottom: 16px;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  Emails: styled.div`
    width: 100%;
    max-width: 100%;
    padding-left: 16px;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 40px;
  `,
  Email: styled.div`
    display: flex;
    border-radius: 8px;
    background: #f2f3f5;
    padding: 8px;
    align-items: center;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    & span:first-child {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: ${Color.BLUE_DARK};
      margin-right: 4px;
    }

    & span:last-child {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: #a9a9a9;
    }
  `,
  Review: styled.div`
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_LIGHT};
    background: ${Color.WHITE};
    padding: 16px;
    margin-bottom: 16px;
  `,
  User: styled.p`
    margin-bottom: 8px;
    display: flex;
  `,
  Name: styled.span`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.BLUE_DARK};
    margin-right: 8px;
  `,
  Age: styled.span`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.GRAY};
  `,
  Rating: styled.div`
    display: flex;
    margin-bottom: 8px;
    max-width: 98px;

    & svg {
      margin-right: 2px;
    }
  `,
  Feedback: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GRAY};
  `,
}

export const QUESTION = `If I knew I couldn't fail, I would pursue my passions.`

export const PASSION_TYPE = {
  STRONGLY_DISAGREE: 'strongly_disagree',
  SOMEWHAT_DISAGREE: 'somewhat_disagree',
  SOMEWHAT_AGREE: 'somewhat_agree',
  STRONGLY_AGREE: 'strongly_agree',
}
export const PASSION_OPTION = [
  {
    value: PASSION_TYPE.STRONGLY_DISAGREE,
    text: 'onboarding.pursuePassion.stronglyDisagree',
  },
  {
    value: PASSION_TYPE.SOMEWHAT_DISAGREE,
    text: 'onboarding.pursuePassion.somewhatDisagree',
  },
  {
    value: PASSION_TYPE.SOMEWHAT_AGREE,
    text: 'onboarding.pursuePassion.somewhatAgree',
  },
  {
    value: PASSION_TYPE.STRONGLY_AGREE,
    text: 'onboarding.pursuePassion.stronglyAgree',
  },
]

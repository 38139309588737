import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledHighlightsV2 = {
  HighlightsContainer: styled.div`
    margin-bottom: 32px;
  `,
  HighlightsTitle: styled.h2`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin: 32px 0 16px;
    text-align: center;
  `,
  Highlights: styled.ul`
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px 0;
  `,
  Highlight: styled.li`
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding-left: 24px;
    padding-right: 24px;

    &:not(:first-child) {
      border-top: 1px solid rgba(0, 0, 0, 0.18);
      padding: 12px 24px 0;
    }
  `,
  Text: styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.INK_DARKEST};

    & strong {
      font-weight: 700;
    }
  `,
  Icon: styled(SvgImage)`
    color: ${Color.BLUE_DARKER};
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    padding: 3.75px;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: 24px;
    border: 1px solid ${Color.BLUE_DARKER};
    background: ${Color.BLUE_DARKER};
  `,
}

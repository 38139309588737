export const QUESTION = 'Do you feel secure about your social status?'

export const SOCIAL_CONFIDENCE_TYPE = {
  NOT_AT_ALL: 'not_at_all',
  NOT_REALLY: 'not_really',
  MOSTLY: 'mostly',
  ABSOLUTELY: 'absolutely',
}

export const SOCIAL_CONFIDENCE_OPTION = [
  {
    value: SOCIAL_CONFIDENCE_TYPE.NOT_AT_ALL,
    text: 'onboarding.socialConfidence.notAtAll',
  },
  {
    value: SOCIAL_CONFIDENCE_TYPE.NOT_REALLY,
    text: 'onboarding.socialConfidence.notReally',
  },
  {
    value: SOCIAL_CONFIDENCE_TYPE.MOSTLY,
    text: 'onboarding.socialConfidence.mostly',
  },
  {
    value: SOCIAL_CONFIDENCE_TYPE.ABSOLUTELY,
    text: 'onboarding.socialConfidence.absolutely',
  },
]

import React from 'react'

import { TProps as TOptionProps } from 'components/Option/Option'
import { SvgImage } from 'components/SvgImage'

import ChevronRight from 'assets/images/sprite/chevron-right.svg'

import { StyledOptionCard as S } from './OptionCard.styles'

export type TOptionCardProps = TOptionProps & {
  imageUrl: string
  label: string
}

export const OptionCard: React.FC<TOptionCardProps> = ({
  imageUrl,
  checked,
  value,
  label,
  ...optionProps
}) => (
  <S.Option
    $imageUrl={imageUrl}
    checked={checked}
    value={value}
    {...optionProps}
  >
    <S.OptionLabel>
      {label}
      <SvgImage svg={ChevronRight} width={24} />
    </S.OptionLabel>
  </S.Option>
)

import brianna from 'assets/images/brianna-before-after-min.jpg'
import jennifer from 'assets/images/jennifer-before-after-min.jpg'
import linda from 'assets/images/linda-before-after-min.jpg'
import marissa from 'assets/images/marissa-before-after-min.jpg'

export const QUESTION_V2 = 'Save 50% with Marissa'

export const QUESTION = 'Select your goal'

export const OPTION_VALUES = {
  GET_RID: 'get_rid_of_wrinkles',
  REDUCE_FAT: 'reduce_face_fat',
  BOTH_OPTIONS: 'both_options',
}

export const REVIEWS = [
  {
    name: 'onboarding.introV2.reviews.marissa',
    image: marissa,
  },
  {
    name: 'onboarding.introV2.reviews.brianna',
    image: brianna,
  },
  {
    name: 'onboarding.introV2.reviews.linda',
    image: linda,
  },
  {
    name: 'onboarding.introV2.reviews.jennifer',
    image: jennifer,
  },
]

export const STEPS = [
  {
    title: `onboarding.introV2.steps.first.stepTitle`,
    description: `onboarding.introV2.steps.first.stepDescription`,
  },
  {
    title: `onboarding.introV2.steps.second.stepTitle`,
    description: `onboarding.introV2.steps.second.stepDescription`,
  },
  {
    title: `onboarding.introV2.steps.third.stepTitle`,
    description: `onboarding.introV2.steps.third.stepDescription`,
  },
]

export enum OBSERVABLE_BLOCKS {
  REVIEWS_BLOCK = 'reviews',
  TERMS_BLOCK = 'terms',
}

export const intersectionOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 1,
}

export const scrolledValues = {
  [OBSERVABLE_BLOCKS.REVIEWS_BLOCK]: '66%',
  [OBSERVABLE_BLOCKS.TERMS_BLOCK]: '100%',
}

export const fixedButtonParams = {
  rootMargin: '-30px 0px 0px 0px',
  threshold: 0.4,
}

export const INTRO_PAGE_DEFAULT_TEXT = {
  title: 'onboarding.intro.title.default',
  subtitle: 'onboarding.intro.subtitle',
  firstAnswer: 'onboarding.intro.firstAnswer.default',
  secondAnswer: 'onboarding.intro.secondAnswer.default',
}

export const INTRO_PAGE_CONTEXT_TEXT_MAP = {}

export const MOBILE_CDN_PATH = 'mobile'
export const DESKTOP_CDN_PATH = 'desktop'

import styled, { keyframes } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

interface IChartBarProps {
  $height: number
  $background: string
}

const growAnimation = keyframes`
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
`

const fadeUpAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const StyledPeopleLikeYou = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 375px;
    padding: 36px 24px 0;
    margin: 0 auto;
  `,
  Container: styled(Container)`
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    background-color: ${Color.WHITE};
    padding: 32px 24px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    padding-bottom: 8px;
  `,
  Subtitle: styled.h3`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.INK_DARKEST};
    text-align: center;
    padding-bottom: 20px;
  `,
  Chart: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12px;
  `,
  ChartBarContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    gap: 8px;
  `,
  ChartBarTitle: styled.p`
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: center;
    color: ${Color.INK_DARKEST};

    opacity: 0;
    animation: ${fadeUpAnimation} 0.6s ease forwards;
    animation-delay: 0.5s;
  `,
  ChartBar: styled.div<IChartBarProps>`
    width: 60px;
    height: ${({ $height }) => `${($height * 160) / 100}px`};
    background: ${({ $background }) => $background};
    border-radius: 8px;
    transform-origin: bottom;
    animation: ${growAnimation} 1s ease forwards;
  `,
}

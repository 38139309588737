import bieberImage from 'assets/images/inspiration-bieber.png'
import curryImage from 'assets/images/inspiration-curry.png'
import kidmanImage from 'assets/images/inspiration-kidman.png'
import matthewImage from 'assets/images/inspiration-matthew.png'
import messiImage from 'assets/images/inspiration-messi.png'
import morganImage from 'assets/images/inspiration-morgan.png'
import muskImage from 'assets/images/inspiration-musk.png'
import obamaImage from 'assets/images/inspiration-obama.png'
import oprahImage from 'assets/images/inspiration-oprah.png'

export const QUESTION = 'Whose achievements and values inspire you the most?'

export const INSPIRATION_PERSON_TYPE = {
  MATTHEW: 'matthew',
  BIEBER: 'bieber',
  OPRAH: 'oprah',
  OBAMA: 'obama',
  CURRY: 'curry',
  MESSI: 'messi',
  KIDMAN: 'kidman',
  MORGAN: 'morgan',
  MUSK: 'musk',
}

export const INSPIRATION_PERSON_OPTION = [
  {
    value: INSPIRATION_PERSON_TYPE.MATTHEW,
    text: 'onboarding.inspirationPerson.subtitleMatthew',
    imageUrl: matthewImage,
  },
  {
    value: INSPIRATION_PERSON_TYPE.BIEBER,
    text: 'onboarding.inspirationPerson.subtitleBieber',
    imageUrl: bieberImage,
  },
  {
    value: INSPIRATION_PERSON_TYPE.OPRAH,
    text: 'onboarding.inspirationPerson.subtitleOprah',
    imageUrl: oprahImage,
  },
  {
    value: INSPIRATION_PERSON_TYPE.OBAMA,
    text: 'onboarding.inspirationPerson.subtitleObama',
    imageUrl: obamaImage,
  },
  {
    value: INSPIRATION_PERSON_TYPE.CURRY,
    text: 'onboarding.inspirationPerson.subtitleCurry',
    imageUrl: curryImage,
  },
  {
    value: INSPIRATION_PERSON_TYPE.MESSI,
    text: 'onboarding.inspirationPerson.subtitleMessi',
    imageUrl: messiImage,
  },
  {
    value: INSPIRATION_PERSON_TYPE.KIDMAN,
    text: 'onboarding.inspirationPerson.subtitleKidman',
    imageUrl: kidmanImage,
  },
  {
    value: INSPIRATION_PERSON_TYPE.MORGAN,
    text: 'onboarding.inspirationPerson.subtitleMorgan',
    imageUrl: morganImage,
  },
  {
    value: INSPIRATION_PERSON_TYPE.MUSK,
    text: 'onboarding.inspirationPerson.subtitleMusk',
    imageUrl: muskImage,
  },
]

export const QUESTION =
  'I consider both short-term and long-term outcomes when making decisions.'

export const DECISION_OUTCOME_TYPE = {
  STRONGLY_DISAGREE: 'strongly_disagree',
  SOMEWHAT_DISAGREE: 'somewhat_disagree',
  SOMEWHAT_AGREE: 'somewhat_agree',
  STRONGLY_AGREE: 'strongly_agree',
}
export const DECISION_OUTCOME_OPTION = [
  {
    value: DECISION_OUTCOME_TYPE.STRONGLY_DISAGREE,
    text: 'onboarding.decisionOutcome.stronglyDisagree',
  },
  {
    value: DECISION_OUTCOME_TYPE.SOMEWHAT_DISAGREE,
    text: 'onboarding.decisionOutcome.somewhatDisagree',
  },
  {
    value: DECISION_OUTCOME_TYPE.SOMEWHAT_AGREE,
    text: 'onboarding.decisionOutcome.somewhatAgree',
  },
  {
    value: DECISION_OUTCOME_TYPE.STRONGLY_AGREE,
    text: 'onboarding.decisionOutcome.stronglyAgree',
  },
]

import React, { useMemo } from 'react'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { TComponentProps } from 'models/common.model'

import { StyledContainer as S } from './Container.styles'
import { LARGE_CONTAINER_COHORTS } from './constants'

export type TContainer = {
  fields?: number
}

export const Container: React.FC<TComponentProps & TContainer> = ({
  children,
  className = '',
  fields,
}) => {
  const cohortToUse = useCohortToUse()

  const containerFields = useMemo(() => {
    if (fields) return fields
    const hasTinyFields = LARGE_CONTAINER_COHORTS.includes(cohortToUse)
    return hasTinyFields ? 16 : 20
  }, [cohortToUse, fields])

  return (
    <S.Container className={className} fields={containerFields}>
      {children}
    </S.Container>
  )
}

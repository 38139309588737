import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnalyzingAnswersModal } from 'components/AnalyzingAnswersModal'
import { ProgressAnalyzer } from 'components/ProgressAnalyzer'
import { HEADER_POSITIONS, ReviewV2 } from 'components/ReviewV2'

import { useCohortInfo } from 'hooks/useCohortInfo'
import { useNextStep } from 'hooks/useNextStep'
import { useProgressAnimationData } from 'hooks/useProgressAnimationData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Color, Language, OptionType, UserAnswer } from 'root-constants'

import { StyledAnalyzingAnswers as S } from './AnalyzingAnswers.styles'
import {
  ANALYZING_ANSWERS_CONFIG,
  DEFAULT_ANALYZING_ANSWERS_CONFIG,
  FIRST_MODAL_DISABLE_POINT,
  FIRST_STOP_COUNTER_AT,
  MODAL_HIDING_DELAY,
  QUESTION,
  REVIEWS,
  REVIEW_HIDING_DELAY,
  REVIEW_NUMBER,
  SECOND_MODAL_DISABLE_POINT,
  SECOND_STOP_COUNTER_AT,
  STATIC_ANIMATION_DURATION,
  THIRD_MODAL_DISABLE_POINT,
  THIRD_STOP_COUNTER_AT,
} from './constants'

export const AnalyzingAnswers: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { cohortToUse } = useCohortInfo()

  const [answers, setAnswers] = useState<UserAnswer[]>([])
  const [visibleReview, setVisibleReview] = useState<number>(REVIEW_NUMBER[0])
  const [isAnimating, setIsAnimating] = useState<boolean>(false)

  const config =
    ANALYZING_ANSWERS_CONFIG[cohortToUse] ?? DEFAULT_ANALYZING_ANSWERS_CONFIG

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
  }

  const switchReview = (nextReview: number) => {
    setIsAnimating(true)
    setTimeout(() => {
      setVisibleReview(nextReview)
      setIsAnimating(false)
    }, REVIEW_HIDING_DELAY)
  }

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const goToNextPage = useCallback(() => {
    eventLogger.logGenerationPlanScreen()
    handleContinue(answers)
  }, [answers, handleContinue])

  const handleContinueAnimation = (playback: () => void) => {
    const timerId = setTimeout(() => {
      playback && playback()
      clearTimeout(timerId)
    }, MODAL_HIDING_DELAY)
  }
  const handleChange = (animationPlayback: () => void) => {
    handleContinueAnimation(animationPlayback)
  }

  const {
    firstCounter,
    isFirstCounterFinished,
    firstProgressAnimationPlayback,
    secondCounter,
    isSecondCounterFinished,
    secondProgressAnimationPlayback,
    thirdCounter,
    isThirdCounterFinished,
    thirdProgressAnimationPlayback,
  } = useProgressAnimationData({
    goToNextPage,
    STATIC_ANIMATION_DURATION,
    FIRST_STOP_COUNTER_AT,
    SECOND_STOP_COUNTER_AT,
    THIRD_STOP_COUNTER_AT,
  })

  const finished = [
    isFirstCounterFinished,
    isSecondCounterFinished,
    isThirdCounterFinished,
  ]

  useEffect(() => {
    if (isFirstCounterFinished) switchReview(REVIEW_NUMBER[1])
  }, [isFirstCounterFinished])

  useEffect(() => {
    if (isSecondCounterFinished) switchReview(REVIEW_NUMBER[2])
  }, [isSecondCounterFinished])

  return (
    <S.Container>
      <S.Subtitle>{t(config.subtitle)}</S.Subtitle>
      <S.Title>
        <Trans i18nKey={config.title} />
      </S.Title>

      <S.ProgressContainer>
        {config.progressSteps.map(({ progressTitle }, id) => (
          <ProgressAnalyzer
            key={progressTitle}
            color={Color.GOLD_BASE}
            value={[firstCounter, secondCounter, thirdCounter][id]}
            isInProgress={!![firstCounter, secondCounter, thirdCounter][id]}
            isFinished={finished[id]}
          >
            <Trans i18nKey={progressTitle} />
          </ProgressAnalyzer>
        ))}
      </S.ProgressContainer>

      <S.ReviewWrapper $isVisible={isAnimating}>
        {REVIEWS.map(
          ({ title, author, comment }, index) =>
            visibleReview === REVIEW_NUMBER[index] && (
              <ReviewV2
                key={author}
                headerPosition={HEADER_POSITIONS.BOTTOM}
                reviewerName={t(author)}
                comment={t(comment)}
                title={t(title)}
              />
            ),
        )}
      </S.ReviewWrapper>

      <AnalyzingAnswersModal
        isModalVisible={firstCounter === FIRST_STOP_COUNTER_AT}
        disabled={answers.length > FIRST_MODAL_DISABLE_POINT}
        {...optionProps}
        onChange={(answer) => {
          setAnswers((prevAnswers) => [...prevAnswers, answer])
          eventLogger.logQuestionPopupTap({
            question: t(config.progressSteps[0].modalQuestion, {
              lng: Language.EN,
            }),
            answers: answer,
          })
          handleChange(firstProgressAnimationPlayback)
        }}
      >
        <Trans i18nKey={config.progressSteps[0].modalQuestion} />
      </AnalyzingAnswersModal>

      <AnalyzingAnswersModal
        isModalVisible={secondCounter === SECOND_STOP_COUNTER_AT}
        disabled={answers.length > SECOND_MODAL_DISABLE_POINT}
        {...optionProps}
        onChange={(answer) => {
          setAnswers((prevAnswers) => [...prevAnswers, answer])
          eventLogger.logQuestionPopupTap({
            question: t(config.progressSteps[1].modalQuestion, {
              lng: Language.EN,
            }),
            answers: answer,
          })
          handleChange(secondProgressAnimationPlayback)
        }}
      >
        <Trans i18nKey={config.progressSteps[1].modalQuestion} />
      </AnalyzingAnswersModal>

      <AnalyzingAnswersModal
        isModalVisible={thirdCounter === THIRD_STOP_COUNTER_AT}
        disabled={answers.length > THIRD_MODAL_DISABLE_POINT}
        {...optionProps}
        onChange={(answer) => {
          setAnswers((prevAnswers) => [...prevAnswers, answer])
          eventLogger.logQuestionPopupTap({
            question: t(config.progressSteps[2].modalQuestion, {
              lng: Language.EN,
            }),
            answers: answer,
          })
          handleChange(thirdProgressAnimationPlayback)
        }}
      >
        <Trans i18nKey={config.progressSteps[2].modalQuestion} />
      </AnalyzingAnswersModal>
    </S.Container>
  )
}

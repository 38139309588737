export const IDEAL_PLACE_TYPE = {
  BEDROOM: 'bedroom',
  FAVORITE_CHAIR: 'favorite_chair',
  HOME_COZY_CORNER: 'home_cozy_corner',
  CHURCH_OR_PRAYER_ROOM: 'church_or_prayer_room',
  QUIET_COFFEE_SHOP: 'quiet_coffee_shop',
  PARK_OR_GARDEN: 'park_or_garden',
  FRIENDS_HOUSE_OR_SMALL_GROUP: 'friends_house_or_small_group',
  OTHER: 'other',
}

export const IDEAL_PLACE_OPTION = [
  {
    value: IDEAL_PLACE_TYPE.BEDROOM,
    text: 'onboarding.idealPlace.bedroom',
  },
  {
    value: IDEAL_PLACE_TYPE.FAVORITE_CHAIR,
    text: 'onboarding.idealPlace.favoriteChair',
  },
  {
    value: IDEAL_PLACE_TYPE.HOME_COZY_CORNER,
    text: 'onboarding.idealPlace.homeCozyCorner',
  },
  {
    value: IDEAL_PLACE_TYPE.CHURCH_OR_PRAYER_ROOM,
    text: 'onboarding.idealPlace.churchOrPrayerRoom',
  },
  {
    value: IDEAL_PLACE_TYPE.QUIET_COFFEE_SHOP,
    text: 'onboarding.idealPlace.quietCoffeeShop',
  },
  {
    value: IDEAL_PLACE_TYPE.PARK_OR_GARDEN,
    text: 'onboarding.idealPlace.parkOrGarden',
  },
  {
    value: IDEAL_PLACE_TYPE.FRIENDS_HOUSE_OR_SMALL_GROUP,
    text: 'onboarding.idealPlace.friendsHouseOrSmallGroup',
  },
  {
    value: IDEAL_PLACE_TYPE.OTHER,
    text: 'onboarding.idealPlace.other',
  },
]

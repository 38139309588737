import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import badgeImg from 'assets/images/sprite/badge.svg'

import { StyledMoneyBackGuarantee as S } from './MoneyBackGuarantee.styles'

export const MoneyBackGuarantee: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`purchase2.moneyBackGuarantee.title`}</S.Title>
        <S.Text>
          <Trans i18nKey="purchase2.moneyBackGuarantee.text" />{' '}
          <TermsOfUseLink />
        </S.Text>
        <S.Badge svg={badgeImg} />
      </S.Content>
    </S.Wrapper>
  )
}

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledCircular as C, StyledResults as S } from './Results.styles'

const StaticCircularProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <C.Container>
      <C.CircularSVG>
        <C.Circle cx="25" cy="25" r="22" />
        <C.ProgressCircle cx="25" cy="25" r="22" percentage={percentage} />
      </C.CircularSVG>
      <C.PercentageText>
        {percentage}
        <span>%</span>
      </C.PercentageText>
    </C.Container>
  )
}

export const Results = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.Title>
        <Trans
          i18nKey="purchase2.results.title"
          components={[<strong />, <br />]}
        />
      </S.Title>
      <ul>
        <S.Result>
          <StaticCircularProgressBar percentage={82} />
          {t('purchase2.results.result1')}
        </S.Result>
        <S.Result>
          <StaticCircularProgressBar percentage={71} />
          {t('purchase2.results.result2')}
        </S.Result>
        <S.Result>
          <StaticCircularProgressBar percentage={45} />
          {t('purchase2.results.result3')}
        </S.Result>
      </ul>
    </S.Root>
  )
}

import { AnswerWithCheckbox } from 'storybook-ui'
import styled from 'styled-components'

import radioIcon from 'assets/images/radio.svg'

export const AnswerWithRadioIcon = styled(AnswerWithCheckbox)`
  & {
    input:checked + & > span {
      background-image: url(${radioIcon});
    }
  }
`

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'

import { ISubscription } from 'models/subscriptions.model'

import { SubscriptionProduct } from 'root-constants'

import { StyledSelectSubscriptionItemV2 as S } from './SelectSubscriptionItemV2.styles'
import { WEEKLY_PLANS_DURATION_MAP } from './constants'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SelectSubscriptionItemV2: React.FC<TProps> = ({
  subscription: { id, isDefault, mainPrices, trialPrices, currency },
  isSelected,
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper isSelected={isSelected} key={id}>
      {isDefault && (
        <S.MostPopularBadge>
          {t`purchaseIntroOffer.subscription.mostPopularBadge`}
        </S.MostPopularBadge>
      )}
      <S.Content isSelected={isSelected} isDefault={isDefault}>
        <S.PlanContainer>
          <S.PlanPeriod>
            {t(
              trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY
                ? 'purchase2.weeklyPeriod'
                : 'purchase2.monthlyPeriod',
              {
                count: WEEKLY_PLANS_DURATION_MAP[trialPrices.durationDays],
              },
            )}
          </S.PlanPeriod>
          <S.PricesComparisonWrapper>
            <S.PreviousPrice>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.oldPrice"
                values={{
                  oldPrice:
                    trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY
                      ? trialPrices.oldPrices.fullPrice
                      : mainPrices.fullPrice,
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PreviousPrice>

            <S.CurrentPrice>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.price"
                values={{
                  price: trialPrices.fullPrice,
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CurrentPrice>
          </S.PricesComparisonWrapper>
        </S.PlanContainer>
        <S.OldDailyPrice>
          <Trans
            i18nKey="purchaseIntroOffer.subscription.oldestPrice"
            values={{
              oldestPrice: trialPrices.oldPrices.daily,
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.OldDailyPrice>
        <S.CustomPrice isSelected={isSelected}>
          <S.PriceValue isSelected={isSelected}>
            {t('purchaseIntroOffer.subscription.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: trialPrices.daily,
            })}
          </S.PriceValue>
          <S.Period isSelected={isSelected}>
            {t('purchase1.subscription.per', { context: 'day' })}
            {t`purchase1.subscription.day`}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}

import maleImage from 'assets/images/image-man.png'
import femaleImage from 'assets/images/image-women.png'

import { Gender } from 'root-constants'

export const GENDER_OPTIONS = [
  {
    value: Gender.FEMALE,
    label: 'Female',
    imageUrl: femaleImage,
  },
  {
    value: Gender.MALE,
    label: 'Male',
    imageUrl: maleImage,
  },
]

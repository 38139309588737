import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserTTCLID } from 'root-redux/actions/user'
import { selectTiktokPixelId } from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

export const useTiktokPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const tiktokPixelId = useSelector(selectTiktokPixelId)
  const email = useSelector(selectUserOnboardingEmail)
  const uuid = useSelector(selectUUID)

  useLayoutEffect(() => {
    if (!tiktokPixelId || !uuid) return
    window.ttq.load(tiktokPixelId)
    window.ttq.identify({ email })
    window.ttq.page({ event_id: uuid })

    const searchParams = new URLSearchParams(document.location.search)
    const ttclid = searchParams.get('ttclid')

    if (ttclid) dispatch(sendUserTTCLID(ttclid))
  }, [uuid, email, tiktokPixelId, dispatch])
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Review } from 'components/Review'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PinnedButtonContainer } from 'common-styles'

import { StyledReviews as S } from './Reviews.styles'
import { DEFAULT_CUSTOMER_REVIEWS, QUESTION } from './constants'

export const Reviews: React.FC<TPageProps> = ({ pageId }) => {
  const { search } = useLocation()
  const { t } = useTranslation()

  const { currentSubscriptionPageId } = useGetPageInfo()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: `${currentSubscriptionPageId}${search}`,
  })

  return (
    <S.Root>
      <Container>
        {DEFAULT_CUSTOMER_REVIEWS.map((review) => (
          <Review
            key={review.name}
            icon={review.icon}
            name={review.name}
            review={review.review}
          />
        ))}
        <S.Title>{t('onboarding.worldwideCommunity.title')}</S.Title>
      </Container>
      <PinnedButtonContainer>
        <Button onClick={() => handleContinue('')}>
          {t('actions.continue')}
        </Button>
      </PinnedButtonContainer>
    </S.Root>
  )
}

import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/brown-check-icon.svg'
import priceBadgeSelected from 'assets/images/price-badge-selected.png'
import priceBadge from 'assets/images/price-badge.png'

import { Color } from 'root-constants'

type TProps = {
  isSelected: boolean
  isDefault?: boolean
}

export const StyledSelectSubscriptionItemV2 = {
  Wrapper: styled.div<TProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;
    background: ${Color.WHITE};
  `,
  Content: styled.div<TProps>`
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: relative;
    border-radius: 12px;
    background-color: ${Color.WHITE};

    ${({ isSelected }) =>
      isSelected
        ? css`
            border: 1px solid ${Color.PRIMARY};
          `
        : css`
            border: 1px solid #cacaca;
          `};

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border: 1px solid ${Color.GRAY_SECONDARY};
      border-radius: 50%;
      opacity: 0.5;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      transition: opacity 0.15s ease-out;

      ${({ isSelected }) =>
        isSelected &&
        `
          background: url(${checkIcon}) no-repeat center;
          background-size: 22px 22px;
          opacity: 1;
          border: none;
        `}
    }
  `,
  PlanContainer: styled.div`
    padding-left: 32px;
  `,
  MostPopularBadge: styled.div`
    padding: 4px 12px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 120% */
    letter-spacing: 1px;
    border-radius: 24px;
    color: ${Color.WHITE};
    text-transform: uppercase;
    background-color: ${Color.PRIMARY};
    position: absolute;
    left: 44px;
    z-index: 2;
    top: 0;
    transform: translateY(-50%);
  `,
  PlanPeriod: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-transform: capitalize;
    color: ${Color.DARK};
    margin-bottom: 4px;
  `,
  PricesComparisonWrapper: styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    min-width: 110px;
  `,
  PreviousPrice: styled.span`
    display: flex;
    align-items: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.GRAY};
    margin-right: 8px;
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
    white-space: nowrap;
  `,
  CurrentPrice: styled.span`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.GRAY};
  `,
  CustomPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 87px;
    min-height: 52px;
    background: ${({ isSelected }) =>
      isSelected
        ? `url(${priceBadgeSelected}) no-repeat center`
        : `url(${priceBadge}) no-repeat center`};
    background-size: contain;
    transition: all 0.15s ease-out;
  `,
  OldDailyPrice: styled.span`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    align-self: flex-end;
    color: ${Color.GRAY};
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
  `,
  PriceValue: styled.span<TProps>`
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${({ isSelected }) => (isSelected ? Color.WHITE : Color.BLUE_GRAY)};
  `,
  Period: styled.span<TProps>`
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 127.273% */
    letter-spacing: 0.5px;
    color: ${({ isSelected }) => (isSelected ? Color.WHITE : Color.BLUE_GRAY)};
  `,
}

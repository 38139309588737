import { SubscriptionProduct } from 'root-constants'

export const DEFAULT_DISCOUNT = 50

export const TEN_MINUTES = {
  IN_SECONDS: 600,
  AS_STRING: '10:00',
}

export const BENEFITS_ORDER = [1, 2, 3, 4, 5]

export const DISCLAIMER = {
  [SubscriptionProduct.SEVEN_DAY]: 'purchase2.disclaimerWeekly',
  [SubscriptionProduct.ONE_MONTH]: 'purchase2.disclaimerMonthly',
  [SubscriptionProduct.THREE_MONTHS]: 'purchase2.disclaimerQuarterly',
}

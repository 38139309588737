import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectFacebookPixelIds,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { COHORT_QUERY_PARAM, Locale } from 'root-constants'

export const useFacebookPixelInitialization = (): void => {
  const { search } = document.location
  const uuid = useSelector(selectUUID)
  const facebookPixelIds = useSelector(selectFacebookPixelIds)
  const userCountryCode = useSelector(selectUserCountryCode)
  const language = useSelector(selectLanguage)

  const URLParams = new URLSearchParams(search)
  const cohort = URLParams.get(COHORT_QUERY_PARAM)

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  useLayoutEffect(() => {
    if (!window.fbq || !facebookPixelIds.length) {
      return
    }

    facebookPixelIds.forEach((facebookPixelId) => {
      window.fbq('init', facebookPixelId, { external_id: uuid })
    })

    window.fbq('track', 'PageView')
  }, [cohort, facebookPixelIds, uuid, userCountryCode, isEnglishLang])
}

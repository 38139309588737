import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import listenToBibleImage from 'assets/images/listen-to-bible.png'

import { PinnedButtonContainer } from 'common-styles'

import { StyledListenToBible as S } from './ListenToBible.styles'
import { QUESTION } from './constants'

export const ListenToBible: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <S.Root>
      <S.ImageContainer>
        <S.Image src={listenToBibleImage} />
      </S.ImageContainer>

      <Container>
        <S.Title>{t('onboarding.listenToBible.title')}</S.Title>
      </Container>
      <PinnedButtonContainer>
        <Button onClick={() => handleContinue('')}>
          {t('actions.continue')}
        </Button>
      </PinnedButtonContainer>
    </S.Root>
  )
}

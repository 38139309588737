import { useSelector } from 'react-redux'

import { selectUserStatus } from 'root-redux/selects/user'

import { PageId } from 'page-constants'

export const useUserStatus = (): string => {
  const userStatus = useSelector(selectUserStatus)

  if (userStatus?.hasSubscription) {
    return PageId.DOWNLOAD
  }

  return ''
}

export const RESULTS = [
  {
    text: 'purchaseLong.results.result1',
    percentage: 82,
  },
  {
    text: 'purchaseLong.results.result2',
    percentage: 71,
  },
  {
    text: 'purchaseLong.results.result3',
    percentage: 45,
  },
]

export const TIME_INVESTMENT_TYPE = {
  SHORT: 'short',
  AVERAGE: 'average',
  SIGNIFICANT: 'significant',
  DEDICATED: 'dedicated',
}

export const TIME_INVESTMENT_OPTIONS = [
  {
    value: TIME_INVESTMENT_TYPE.SHORT,
    text: 'onboarding.growthTimeInvestment.short',
  },
  {
    value: TIME_INVESTMENT_TYPE.AVERAGE,
    text: 'onboarding.growthTimeInvestment.average',
  },
  {
    value: TIME_INVESTMENT_TYPE.SIGNIFICANT,
    text: 'onboarding.growthTimeInvestment.significant',
  },
  {
    value: TIME_INVESTMENT_TYPE.DEDICATED,
    text: 'onboarding.growthTimeInvestment.dedicated',
  },
]

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { Gender } from 'root-constants'

import { StyledBeforeAfterCardsV2 as S } from './BeforeAfterCardsV2.styles'
import { OPTIONS_BY_GENDER } from './constants'

export const BeforeAfterCardsV2 = () => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)

  const cardOptions =
    OPTIONS_BY_GENDER[userAnswers?.selectGenderV2] ||
    OPTIONS_BY_GENDER[Gender.FEMALE]

  return (
    <>
      <S.CardsHeader>
        <S.HeaderSegment>{t(cardOptions[0].header)}</S.HeaderSegment>
        <S.Divider />
        <S.HeaderSegment active>{t(cardOptions[1].header)}</S.HeaderSegment>
      </S.CardsHeader>

      <S.Cards>
        {cardOptions.map(({ id, value, imageUrl, imageAlt }) => {
          return (
            <S.Card key={id}>
              <S.Image src={imageUrl} alt={imageAlt} />
              <S.Text>
                <Trans i18nKey={value} />
              </S.Text>
            </S.Card>
          )
        })}
      </S.Cards>
    </>
  )
}

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import checkWhiteIcon from 'assets/images/sprite/check.svg'

import { StyledHighlightsV2 as S } from './HighlightsV2.styles'

export const HighlightsV2 = () => {
  const { t } = useTranslation()

  return (
    <S.HighlightsContainer>
      <S.HighlightsTitle>
        {t('purchaseLong.highlightsTitleV2')}
      </S.HighlightsTitle>

      <S.Highlights>
        {(
          t('purchaseLong.highlightsV2', { returnObjects: true }) as string[]
        ).map((item, index) => (
          <S.Highlight key={item}>
            <S.Icon svg={checkWhiteIcon} width={12.5} />
            <S.Text>
              <Trans i18nKey={`purchaseLong.highlightsV2.${index}`} />
            </S.Text>
          </S.Highlight>
        ))}
      </S.Highlights>
    </S.HighlightsContainer>
  )
}

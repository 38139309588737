import styled from 'styled-components'

import { BackButton } from 'components/BackButton'

import { Color } from 'root-constants'

export const StyledHeaderV2 = {
  Wrapper: styled.header<{ isWhiteHeader?: boolean }>`
    position: relative;
    z-index: 1;
    padding: 12px 0;
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: ${({ isWhiteHeader }) =>
      isWhiteHeader ? Color.WHITE : Color.SKY_BASE};
    border-bottom: ${({ isWhiteHeader }) =>
      isWhiteHeader ? `1px solid rgba(0, 0, 0, 0.18)` : 'none'};
  `,
  Text: styled.span`
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.INK_DARKEST};
    margin-left: auto;
    margin-right: auto;
  `,
  HeaderBackButton: styled(BackButton)`
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  `,
}

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import phoneImage from 'assets/images/phone-blessedpath.png'
import paymentSuccessIcon from 'assets/images/sprite/green-successfull-tick.svg'

import { StyledDownloadLong as S } from './DownloadLong.styles'
import { DOWNLOAD_MAP } from './constants'

export const DownloadLong: React.FC = () => {
  const { t } = useTranslation()
  const platform = getMobileOperatingSystem()

  const { title, subtitle, ctaText, link } = DOWNLOAD_MAP[platform]

  useEffect(() => {
    eventLogger.logGettingAppShown()
  }, [])

  const handleContinue = () => {
    window.location.href = link
  }

  const handleClick = () => {
    googleAnalyticsLogger.logDownloadButtonPressed()
    eventLogger.logDownloadTap(handleContinue)
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.SvgImage svg={paymentSuccessIcon} width={48} />
        <S.Title>{t(title)}</S.Title>
        <S.Subtitle>{t(subtitle)}</S.Subtitle>
        <S.Button type="button" onClick={handleClick}>
          {t(ctaText)}
        </S.Button>
      </S.Container>
      <S.SaleImage src={phoneImage} />
    </S.Wrapper>
  )
}

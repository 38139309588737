export const QUESTION =
  'Do you think it’s important to have a consistent routine?'

export const DAILY_ROUTINE = {
  YES: 'yes',
  NO: 'no',
} as const

export const OPTION_VALUES = {
  [DAILY_ROUTINE.YES]: {
    value: DAILY_ROUTINE.YES,
    label: 'onboarding.dailyRoutine.yes',
  },
  [DAILY_ROUTINE.NO]: {
    value: DAILY_ROUTINE.NO,
    label: 'onboarding.dailyRoutine.no',
  },
}

export const ASPECT_TO_IMPROVE_TYPE = {
  UNDERSTANDING_GOD_WORD: 'understanding_god_word',
  TEACHING_DAILY: 'teaching_daily',
  STRENTHENING_MY_TRUST: 'strenthening_my_trust',
  OTHER: 'other',
}

export const ASPECT_TO_IMPROVE_OPTION = [
  {
    value: ASPECT_TO_IMPROVE_TYPE.UNDERSTANDING_GOD_WORD,
    text: 'onboarding.aspectToImprove.understandingGodWord',
  },
  {
    value: ASPECT_TO_IMPROVE_TYPE.TEACHING_DAILY,
    text: 'onboarding.aspectToImprove.teachingDaily',
  },
  {
    value: ASPECT_TO_IMPROVE_TYPE.STRENTHENING_MY_TRUST,
    text: 'onboarding.aspectToImprove.strentheningMyTrust',
  },
  {
    value: ASPECT_TO_IMPROVE_TYPE.OTHER,
    text: 'onboarding.aspectToImprove.other',
  },
]

import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecuredPaymentBadge = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 16px 0;
  `,
  SecuredBadge: styled.div`
    background-color: ${Color.GREEN_LIGHTER};
    border-radius: 4px;
    padding: 4px 8px;
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    gap: 2px;
  `,
  SecureBadgeText: styled.span`
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: ${Color.GREEN_DARKEST};
    align-items: center;
  `,
}

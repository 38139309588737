import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { Language } from 'root-constants'

dayjs.extend(customParseFormat)

const FOUR_MONTHS = 4

export const SHORT_MONTH_FORMAT = 'MMM'
export const FULL_MONTH_FORMAT = 'MMMM'
export const DAY_MONTH_FORMAT = 'D MMM'

export const getMonthThreeFromNowFullName = (locale?: string): string => {
  return dayjs()
    .add(3, 'month')
    .locale(locale || Language.EN)
    .format(FULL_MONTH_FORMAT)
}

export const getThreeMonthsFromNowAbbr = (locale?: string): string[] => {
  return Array.from({ length: FOUR_MONTHS }, (_, i) =>
    dayjs()
      .add(i, 'month')
      .locale(locale || Language.EN)
      .format(SHORT_MONTH_FORMAT),
  )
}

export const getLearningFinalDate = (locale?: string): string => {
  return dayjs()
    .add(28, 'day')
    .locale(locale || Language.EN)
    .format(DAY_MONTH_FORMAT)
}

import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledReviewsLong = {
  Root: styled.div`
    position: relative;
    margin-bottom: 32px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 32px 0 16px;
    text-align: center;
    color: ${Color.BLUE_DARK};
  `,
  Subtitle: styled.h3`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
    text-align: center;
    margin-bottom: 24px;
  `,
  Emails: styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
  `,
  Email: styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 48px;
    background: ${Color.WHITE};
    padding: 8px 16px;
    align-items: center;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    & span:first-child {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: ${Color.INK_DARKEST};
      margin-right: 8px;
    }

    & span:last-child {
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      color: ${Color.INK_LIGHT};
    }
  `,
  Reviews: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  `,
}

import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div`
    position: relative;
    margin-bottom: 64px;
    border-radius: 24px;
    border: 1px solid #e9e9e9;
    background-color: ${Color.WHITE};
    padding: 8px;
  `,
  Title: styled.h2`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px;
    color: ${Color.BLUE_DARK};
  `,
  Content: styled.div`
    padding: 24px 16px;
    border: 1px solid #7590ce;
    border-radius: 24px;
  `,
  Text: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.GRAY};
    text-align: center;

    & > a {
      color: ${Color.GRAY};
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-decoration-line: underline;
    }
  `,
  Badge: styled(SvgImage)`
    position: absolute;
    bottom: -68px;
    right: -12px;
    width: 92px;
  `,
}

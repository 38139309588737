import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants'

type TPropsWrapper = {
  isShown: boolean
}

const BaseButton = styled(Button)`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 48px;
  width: 100%;
  height: 48px;
  padding: 16px 32px;
  margin-top: 16px;
`

export const StyledPaymentWaitingModalLong = {
  Wrapper: styled.div<TPropsWrapper>`
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 100 : -1)};
    width: max(100%, var(--min-screen-width));
    height: calc(var(--full-height) - calc(var(--full-height) - 100%));
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition-duration: 100ms;
    transition-property: opacity;
    transition-timing-function: ease-out;
  `,
  Content: styled.div`
    width: 327px;
    margin: auto;
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 24px;
    background-color: ${Color.WHITE};
  `,
  LottieContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  `,
  Animation: styled.div`
    width: 64px;
    height: 64px;
  `,
  Title: styled.p`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding-top: 32px;
    text-align: center;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    padding-top: 12px;
  `,
  SuccessButton: styled(BaseButton)`
    background-color: ${Color.INK_DARKEST};
    color: ${Color.GOLD_LIGHTER};
  `,
  ErrorTitle: styled.p`
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    padding-top: 32px;
    text-align: center;
  `,
  ErrorButton: styled(BaseButton)`
    background-color: ${Color.WHITE};
    color: ${Color.INK_DARKEST};
    border: 1px solid ${Color.INK_DARKEST};
  `,
}

export const QUESTION =
  'Select the areas of your life you’d like to enhance or improve:'

export const OPTION_VALUES = {
  MENTAL_HEALTH: 'emotional_mental_health',
  RELATIONSHIPS: 'love_relationships',
  PURPOSE: 'purpose_calling',
  FINANCIAL: 'financial_wellness',
  DECISION: 'decision_making',
  SPIRITUALITY: 'spirituality',
  OTHER: 'other',
}

export const ARIAS_TO_IMPROVE_MAP = [
  {
    value: OPTION_VALUES.MENTAL_HEALTH,
    text: 'onboarding.ariasToImprove.mentalHealth',
  },
  {
    value: OPTION_VALUES.RELATIONSHIPS,
    text: 'onboarding.ariasToImprove.relationship',
  },
  {
    value: OPTION_VALUES.PURPOSE,
    text: 'onboarding.ariasToImprove.purpose',
  },
  {
    value: OPTION_VALUES.FINANCIAL,
    text: 'onboarding.ariasToImprove.financial',
  },
  {
    value: OPTION_VALUES.DECISION,
    text: 'onboarding.ariasToImprove.decision',
  },
  {
    value: OPTION_VALUES.SPIRITUALITY,
    text: 'onboarding.ariasToImprove.spirituality',
  },
  {
    value: OPTION_VALUES.OTHER,
    text: 'onboarding.ariasToImprove.other',
  },
]

import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecurityInfo = {
  Wrapper: styled.div`
    margin: 64px 0 0;
    padding: 48px 16px 32px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px #d1d6eb;
    border-radius: 14px;
    position: relative;
  `,
  Title: styled.h2`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
    color: ${Color.DARK_SECONDARY};
  `,
  Text: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GRAY};
    margin-bottom: 20px;

    a {
      font-weight: 500;
      color: ${Color.PRIMARY};
    }
  `,
  Badge: styled.img`
    position: absolute;
    width: 156px;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 2;
  `,
}

import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import 'root-redux/actions/user'
import { selectGoogleAnalyticsId } from 'root-redux/selects/common'

export const useGoogleAnalytics = (): void => {
  const googleAnalyticsId = useSelector(selectGoogleAnalyticsId)

  useEffect(() => {
    if (googleAnalyticsId) {
      const initGtag = () => {
        const script = document.createElement('script')
        script.text = `window.dataLayer = window.dataLayer || [];
        gtag = function () { dataLayer.push(arguments); }
        gtag('js', new Date());`
        script.async = true
        document.head.appendChild(script)
      }

      const setUpGTM = () => {
        const script = document.createElement('script')
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
        script.async = true
        document.head.appendChild(script)
      }

      initGtag()
      setUpGTM()

      !!window.gtag &&
        window.gtag('config', googleAnalyticsId, {
          send_page_view: false,
        })
    }
  }, [googleAnalyticsId])
}

import { useEffect } from 'react'

export const useTrackPageScrollDepth = ({
  trackedBreakpoints = [16, 33, 50, 66, 83, 100],
  eventCallback,
  preventSendingEvents = false,
  containerSelector = '.viewport-container',
}: {
  trackedBreakpoints: number[]
  eventCallback: (scrolledPart: number) => void
  preventSendingEvents?: boolean
  containerSelector?: string
}) => {
  useEffect(() => {
    const isBetweenRange = (
      currentPosition: number,
      min: number,
      max: number,
    ) => {
      return currentPosition >= min && currentPosition <= max
    }

    const scrolledTargets: number[] = []

    const containerEl = document.querySelector(containerSelector) as HTMLElement

    const handleScroll = () => {
      const currentEndScrollPosition =
        containerEl.scrollTop + containerEl.clientHeight
      const pageHeight = containerEl.scrollHeight - 1
      const scrolledPercentage = (currentEndScrollPosition / pageHeight) * 100

      const isInTrackedRange =
        currentEndScrollPosition >= pageHeight ||
        isBetweenRange(
          scrolledPercentage,
          trackedBreakpoints[scrolledTargets.length],
          trackedBreakpoints[scrolledTargets.length + 1],
        )
      if (
        !preventSendingEvents &&
        isInTrackedRange &&
        scrolledTargets.length !== trackedBreakpoints.length
      ) {
        scrolledTargets.push(trackedBreakpoints[scrolledTargets.length])

        eventCallback(scrolledTargets[scrolledTargets.length - 1])
      }
    }

    containerEl.addEventListener('scroll', handleScroll, true)

    return () => {
      containerEl.removeEventListener('scroll', handleScroll, true)
    }
  }, [
    trackedBreakpoints,
    eventCallback,
    preventSendingEvents,
    containerSelector,
  ])
}

import React from 'react'

import { DecisionButtons } from 'components/DecisionButtons'

import { Color, UserAnswer } from 'root-constants'

import { StyledAnalyzingAnswersModal as S } from './AnalyzingAnswersModal.styles'

type TAnalyzingAnswersModal = {
  isModalVisible: boolean
  disabled: boolean
  onChange: (answer: UserAnswer) => void
  children: React.ReactNode
}

export const AnalyzingAnswersModal: React.FC<TAnalyzingAnswersModal> = ({
  isModalVisible,
  disabled,
  onChange,
  children,
  ...rest
}) => {
  return (
    <S.Modal $isVisible={isModalVisible}>
      <S.ModalContent>
        <S.ModalTitle>{children}</S.ModalTitle>
        <DecisionButtons
          onChange={onChange}
          disabled={disabled}
          {...rest}
          backgroundColor={Color.WHITE}
          padding="0px"
        />
      </S.ModalContent>
    </S.Modal>
  )
}

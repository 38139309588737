import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/sprite/checkbox-check-white.svg'

import { OptionType } from 'root-constants'

import { StyledInspirationPerson as S } from './InspirationPerson.styles'
import { INSPIRATION_PERSON_OPTION, QUESTION } from './constants'

export const InspirationPerson: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [selectedPerson, setSelectedPerson] = useState<string | null>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId as string,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      setSelectedPerson(value)
    },
  }

  return (
    <S.Wrapper>
      <S.Title>{t('onboarding.inspirationPerson.title')}</S.Title>
      <S.InspirationCardsContainer>
        {INSPIRATION_PERSON_OPTION.map(({ value, text, imageUrl }) => (
          <S.Option {...optionProps} key={value} value={value}>
            <S.CheckIcon
              $visible={selectedPerson === value}
              svg={checkIcon}
              width={24}
            />
            <S.ImageWrapper $selected={selectedPerson === value}>
              <S.Image src={imageUrl} alt={value} />
            </S.ImageWrapper>
            <S.InspirationInfo>
              <Trans>{text}</Trans>
            </S.InspirationInfo>
          </S.Option>
        ))}
      </S.InspirationCardsContainer>
      <BottomBarPortal>
        <NavigationButton
          type="button"
          onClick={() => handleContinue(selectedPerson)}
          disabled={!selectedPerson}
        >
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </S.Wrapper>
  )
}

import { SELF_SATISFACTION_TYPE } from 'pages/questions/SelfSatisfaction/constants'

import satisfactionImage from 'assets/images/satisfaction.png'

export const QUESTION = 'Relationship satisfaction result.'

const satisfiedResult = {
  title: 'onboarding.satisfactionResult.titleSatisfied',
  subtitle: 'onboarding.satisfactionResult.subtitleSatisfied',
  imageUrl: satisfactionImage,
}

export const SATISFACTION_RESULT_MAP = {
  [SELF_SATISFACTION_TYPE.ALL_THE_TIME]: satisfiedResult,
  [SELF_SATISFACTION_TYPE.OFTEN]: satisfiedResult,
  [SELF_SATISFACTION_TYPE.RARELY]: satisfiedResult,
  [SELF_SATISFACTION_TYPE.NEVER]: {
    title: 'onboarding.satisfactionResult.titleUnsatisfied',
    subtitle: 'onboarding.satisfactionResult.subtitleUnsatisfied',
    imageUrl: satisfactionImage,
  },
}

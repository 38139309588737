export const QUESTION = 'Which Christian values do you most resonate with?'

export const CHRISTIAN_VALUES = {
  LOVE_AND_COMPARISON: 'lova_and_comparison',
  FOREGIVENESS_AND_TOLERANCE: 'forgiveness_and_tolerance',
  FAMILY_AND_UNITY: 'family_and_unity',
  HONESTY_AND_INTEGRITY: 'honesty_and_integrity',
  HUMILITY_AND_MODESTY: 'humility_and_modesty',
  HOPE_AND_POSITIVITY: 'hope_and_positivity',
  OTHER: 'other',
}

export const CHRISTIAN_VALUES_MAP = [
  {
    value: CHRISTIAN_VALUES.LOVE_AND_COMPARISON,
    text: 'onboarding.christianValues.loveAndCompassion',
  },
  {
    value: CHRISTIAN_VALUES.FOREGIVENESS_AND_TOLERANCE,
    text: 'onboarding.christianValues.forgivenessAndTolerance',
  },
  {
    value: CHRISTIAN_VALUES.FAMILY_AND_UNITY,
    text: 'onboarding.christianValues.familyAndUnity',
  },
  {
    value: CHRISTIAN_VALUES.HONESTY_AND_INTEGRITY,
    text: 'onboarding.christianValues.honestyAndIntergrity',
  },
  {
    value: CHRISTIAN_VALUES.HUMILITY_AND_MODESTY,
    text: 'onboarding.christianValues.humilityAndModesty',
  },
  {
    value: CHRISTIAN_VALUES.HOPE_AND_POSITIVITY,
    text: 'onboarding.christianValues.hopeAndPositivity',
  },
  {
    value: CHRISTIAN_VALUES.OTHER,
    text: 'onboarding.christianValues.other',
  },
]

import React, { lazy, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'

import { Download, DownloadLong } from 'pages/Download'

import { AnimationProvider } from 'components/AnimationProvider'
import { BottomBar } from 'components/BottomBar'
import { ProtectedRoute } from 'components/ProtectedRoute'
import { SubscribeFlow } from 'components/SubscribeFlow'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantSteps,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useCohortInfo } from 'hooks/useCohortInfo'
import { useUserStatus } from 'hooks/useUserStatus'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { SignUp } from 'modules/email/pages'

import { IStep } from 'models/variant.model'

import {
  CommonLayout,
  StyledAnimationProvider,
  Viewport,
  Wrapper,
} from 'common-styles'
import { ID_TO_PAGE_MAP, PageId } from 'page-constants'

const PaymentProvider = lazy(() => import('components/PaymentProvider'))

export const RouteList: React.FC = () => {
  const availableRoute = useUserStatus()
  const { search } = useLocation()
  const { isLongFlow } = useCohortInfo()

  const steps = useSelector(selectCurrentVariantSteps)
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0].id,
        cohort,
        uuid,
        language,
        currentSearch: search,
      }),
    [steps, cohort, uuid, language, search],
  )

  const { onboardingPages, subscriptionPages } = useMemo(() => {
    return steps.reduce<{
      onboardingPages: IStep[]
      subscriptionPages: IStep[]
    }>(
      (accum, { isSubscriptions, isPayment }, ind, arr) => {
        if (isSubscriptions || isPayment) {
          accum.subscriptionPages.push(arr[ind])
          return accum
        }

        accum.onboardingPages.push(arr[ind])
        return accum
      },
      {
        onboardingPages: [],
        subscriptionPages: [],
      },
    )
  }, [steps])

  return useRoutes([
    { index: true, element: <Navigate to={firstPagePath} /> },
    {
      element: <SubscribeFlow />,
      children: onboardingPages.map(({ id: pageId }, index, arr) => {
        const CurrentPage = ID_TO_PAGE_MAP[pageId]

        const nextPagePath = arr[index + 1]
          ? getPathFromPageId({
              pageId: arr[index + 1].id,
              cohort,
              uuid,
              language,
              currentSearch: search,
            })
          : ''

        return {
          path: pageId,
          element: (
            <ProtectedRoute isAvailable={!availableRoute}>
              {isLongFlow ? (
                <CommonLayout>
                  <StyledAnimationProvider>
                    <Wrapper>
                      <Viewport>
                        <CurrentPage
                          pageId={pageId}
                          nextPagePath={nextPagePath}
                        />
                      </Viewport>
                      <BottomBar />
                    </Wrapper>
                  </StyledAnimationProvider>
                </CommonLayout>
              ) : (
                <AnimationProvider>
                  <CurrentPage pageId={pageId} nextPagePath={nextPagePath} />
                </AnimationProvider>
              )}
            </ProtectedRoute>
          ),
        }
      }),
    },
    {
      element: (
        <PaymentProvider>
          <Outlet />
        </PaymentProvider>
      ),
      children: [
        {
          element: <SubscribeFlow />,
          children: subscriptionPages.map(({ id: pageId }, index, arr) => {
            const PurchasePage = ID_TO_PAGE_MAP[pageId]
            const nextPagePath = arr[index + 1]
              ? getPathFromPageId({
                  pageId: arr[index + 1].id,
                  cohort,
                  uuid,
                  language,
                  currentSearch: search,
                })
              : ''

            return {
              path: pageId,
              element: (
                <ProtectedRoute isAvailable={!availableRoute}>
                  {isLongFlow ? (
                    <CommonLayout>
                      <Wrapper>
                        <Viewport>
                          <PurchasePage
                            pageId={pageId}
                            nextPagePath={nextPagePath}
                          />
                        </Viewport>
                        <BottomBar />
                      </Wrapper>
                    </CommonLayout>
                  ) : (
                    <PurchasePage pageId={pageId} nextPagePath={nextPagePath} />
                  )}
                </ProtectedRoute>
              ),
            }
          }),
        },
      ],
    },
    {
      path: PageId.SIGNUP,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.SIGNUP}>
          <CommonLayout>
            <StyledAnimationProvider>
              <Wrapper>
                <Viewport>
                  <SignUp />
                </Viewport>
                <BottomBar />
              </Wrapper>
            </StyledAnimationProvider>
          </CommonLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.DOWNLOAD,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.DOWNLOAD}>
          {isLongFlow ? (
            <CommonLayout>
              <StyledAnimationProvider>
                <Wrapper>
                  <Viewport>
                    <DownloadLong />
                  </Viewport>
                </Wrapper>
              </StyledAnimationProvider>
            </CommonLayout>
          ) : (
            <AnimationProvider>
              <Download />
            </AnimationProvider>
          )}
        </ProtectedRoute>
      ),
    },

    { path: '*', element: <Navigate to={firstPagePath} /> },
  ])
}

import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledSignUp = {
  Wrapper: styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 375px;
    padding: 16px 24px 0;
    margin: 0 auto;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  `,
  Title: styled.h1`
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
  `,
  Subtitle: styled.h3`
    margin: 0;
    padding: 8px 0 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: ${Color.INK_DARKEST};
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    width: 327px;
  `,
}

/* eslint-disable max-lines */
import { userApi } from 'api'
import i18n from 'i18next'

import {
  setAnswersFromBackendAction,
  setErrorAction,
  setUtmTags,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { selectAnswers, selectUtmTags } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getCookie } from 'helpers/getCookie'
import { getUserStatusFromRawUserStatus } from 'helpers/getUserStatusFromRawUserStatus'

import { TAnswers, TUtmTags } from 'models/common.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import {
  IGetUUIDResponseRaw,
  IUserStatus,
  TUserConfig,
} from 'models/user.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

const MODULE_NAME = 'USER'

export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const GET_STATUS = `${MODULE_NAME}/GET_STATUS`
export const SET_STATUS = `${MODULE_NAME}/SET_STATUS`
export const SET_TEST_ENVIRONMENT_QUERY_PARAM = `${MODULE_NAME}/SET_TEST_ENVIRONMENT_QUERY_PARAM`
export const SEND_USER_ANSWERS = `${MODULE_NAME}/SEND_USER_ANSWERS`
export const SEND_USER_CONFIG = `${MODULE_NAME}/SEND_USER_CONFIG`
export const UPDATE_USER_CONFIG = `${MODULE_NAME}/UPDATE_USER_CONFIG`
export const SEND_FACEBOOK_PARAMS = `${MODULE_NAME}/SEND_FACEBOOK_PARAMS`
const SEND_USER_TTCLID = `${MODULE_NAME}/SEND_USER_TTCLID`
const SEND_USER_SCCID = `${MODULE_NAME}/SEND_USER_SCCID`
const SEND_USER_RDTCID = `${MODULE_NAME}/SEND_USER_RDTCID`
export const SEND_USER_EMAIL = `${MODULE_NAME}/SEND_USER_EMAIL`
export const SEND_USER_LEARNWORLDS_ACCOUNT = `${MODULE_NAME}/SEND_USER_LEARNWORLDS_ACCOUNT`

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function setUserStatusAction(
  userStatus: IUserStatus,
): IAction<IUserStatus> {
  return {
    type: SET_STATUS,
    payload: userStatus,
  }
}

export function setTestEnvironmentQueryParamAction(
  testEnvironmentQueryParam: string,
): IAction<string> {
  return {
    type: SET_TEST_ENVIRONMENT_QUERY_PARAM,
    payload: testEnvironmentQueryParam,
  }
}

export const setUpUUIDAction =
  ({
    cohort,
    uuid: uuidFromUrl,
    utmTags: utmTagsFromUrl,
    giaApiKey,
    language,
  }: {
    cohort: string
    uuid: string | null
    utmTags: TUtmTags | null
    giaApiKey: string
    language: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuidFromStore = selectUUID(state)
    const uuid = uuidFromUrl || uuidFromStore
    const utmTagsFromStore = selectUtmTags(state)
    const utmTags = utmTagsFromUrl || utmTagsFromStore

    if (uuid) {
      dispatch(setUUIDAction(uuid))
      return
    }

    const response = await userApi.getUUID({
      cohort,
      appId: giaApiKey,
      locale: language,
      utm: utmTags,
    })

    if (response.success) {
      const responseUUID = (response.data as IGetUUIDResponseRaw).value
      dispatch(setUUIDAction(responseUUID))
    } else {
      console.error('error')
    }
  }

export const sendFacebookParamsAction =
  (): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const fbp = getCookie('_fbp')
    const fbc = getCookie('_fbc')

    dispatch(startFetching(SEND_FACEBOOK_PARAMS))

    const response = await userApi.saveFacebookParams({
      uuid,
      fbp,
      fbc,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    }

    dispatch(stopFetching(SEND_FACEBOOK_PARAMS))
  }

export function getUserStatusAction(uuid: string): TAppActionThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(GET_STATUS))

    const response = await userApi.getUserStatus(uuid)
    if (response.success && response.data) {
      const { state } = response.data

      const userStatus = getUserStatusFromRawUserStatus(state)
      dispatch(setUserStatusAction(userStatus))
      dispatch(setAnswersFromBackendAction(userStatus.onboarding))
      dispatch(setUtmTags(userStatus.config?.utmTags))
    }

    if (!response.success && response.status === 404) {
      const { search } = window.location
      const urlParams = new URLSearchParams(search)
      urlParams.delete('uuid')

      // use window.location.search to reload the page with updated query params
      window.location.search = `?${urlParams}`
    }

    dispatch(stopFetching(GET_STATUS))
  }
}

export const sendUserAnswersAction =
  (unsuccessCallback?: (() => void) | null, isFinished = false): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const answers = selectAnswers(state) as TAnswers
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_ANSWERS))

    const response = await userApi.saveUserAnswers({
      uuid,
      answers,
      isFinished,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

      unsuccessCallback && unsuccessCallback()
    }

    dispatch(stopFetching(SEND_USER_ANSWERS))
  }

export const sendUserConfigAction =
  (config: TUserConfig, unsuccessCallback?: (() => void) | null): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_CONFIG))

    const response = await userApi.saveUserConfig({
      uuid,
      config,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

      unsuccessCallback && unsuccessCallback()
    }

    dispatch(stopFetching(SEND_USER_CONFIG))
  }

export function updateUserConfigAction(
  value: TUserConfig,
): IAction<TUserConfig> {
  return {
    type: UPDATE_USER_CONFIG,
    payload: value,
  }
}

export const sendUserRDTCID =
  (rdtcid: string): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_RDTCID))

    try {
      const response = await userApi.saveUserRDTCID({ rdtcid, uuid })

      if (!response.success) {
        dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      }

      dispatch(stopFetching(SEND_USER_RDTCID))
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setErrorAction(error.toString()))
        dispatch(stopFetching(SEND_USER_RDTCID))

        return
      }

      dispatch(setErrorAction(String(error)))
      dispatch(stopFetching(SEND_USER_RDTCID))
    }
  }

export const sendUserTTCLID =
  (ttclid: string): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_TTCLID))

    try {
      const response = await userApi.saveUserTTCLID({ ttclid, uuid })

      if (!response.success) {
        dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      }

      dispatch(stopFetching(SEND_USER_TTCLID))
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setErrorAction(error.toString()))
        dispatch(stopFetching(SEND_USER_TTCLID))

        return
      }

      dispatch(setErrorAction(String(error)))
      dispatch(stopFetching(SEND_USER_TTCLID))
    }
  }

export const sendUserSCCID =
  (sccid: string, scid: string, unsuccessCallback?: () => void): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_SCCID))

    const response = await userApi.saveUserSCCID({ sccid, uuid, scid })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      unsuccessCallback && unsuccessCallback()
      dispatch(stopFetching(SEND_USER_SCCID))
      return
    }

    dispatch(stopFetching(SEND_USER_SCCID))
  }

export const sendUserEmailAction =
  ({ email }: { email: string }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_EMAIL))

    const response = await userApi.saveUserEmail({
      uuid,
      email,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
      dispatch(stopFetching(SEND_USER_EMAIL))

      eventLogger.logEmailPageError(JSON.stringify(response.data))
      return
    }

    eventLogger.logEmailPageCompleted(email)
    googleAnalyticsLogger.logEmailPageCompleted()

    dispatch(stopFetching(SEND_USER_EMAIL))
  }

export const sendUserEmailAndPasswordAction =
  ({ email, password }: { email: string; password: string }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_LEARNWORLDS_ACCOUNT))

    const response = await userApi.saveUserEmailAndPassword({
      uuid,
      email,
      password,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('commonComponents.commonError')))
      dispatch(stopFetching(SEND_USER_LEARNWORLDS_ACCOUNT))

      return
    }

    await dispatch(
      sendUserConfigAction({
        is_user_signed_up: true,
      }),
    )
    await dispatch(getUserStatusAction(uuid))

    dispatch(stopFetching(SEND_USER_LEARNWORLDS_ACCOUNT))
  }

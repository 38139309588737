import React from 'react'
import { useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { DecisionButtons } from 'components/DecisionButtons'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import diveDeeperImage from 'assets/images/psalms-understanding-image.png'

import { Color, Language, OptionType } from 'root-constants'

import { StyledPsalmsUnderstanding as S } from './PsalmsUnderstanding.styles'

export const PsalmsUnderstanding: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.psalmsUnderstanding.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <S.Image src={diveDeeperImage} />
      </S.ImageContainer>
      <S.Title>{t('onboarding.psalmsUnderstanding.title')}</S.Title>
      <BottomBarPortal>
        <DecisionButtons
          {...optionProps}
          disabled={isAnswersDisabled}
          activeBackgroundColor={Color.WHITE}
        />
      </BottomBarPortal>
    </S.Wrapper>
  )
}

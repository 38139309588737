export const BIBLE_VERSION_TYPE = {
  NIV: 'niv',
  KJV: 'kjv',
  ESV: 'esv',
  NLT: 'nlt',
  NKJV: 'nkjv',
  NRSV: 'nrsv',
  NOT_SURE: 'notSure',
  OTHER: 'other',
}

export const BIBLE_VERSION_OPTIONS = [
  {
    value: BIBLE_VERSION_TYPE.NIV,
    text: 'onboarding.bibleVersion.niv',
  },
  {
    value: BIBLE_VERSION_TYPE.KJV,
    text: 'onboarding.bibleVersion.kjv',
  },
  {
    value: BIBLE_VERSION_TYPE.ESV,
    text: 'onboarding.bibleVersion.esv',
  },
  {
    value: BIBLE_VERSION_TYPE.NLT,
    text: 'onboarding.bibleVersion.nlt',
  },
  {
    value: BIBLE_VERSION_TYPE.NKJV,
    text: 'onboarding.bibleVersion.nkjv',
  },
  {
    value: BIBLE_VERSION_TYPE.NRSV,
    text: 'onboarding.bibleVersion.nrsv',
  },
  {
    value: BIBLE_VERSION_TYPE.NOT_SURE,
    text: 'onboarding.bibleVersion.notSure',
  },
  {
    value: BIBLE_VERSION_TYPE.OTHER,
    text: 'onboarding.bibleVersion.other',
  },
]

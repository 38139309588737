import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserSCCID } from 'root-redux/actions/user'
import { selectSnapPixelId } from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { getCookie } from 'helpers/getCookie'

export const useSnapPixelInitialization = (): void => {
  const dispatch = useDispatch()
  const snapPixelId = useSelector(selectSnapPixelId)
  const email = useSelector(selectUserOnboardingEmail)
  const uuid = useSelector(selectUUID)

  useLayoutEffect(() => {
    if (!snapPixelId || !uuid) {
      return
    }

    window.snaptr('init', snapPixelId, {
      user_email: email,
    })
    window.snaptr('track', 'PAGE_VIEW', {
      user_email: email,
    })

    const searchParams = new URLSearchParams(document.location.search)
    const sccid = searchParams.get('sccid')
    const scid = getCookie('_scid')

    if (sccid) {
      dispatch(sendUserSCCID(sccid, scid))
    }
  }, [snapPixelId, email, uuid, dispatch])
}

export const RELATIONSHIP_WITH_GOD_TYPE = {
  STRONG_AND_GROWING: 'strong_and_growing',
  NEED_OF_RENEWAL: 'need_of_renewal',
  UNSURE: 'unsure',
  OTHER: 'other',
}
export const RELATIONSHIP_WITH_GOD_OPTION = [
  {
    value: RELATIONSHIP_WITH_GOD_TYPE.STRONG_AND_GROWING,
    text: 'onboarding.relationshipWithGod.strongAndGrowing',
  },
  {
    value: RELATIONSHIP_WITH_GOD_TYPE.NEED_OF_RENEWAL,
    text: 'onboarding.relationshipWithGod.needOfRenewal',
  },
  {
    value: RELATIONSHIP_WITH_GOD_TYPE.UNSURE,
    text: 'onboarding.relationshipWithGod.unsure',
  },
  {
    value: RELATIONSHIP_WITH_GOD_TYPE.OTHER,
    text: 'onboarding.relationshipWithGod.other',
  },
]

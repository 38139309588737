import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { answerWithCheckboxTheme } from 'storybook-ui'

import { AnswerWithCheckboxIcon } from 'components/AnswerWithCheckboxIcon'
import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color, Language, OptionType } from 'root-constants'

import { StyledPartsToExplore as S } from './PartsToExplore.styles'
import { PARTS_TO_EXPLORE_MAP } from './constants'

export const PartsToExplore: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = {
    name: pageId as string,
    type: OptionType.CHECKBOX,
    onChange: (answer: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, answer])
      } else {
        setAnswers(answers.filter((item) => item !== answer))
      }
    },
  }

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.partsToExplore.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleSelect = () => {
    handleContinue(answers)
  }

  return (
    <S.Wrapper>
      <S.Title>{t('onboarding.partsToExplore.title')}</S.Title>
      {PARTS_TO_EXPLORE_MAP.map(({ value, text }) => (
        <AnswerWithCheckboxIcon
          {...optionProps}
          minWidth="327px"
          height="auto"
          margin="0 0 8px 0"
          lineHeight="18px"
          padding="20px 24px"
          iconSize="24px"
          backgroundColor={Color.SKY_BASE}
          checkboxBackgroundColor={Color.SKY_BASE}
          theme={answerWithCheckboxTheme.BLESSED_PATH}
          key={value}
          value={value}
        >
          <Trans>{text}</Trans>
        </AnswerWithCheckboxIcon>
      ))}
      <BottomBarPortal>
        <NavigationButton
          disabled={!answers.length}
          type="button"
          onClick={handleSelect}
        >
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </S.Wrapper>
  )
}

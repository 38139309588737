export const STUDY_THE_BIBLE_VALUES = {
  DEEPEN_SPIRITUAL_GROWTH: 'deepen_spiritual_growth',
  GAIN_BETTER_UNDERSTANDING: 'gain_better_understanding',
  FIND_PEACE: 'find_peace',
  GROW_SPIRITUALLY_AND_EMOTIONALLY: 'grow_spiritually_and_emotionally',
  BUILD_CLOSER_RELATIONSHIP: 'build_closer_relationship',
}

export const STUDY_THE_BIBLE_MAP = [
  {
    value: STUDY_THE_BIBLE_VALUES.DEEPEN_SPIRITUAL_GROWTH,
    text: 'onboarding.studyTheBible.deepenSpiritualGrowth',
  },
  {
    value: STUDY_THE_BIBLE_VALUES.GAIN_BETTER_UNDERSTANDING,
    text: 'onboarding.studyTheBible.gainBetterUnderstanding',
  },
  {
    value: STUDY_THE_BIBLE_VALUES.FIND_PEACE,
    text: 'onboarding.studyTheBible.findPeace',
  },
  {
    value: STUDY_THE_BIBLE_VALUES.GROW_SPIRITUALLY_AND_EMOTIONALLY,
    text: 'onboarding.studyTheBible.growSpitiruallyAndEmotionally',
  },
  {
    value: STUDY_THE_BIBLE_VALUES.BUILD_CLOSER_RELATIONSHIP,
    text: 'onboarding.studyTheBible.buildCloserRelationship',
  },
]

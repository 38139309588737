import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { OptionCard } from 'components/OptionCard'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import starSvg from 'assets/images/sprite/star-gold.svg'

import { Language, OptionType } from 'root-constants'

import {
  StyledSelectGender as S,
  SEPARATOR_VARIANT,
} from './SelectGender.styles'
import { GENDER_OPTIONS } from './constants'

export const SelectGender: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const [answer, setAnswer] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.selectGender.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId as string,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      eventLogger.logFirstPageCompleted({
        question: t('onboarding.selectGender.title', { lng: Language.EN }),
        answers: value,
      })
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
      setAnswer(value)
    },
  }

  return (
    <S.Container>
      <S.RatingContainer>
        <SvgImage svg={starSvg} width={14} height={14} />
        <S.RatingText>4.9 out of 5</S.RatingText>
        <S.Separator variant={SEPARATOR_VARIANT.RATING} />
        <S.RatingText>23,578 reviews</S.RatingText>
      </S.RatingContainer>
      <S.Title>{t(`onboarding.selectGender.title`)}</S.Title>
      <S.Subtitle>
        <Trans
          i18nKey="onboarding.selectGender.subtitle"
          components={[<strong />]}
        />
      </S.Subtitle>
      <S.Topic>{t('onboarding.selectGender.selectTitle')}</S.Topic>
      <S.CardsContainer>
        {GENDER_OPTIONS.map(({ value, label, imageUrl }) => (
          <OptionCard
            key={value}
            imageUrl={imageUrl}
            value={value}
            label={label}
            checked={answer === value}
            disabled={isAnswersDisabled}
            {...optionProps}
          />
        ))}
      </S.CardsContainer>

      <S.Terms>
        <p>{t`onboarding.selectGender.termsTitle`}</p>
        <TermsOfUseLink translationKey="onboarding.selectGender.termsOfUse" />
        <S.Separator />
        <PrivacyPolicyLink />
        <S.Separator variant={SEPARATOR_VARIANT.DEFAULT} />
        <TermsOfUseLink translationKey="onboarding.selectGender.subscriptionPolicy" />
      </S.Terms>
      <S.TermsNote>{t`onboarding.selectGender.termsNote`}</S.TermsNote>
    </S.Container>
  )
}

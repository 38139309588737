import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import writingYourStoryImage from 'assets/images/writing-your-story-image.png'

import { Language } from 'root-constants'

import { StyledWritingYourStory as S } from './WritingYourStory.styles'

export const WritingYourStory: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.writingYourStory.title', { lng: Language.EN }),
    nextPagePath,
  })

  return (
    <>
      <S.Wrapper>
        <S.ImageContainer>
          <S.Image src={writingYourStoryImage} />
        </S.ImageContainer>
        <Container>
          <S.Title>
            <Trans i18nKey="onboarding.writingYourStory.title" />
          </S.Title>
          <S.Subtitle>{t('onboarding.writingYourStory.subtitle')}</S.Subtitle>
        </Container>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          {t('actions.continue')}
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

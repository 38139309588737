import { createProductId } from 'helpers/createProductId'

import { ISubscription } from 'models/subscriptions.model'

import { ScreenName, TimeInterval } from 'root-constants'

export const enum GoogleAnalyticsAction {
  EVENT = 'event',
}

export const enum GoogleAnalyticsEvent {
  PAGE_VIEW = 'page_view',
  START_ONBOARDING = 'start_onboarding',
  MID_FUNNEL = 'mid_funnel',
  EMAIL_SUBMIT = 'email_submit',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_TO_CART = 'add_to_cart',
  PAYWALL_VIEW = 'paywall_view',
  PURCHASE = 'purchase',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
  ACCOUNT_CREATED = 'account_created',
  DOWNLOAD_BUTTON_PRESSED = 'download_button_press',
  LIVE_CHAT_OPENED = 'live_chat_opened',
  LIVE_CHAT_CLOSED = 'live_chat_closed',
  CONVERSION = 'conversion',
}

export const enum GoogleAnalyticsEventCategory {
  TRANSITION = 'transition',
}

class GoogleAnalyticsService {
  private googleAdsConversions: Record<string, string> = {}

  configure(googleAdsConversions: Record<string, string>): void {
    this.googleAdsConversions = googleAdsConversions
  }

  logPageView(location: string): void {
    !!window.gtag &&
      window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.PAGE_VIEW, {
        page_path: `/${location}`,
      })
  }

  logFirstPageView(): void {
    if (!window.gtag) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.PAGE_VIEW],
    })
  }

  logPaywallView(): void {
    if (!window.gtag) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.PAYWALL_VIEW],
    })
  }

  logCheckoutStarted(subscriptions: ISubscription[]): void {
    if (!window.gtag) return

    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.BEGIN_CHECKOUT,
      {
        items: subscriptions.map(({ mainPrices }) => {
          const { fullPrice, periodName, periodQuantity } = mainPrices

          return {
            id: createProductId({
              periodName,
              periodQuantity,
              price: fullPrice,
            }),
            price: fullPrice,
          }
        }),
      },
    )

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.BEGIN_CHECKOUT],
    })
  }

  logAddingToCart({ mainPrices }: ISubscription): void {
    const { fullPrice, periodName, periodQuantity } = mainPrices

    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.ADD_TO_CART,
        {
          items: [
            {
              id: createProductId({
                periodName,
                periodQuantity,
                price: fullPrice,
              }),
              price: fullPrice,
            },
          ],
        },
      )
  }

  logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
  }: {
    subscriptionId: string
    price: number
    periodName: TimeInterval | null
    periodQuantity: number | null
    currency: string
    screenName: ScreenName
  }): void {
    if (!window.gtag) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.PURCHASE, {
      event_label: screenName,
      transaction_id: subscriptionId,
      value: price,
      currency,
      items: [
        {
          id: createProductId({ periodName, periodQuantity, price }),
          price,
        },
      ],
    })

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.PURCHASE],
      transaction_id: subscriptionId,
      value: price,
      currency,
    })
  }

  logFirstPageCompleted(): void {
    if (!window.gtag) return

    window.gtag(
      GoogleAnalyticsAction.EVENT,
      GoogleAnalyticsEvent.FIRST_PAGE_COMPLETED,
      {
        event_category: GoogleAnalyticsEventCategory.TRANSITION,
      },
    )

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.START_ONBOARDING],
    })
  }

  logMidFunnelCompleted(): void {
    if (!window.gtag) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.MID_FUNNEL],
    })
  }

  logAccountCreated(): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.ACCOUNT_CREATED,
      )
  }

  logEmailPageCompleted(): void {
    if (!window.gtag) return

    window.gtag(GoogleAnalyticsAction.EVENT, GoogleAnalyticsEvent.CONVERSION, {
      send_to: this.googleAdsConversions[GoogleAnalyticsEvent.EMAIL_SUBMIT],
    })
  }

  logDownloadButtonPressed(): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.DOWNLOAD_BUTTON_PRESSED,
      )
  }

  logLiveChatOpened(): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.LIVE_CHAT_OPENED,
      )
  }

  logLiveChatClosed(): void {
    !!window.gtag &&
      window.gtag(
        GoogleAnalyticsAction.EVENT,
        GoogleAnalyticsEvent.LIVE_CHAT_CLOSED,
      )
  }
}

export const googleAnalyticsLogger = new GoogleAnalyticsService()

import React from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import paymentCardsIcon from 'assets/images/sprite/payment-cards-icon.svg'
import shieldTickIcon from 'assets/images/sprite/shield-tick-green.svg'

import { StyledSecuredPaymentBadge as S } from './SecuredPaymentBadge.styles'

export const SecuredPaymentBadge = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.SecuredBadge>
        <SvgImage svg={shieldTickIcon} width={16} />
        <S.SecureBadgeText>
          {t('purchaseLong.securePaymentBadge')}
        </S.SecureBadgeText>
      </S.SecuredBadge>

      <SvgImage svg={paymentCardsIcon} width={286} />
    </S.Container>
  )
}

import styled from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

type TModalProps = {
  $isVisible?: boolean
}

export const StyledAnalyzingAnswersModal = {
  Modal: styled.div<TModalProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: ${({ $isVisible }) => ($isVisible ? 2 : -1)};
    visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    padding: 20px;
    transition: 0.25s opacity;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      align-items: flex-end;
      padding: 0;
    }
  `,
  ModalContent: styled.div`
    width: 100%;
    max-width: 375px;
    background: ${Color.WHITE};
    border-radius: 20px;
    padding: 40px 24px;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 100%;
      border-radius: 20px 20px 0 0;
    }
  `,
  ModalTitle: styled.h3`
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;
    color: ${Color.INK_DARKEST};
  `,
}

import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

const baseText = css`
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: ${Color.INK_LIGHT};
`

export const StyledPersonalGrowthProgressV2 = {
  Wrapper: styled(Container)`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px 24px 0;
  `,
  ProgressWrapper: styled.div`
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    background-color: ${Color.WHITE};
    padding: 32px 24px;
    text-align: center;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    margin: 24px 0 28px;

    & strong {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: ${Color.BLUE_DARKER};
    }
  `,
  GraphWrapper: styled.div`
    position: relative;
    width: 279px;
    height: 239px;
    padding: 0 16px 32px;
  `,
  Label: styled.p`
    ${baseText};
    font-size: 12px;
    white-space: nowrap;
  `,
  Labels: styled.div`
    position: absolute;
    bottom: -12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 27px;
  `,
  Subtitle: styled.h3`
    ${baseText};
    font-size: 14px;
    color: ${Color.INK_DARKEST};
    padding-bottom: 20px;
  `,
  ChartFooter: styled.p`
    ${baseText};
    font-size: 12px;
    margin-top: 32px;
  `,
  DisclaimerGradient: styled.div`
    display: inline-flex;
    justify-content: center;
    margin: 0 auto;
    height: 44px;
    padding: 2px;
    border-radius: 8px;
    background: linear-gradient(
      270deg,
      ${Color.BLUE_DARKER},
      ${Color.BLUE_LIGHTER}
    );
  `,
  Disclaimer: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 6px;
    padding: 12px 16px;
    text-align: center;

    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.INK_DARKEST};
  `,
}

import { SubscriptionProduct } from 'root-constants'

export const WEEKLY_PLANS_DURATION_MAP: Partial<
  Record<SubscriptionProduct, string>
> = {
  [SubscriptionProduct.SEVEN_DAY]: '7',
  [SubscriptionProduct.ONE_MONTH]: '1',
  [SubscriptionProduct.THREE_MONTHS]: '3',
  [SubscriptionProduct.SIX_MONTHS]: '6',
}

import React, { lazy, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import { AnimationProvider } from 'components/AnimationProvider'
import { ProtectedRoute } from 'components/ProtectedRoute'
import { SubscribeFlow } from 'components/SubscribeFlow'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserStatus } from 'hooks/useUserStatus'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { ID_TO_PAGE_MAP, PURCHASE_PAGES, PageId } from 'page-constants'
import { Cohort } from 'root-constants'

import { Download } from './pages/download'

const PaymentProvider = lazy(() => import('components/PaymentProvider'))

export const RouteList: React.FC = () => {
  const availableRoute = useUserStatus()
  const { search } = useLocation()

  const { currentVariantPageList } = useGetPageInfo()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort || Cohort.FLOW_1_BB_COPY
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)

  const pageMapId = useMemo(() => {
    switch (cohortToUse) {
      default:
        return ID_TO_PAGE_MAP
    }
  }, [cohortToUse])

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: currentVariantPageList[0].id,
        cohort,
        uuid,
        language,
        currentSearch: search,
      }),
    [currentVariantPageList, cohort, uuid, language, search],
  )

  const routes = useRoutes([
    { index: true, element: <Navigate to={firstPagePath} /> },
    {
      element: <SubscribeFlow />,
      children: currentVariantPageList.map(({ id: pageId }, index, arr) => {
        const CurrentPage = pageMapId[pageId]

        const nextPagePath = getPathFromPageId({
          pageId: arr[index + 1]?.id,
          cohort,
          uuid,
          language,
          currentSearch: search,
        })

        return {
          path: pageId,
          element: (
            <ProtectedRoute isAvailable={!availableRoute}>
              <AnimationProvider>
                <CurrentPage pageId={pageId} nextPagePath={nextPagePath} />
              </AnimationProvider>
            </ProtectedRoute>
          ),
        }
      }),
    },

    ...PURCHASE_PAGES.map(({ id }) => {
      const PurchasePage = pageMapId[id]

      return {
        path: id,
        element: (
          <ProtectedRoute isAvailable={!availableRoute}>
            <PaymentProvider>
              <PurchasePage nextPagePath="/" pageId={id} />
            </PaymentProvider>
          </ProtectedRoute>
        ),
      }
    }),

    {
      path: PageId.DOWNLOAD,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.DOWNLOAD}>
          <AnimationProvider>
            <Download />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },

    { path: '*', element: <Navigate to={firstPagePath} /> },
  ])

  return routes
}

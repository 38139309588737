export const INSPIRED_SCRIPTURE_TYPE = {
  STRENGTHEN_RELATIONSHIP_WITH_GOD: 'strengthen_relationship_with_god',
  LEARN_MORE_ABOUT_FAITH: 'learn_nore_about_faith',
  FIND_PEACE_OR_HEALING: 'find_peace_or_healing',
  GROW_CLOSER_TO_GOD: 'grow_closer_to_god',
  OTHER: 'other',
}
export const INSPIRED_SCRIPTURE_OPTION = [
  {
    value: INSPIRED_SCRIPTURE_TYPE.STRENGTHEN_RELATIONSHIP_WITH_GOD,
    text: 'onboarding.inspiredScripture.strengthenRelationshipWithGod',
  },
  {
    value: INSPIRED_SCRIPTURE_TYPE.LEARN_MORE_ABOUT_FAITH,
    text: 'onboarding.inspiredScripture.learnMoreAboutFaith',
  },
  {
    value: INSPIRED_SCRIPTURE_TYPE.FIND_PEACE_OR_HEALING,
    text: 'onboarding.inspiredScripture.findPeaceOrHealing',
  },
  {
    value: INSPIRED_SCRIPTURE_TYPE.GROW_CLOSER_TO_GOD,
    text: 'onboarding.inspiredScripture.growCloserToGod',
  },
  {
    value: INSPIRED_SCRIPTURE_TYPE.OTHER,
    text: 'onboarding.inspiredScripture.other',
  },
]

import { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectAmplitudeApiKey,
  selectStripeAccountId,
  selectStripeAccountName,
  selectUtmTags,
} from 'root-redux/selects/common'
import { selectUUID, selectUserStatus } from 'root-redux/selects/user'

import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { getQueryParamsFromSearchString } from 'helpers/getQueryParamsFromSearchString'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'

import {
  CurrentEnvironment,
  EventLoggerInstanceName,
  ScreenName,
  TEST_ENV_QUERY_PARAM,
} from 'root-constants'

export function useAmplitudeInitialization(
  cohort = '',
  screenName?: ScreenName,
): Amplitude | null {
  const { search } = useLocation()
  const uuid = useSelector(selectUUID)
  const amplitudeApiKey = useSelector(selectAmplitudeApiKey)
  const userStatus = useSelector(selectUserStatus)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const utmTags = useSelector(selectUtmTags)

  const [amplitudeInstance, setAmplitudeInstance] = useState<Amplitude | null>(
    null,
  )

  const URLParams = new URLSearchParams(search)
  const isTestEnvironment = URLParams.has(TEST_ENV_QUERY_PARAM)
  const isDevEnvironment = getCurrentEnv() !== CurrentEnvironment.PROD

  useLayoutEffect(() => {
    // if (eventLogger.isAmplitudeActive) return
    if (uuid && amplitudeApiKey && screenName === ScreenName.PURCHASE) {
      const amplitudeService = new Amplitude({
        apiKey: amplitudeApiKey,
        instanceName: EventLoggerInstanceName.AMPLITUDE,
        userId: uuid,
        utmTags: Object.entries(utmTags).length
          ? utmTags
          : getQueryParamsFromSearchString(search),
        isTestEnvironment,
        isDevEnvironment,
      })
      amplitudeService.configure({ cohort })

      if (stripeAccountId && stripeAccountName) {
        amplitudeService?.updateUserProperties(
          stripeAccountId,
          stripeAccountName,
        )
      }

      eventLogger.init(amplitudeService)
      setAmplitudeInstance(amplitudeService)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uuid,
    amplitudeApiKey,
    stripeAccountId,
    stripeAccountName,
    userStatus,
    screenName,
  ])

  return amplitudeInstance
}

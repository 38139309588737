import styled from 'styled-components'

import { Color } from 'root-constants'

export const SEPARATOR_VARIANT = {
  RATING: 'RATING',
  DEFAULT: 'DEFAULT',
} as const

export type TSeparatorVariant =
  typeof SEPARATOR_VARIANT[keyof typeof SEPARATOR_VARIANT]

export const StyledSelectGender = {
  Container: styled.div`
    max-width: 375px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    margin-bottom: 8px;
  `,
  Subtitle: styled.h3`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
    text-align: center;
    padding: 0 24px;
  `,
  Topic: styled.h2`
    font-size: 20px;
    font-style: normal;
    color: ${Color.INK_DARKEST};
    font-weight: 700;
    line-height: 24px;
    margin-top: 32px;
    margin-bottom: 24px;
  `,
  CardsContainer: styled.div`
    width: 100%;
    height: 295px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding-bottom: 39px;
  `,
  RatingContainer: styled.div`
    display: flex;
    align-items: center;
    background-color: ${Color.WHITE};
    padding: 8px 12px;
    border-radius: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    border: 1px solid #979ca0;
    margin-top: 8px;
    margin-bottom: 32px;

    & > svg {
      margin-right: 4px;
    }
  `,
  RatingText: styled.span`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
  `,
  Terms: styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    color: ${Color.INK_LIGHT};

    a {
      color: ${Color.INK_DARKEST};
      text-decoration: underline;
      padding-top: 8px;
      display: inline-block;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  `,
  TermsNote: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: ${Color.INK_LIGHT};
    text-align: center;
    padding-top: 24px;
    padding-bottom: 16px;
  `,
  Separator: styled.span<{ variant?: TSeparatorVariant }>`
    display: inline-flex;
    height: 16px;
    width: 1px;
    background-color: ${({ variant }) =>
      variant === SEPARATOR_VARIANT.RATING ? 'rgba(0, 0, 0, 0.20)' : '#979CA0'};
    margin: 0 8px;
    vertical-align: middle;
  `,
}

export const StyledRatingSvg = styled.img`
  display: block;
  min-width: 14px;
  min-height: 14px;
  margin-right: 4px;
`

import { PlatformOS } from 'root-constants'

export const APP_OUTER_LINK = 'https://blessedpaths.onelink.me/dkdK/yo3sq7k5'
export const APP_OUTER_LEARN_WORLDS_COURSE_LINK =
  'https://blessedpath-course.learnworlds.com/?msg=not-logged-in'

const DEFAULT_DOWNLOAD_DATA = {
  title: 'downloadApp.title',
  subtitle: 'downloadApp.subtitle',
  ctaText: 'downloadApp.ctaButtonText',
  link: APP_OUTER_LINK,
}

export const DOWNLOAD_MAP = {
  [PlatformOS.ANDROID]: {
    title: 'downloadApp.titleAndroid',
    subtitle: 'downloadApp.subtitleAndroid',
    ctaText: 'downloadApp.ctaButtonTextAndroid',
    link: APP_OUTER_LEARN_WORLDS_COURSE_LINK,
  },
  [PlatformOS.IOS]: DEFAULT_DOWNLOAD_DATA,
  [PlatformOS.UNKNOWN]: DEFAULT_DOWNLOAD_DATA,
}

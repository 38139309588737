import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledBeforeAfterCards = {
  Cards: styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
  `,
  CardContainer: styled.div<{ isActive: boolean }>`
    background: ${Color.WHITE};
    width: 158px;
    height: 196px;
    border-radius: 16px;
    overflow: hidden;
    box-sizing: border-box;
    border: ${({ isActive }) =>
      isActive
        ? `2px solid ${Color.GOLD_DARKEST}`
        : '1px solid rgba(0, 0, 0, 0.18)'};
    display: flex;
    flex-direction: column;
  `,
  ImageWrapper: styled.div`
    background: radial-gradient(
      91.23% 70.71% at 50% 50%,
      #dfe9f5 0%,
      #9ea7b0 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  `,
  CardImage: styled.img`
    object-fit: cover;
  `,
  ContentWrapper: styled.div`
    background: ${Color.WHITE};
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    padding: 8px 16px 16px;
    text-align: start;
  `,
}

export const QUESTION = 'Do you consider yourself a thinker or a feeler type?'

export const PERSONALITY_TYPE = {
  THINKER: 'thinker',
  FEELER: 'feeler',
  BOTH: 'both',
}

export const PERSONALITY_TYPES_OPTION = [
  {
    value: PERSONALITY_TYPE.THINKER,
    text: 'onboarding.personalityType.thinker',
  },
  { value: PERSONALITY_TYPE.FEELER, text: 'onboarding.personalityType.feeler' },
  { value: PERSONALITY_TYPE.BOTH, text: 'onboarding.personalityType.both' },
]

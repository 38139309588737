import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import {
  getMonthThreeFromNowFullName,
  getThreeMonthsFromNowAbbr,
} from 'helpers/dates'

import { TPageProps } from 'models/common.model'

import personalGrowthProgressAnimation from 'assets/animation/personalGrowthProgressAnimation.json'

import { Language } from 'root-constants'

import { StyledPersonalGrowthProgress as S } from './PersonalGrowthProgress.styles'

export const PersonalGrowthProgress: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const graphContentRef = useRef<HTMLDivElement | null>(null)

  const targetMonth = getMonthThreeFromNowFullName(Language.EN)
  const monthsArray = getThreeMonthsFromNowAbbr(Language.EN)

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.personalGrowthProgress.title', {
      lng: Language.EN,
    }),
    nextPagePath,
  })

  useEffect(() => {
    if (graphContentRef.current) {
      lottie.loadAnimation({
        container: graphContentRef.current,
        animationData: personalGrowthProgressAnimation,
        loop: false,
        name: 'personalGrowthProgressAnimation',
      })
    }

    return () => lottie.destroy('personalGrowthProgressAnimation')
  }, [])

  return (
    <>
      <S.Wrapper>
        <S.ProgressWrapper>
          <S.Title>
            <Trans
              i18nKey="onboarding.personalGrowthProgress.title"
              values={{
                month: targetMonth,
              }}
            />
          </S.Title>
          <S.GraphWrapper>
            <div ref={graphContentRef} />
            <S.MonthLabels>
              {monthsArray.map((month) => (
                <S.MonthLabel key={month}>{month}</S.MonthLabel>
              ))}
            </S.MonthLabels>
          </S.GraphWrapper>
        </S.ProgressWrapper>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

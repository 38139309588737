import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import lottie from 'lottie-web/build/player/lottie_light'

import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { getLearningFinalDate } from 'helpers/dates'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import personalGrowthProgressAnimationV2 from 'assets/animation/personalGrowthProgressAnimationV2.json'

import { goTo } from 'browser-history'
import { Language } from 'root-constants'

import { StyledPersonalGrowthProgressV2 as S } from './PersonalGrowthProgressV2.styles'

export const PersonalGrowthProgressV2: React.FC<TPageProps> = () => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const { currentSubscriptionPageId } = useGetPageInfo()

  const graphContentRef = useRef<HTMLDivElement | null>(null)

  const targetDate = getLearningFinalDate()

  const handleContinueClick = () => {
    eventLogger.logQuestion({
      question: t('onboarding.personalGrowthProgressV2.title', {
        lng: Language.EN,
      }),
    })

    goTo({ pathname: currentSubscriptionPageId, search })
  }

  useEffect(() => {
    if (graphContentRef.current) {
      lottie.loadAnimation({
        container: graphContentRef.current,
        animationData: personalGrowthProgressAnimationV2,
        loop: false,
        name: 'personalGrowthProgressAnimationV2',
      })
    }

    return () => lottie.destroy('personalGrowthProgressAnimationV2')
  }, [])

  return (
    <>
      <S.Wrapper>
        <S.ProgressWrapper>
          <S.DisclaimerGradient>
            <S.Disclaimer>
              {t('onboarding.personalGrowthProgressV2.disclaimer')}
            </S.Disclaimer>
          </S.DisclaimerGradient>

          <S.Title>
            <Trans
              i18nKey="onboarding.personalGrowthProgressV2.title"
              values={{
                date: targetDate,
              }}
            />
          </S.Title>

          <S.GraphWrapper>
            <div ref={graphContentRef} />
            <S.Labels>
              {(
                t('onboarding.personalGrowthProgressV2.weekLabels', {
                  returnObjects: true,
                }) as string[]
              ).map((label) => (
                <S.Label key={label}>{label}</S.Label>
              ))}
            </S.Labels>
          </S.GraphWrapper>
          <S.ChartFooter>
            {t('onboarding.personalGrowthProgressV2.chartFooter')}
          </S.ChartFooter>
        </S.ProgressWrapper>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={handleContinueClick}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

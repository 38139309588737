import styled from 'styled-components'

import { Color } from 'root-constants'

type TButtons = {
  $disabled?: boolean
  $backgroundColor?: string
  $activeBackgroundColor?: string
}

export const StyledDecisionButtons = {
  Container: styled.div<{ $padding?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: ${({ $padding }) => $padding || '24px'};
  `,
  Button: styled.div<TButtons>`
    width: 157px;
    height: 100px;
    background-color: ${({ $backgroundColor }) =>
      $backgroundColor ?? Color.SKY_BASE};
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.INK_DARKEST};
    cursor: pointer;
    pointer-events: ${({ $disabled }) => $disabled && 'none'};

    input:checked + & {
      background-color: ${({ $activeBackgroundColor }) =>
        $activeBackgroundColor ?? 'rgba(0, 0, 0, 0.03)'};
      transition: 0.25s;
      pointer-events: none;
    }
  `,
}

import React from 'react'

import { PRIMER_PAY_PAL_ID } from 'modules/purchase/constants'

import { StyledPrimerPayPalButton as S } from './PrimerPayPalButton.styles'

export interface IPrimerPayPalButtonProps {
  isVisible: boolean
}

export const PrimerPayPalButton: React.FC<IPrimerPayPalButtonProps> = ({
  isVisible,
}) => {
  return (
    <S.Wrapper isVisible={isVisible}>
      <S.PayPalButton id={PRIMER_PAY_PAL_ID} />
    </S.Wrapper>
  )
}

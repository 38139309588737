import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { answerWithCheckboxTheme } from 'storybook-ui'

import { AnswerWithCheckboxIcon } from 'components/AnswerWithCheckboxIcon'
import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color, Language, OptionType } from 'root-constants'

import { StyledMeaningfulStudy as S } from './MeaningfulStudy.styles'
import { MEANINGFUL_STUDY_MAP, SELECT_ALL } from './constants'

export const MeaningfulStudy: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = {
    name: pageId as string,
    type: OptionType.CHECKBOX,
    onChange: (answer: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, answer])
      } else {
        setAnswers(answers.filter((item) => item !== answer))
      }
    },
  }

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.meaningfulStudy.title', { lng: Language.EN }),
    nextPagePath,
  })

  const handleSelect = () => {
    handleContinue(answers)
  }

  const handleSelectAll = (_: string, isChecked: boolean) => {
    if (isChecked) {
      setAnswers(MEANINGFUL_STUDY_MAP.map(({ value }) => value))
    } else {
      setAnswers([])
    }
  }
  return (
    <S.Wrapper>
      <S.Title>{t('onboarding.meaningfulStudy.title')}</S.Title>
      {MEANINGFUL_STUDY_MAP.map(({ value, text }) => (
        <AnswerWithCheckboxIcon
          {...optionProps}
          minWidth="327px"
          height="auto"
          margin="0 0 8px 0"
          lineHeight="18px"
          padding="20px 24px"
          iconSize="24px"
          backgroundColor={Color.SKY_BASE}
          checkboxBackgroundColor={Color.SKY_BASE}
          theme={answerWithCheckboxTheme.BLESSED_PATH}
          key={value}
          value={value}
          checked={answers.includes(value)}
        >
          <Trans>{text}</Trans>
        </AnswerWithCheckboxIcon>
      ))}

      <AnswerWithCheckboxIcon
        {...optionProps}
        {...optionProps}
        minWidth="327px"
        height="auto"
        margin="0 0 8px 0"
        lineHeight="20px"
        iconSize="24px"
        backgroundColor={Color.SKY_BASE}
        checkboxBackgroundColor={Color.SKY_BASE}
        theme={answerWithCheckboxTheme.BLESSED_PATH}
        key={SELECT_ALL.value}
        value={SELECT_ALL.value}
        checked={answers.length === MEANINGFUL_STUDY_MAP.length}
        onChange={handleSelectAll}
      >
        <Trans i18nKey={SELECT_ALL.text} />
      </AnswerWithCheckboxIcon>

      <BottomBarPortal>
        <NavigationButton
          disabled={!answers.length}
          type="button"
          onClick={handleSelect}
        >
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </S.Wrapper>
  )
}

import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

type TVisibilityProps = {
  hasVisibilityControl?: boolean
}

export const StyledInput = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  InputContainer: styled.div`
    position: relative;
    width: 100%;
    height: 50px;
  `,
  Input: styled.input<TVisibilityProps>`
    width: 100%;
    height: 50px;
    padding: ${({ hasVisibilityControl }) =>
      hasVisibilityControl ? '12px 48px 16px 12px' : '12px 16px 16px 48px'};
    border: 1px solid ${Color.SKY_DARK};
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: border 0.2s;

    &::placeholder {
      color: ${Color.INK_LIGHTER};
    }

    &:focus,
    &:not(:placeholder-shown) {
      border-color: ${Color.SKY_DARK};
      color: ${Color.INK_DARKEST};
    }
  `,
  Icon: styled(SvgImage)`
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #999;
  `,
  Label: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.INK_LIGHT};
    margin-bottom: 8px;
  `,
  ErrorMessage: styled.p`
    margin: 5px 0 0 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: ${Color.RED};
  `,
  VisibilityButton: styled.button`
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }
  `,
}

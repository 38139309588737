import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledPersonalGrowthProgress = {
  Wrapper: styled(Container)`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px 24px 0;
  `,
  ProgressWrapper: styled.div`
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    background-color: ${Color.WHITE};
    padding: 32px 24px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    padding-bottom: 28px;

    & strong {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: ${Color.GOLD_BASE};
    }
  `,
  GraphWrapper: styled.div`
    position: relative;
    width: 279px;
    height: 269px;
    padding: 0 16px 32px;
  `,
  MonthLabel: styled.p`
    font-size: 12px;
    font-weight: 400px;
    line-height: 16px;
    color: ${Color.INK_LIGHT};
    text-align: center;
  `,
  MonthLabels: styled.div`
    position: absolute;
    bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
  `,
  Subtitle: styled.h3`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.INK_DARKEST};
    text-align: center;
    padding-bottom: 20px;
  `,
}

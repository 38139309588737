export const REVIEWS = [
  {
    author: 'purchaseLong.reviews.author1Name',
    age: 'purchaseLong.reviews.author1Age',
    comment: 'purchaseLong.reviews.author1Comment',
  },
  {
    author: 'purchaseLong.reviews.author2Name',
    age: 'purchaseLong.reviews.author2Age',
    comment: 'purchaseLong.reviews.author2Comment',
  },
  {
    author: 'purchaseLong.reviews.author3Name',
    age: 'purchaseLong.reviews.author3Age',
    comment: 'purchaseLong.reviews.author3Comment',
  },
]

export const EMAILS = [
  {
    author: 'purchaseLong.reviews.emails.author1',
    time: 'purchaseLong.reviews.emails.receivedTime1',
  },
  {
    author: 'purchaseLong.reviews.emails.author2',
    time: 'purchaseLong.reviews.emails.receivedTime2',
  },
]

import { INSPIRATION_PERSON_TYPE } from 'pages/questions/InspirationPerson/constants'

import bieberImage from 'assets/images/inspiration-bieber.png'
import curryImage from 'assets/images/inspiration-curry.png'
import kidmanImage from 'assets/images/inspiration-kidman.png'
import matthewImage from 'assets/images/inspiration-matthew.png'
import messiImage from 'assets/images/inspiration-messi.png'
import morganImage from 'assets/images/inspiration-morgan.png'
import muskImage from 'assets/images/inspiration-musk.png'
import obamaImage from 'assets/images/inspiration-obama.png'
import oprahImage from 'assets/images/inspiration-oprah.png'

export const QUESTION = 'Did you know that…'

export const INSPIRATION_PERSON_MAP = {
  [INSPIRATION_PERSON_TYPE.MATTHEW]: {
    subtitle: 'onboarding.inspirationPersonDetails.subtitleMatthew',
    imageUrl: matthewImage,
  },
  [INSPIRATION_PERSON_TYPE.BIEBER]: {
    subtitle: 'onboarding.inspirationPersonDetails.subtitleBieber',
    imageUrl: bieberImage,
  },
  [INSPIRATION_PERSON_TYPE.OPRAH]: {
    subtitle: 'onboarding.inspirationPersonDetails.subtitleOprah',
    imageUrl: oprahImage,
  },
  [INSPIRATION_PERSON_TYPE.OBAMA]: {
    subtitle: 'onboarding.inspirationPersonDetails.subtitleObama',
    imageUrl: obamaImage,
  },
  [INSPIRATION_PERSON_TYPE.CURRY]: {
    subtitle: 'onboarding.inspirationPersonDetails.subtitleCurry',
    imageUrl: curryImage,
  },
  [INSPIRATION_PERSON_TYPE.MESSI]: {
    subtitle: 'onboarding.inspirationPersonDetails.subtitleMessi',
    imageUrl: messiImage,
  },
  [INSPIRATION_PERSON_TYPE.KIDMAN]: {
    subtitle: 'onboarding.inspirationPersonDetails.subtitleKidman',
    imageUrl: kidmanImage,
  },
  [INSPIRATION_PERSON_TYPE.MORGAN]: {
    subtitle: 'onboarding.inspirationPersonDetails.subtitleMorgan',
    imageUrl: morganImage,
  },
  [INSPIRATION_PERSON_TYPE.MUSK]: {
    subtitle: 'onboarding.inspirationPersonDetails.subtitleMusk',
    imageUrl: muskImage,
  },
}

/* eslint react-hooks/exhaustive-deps: 0 */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  EventTypes,
  INativePaymentMethodManager,
  PaymentInstrumentType,
  PaymentMethodInfo,
  PaymentMethodType,
  Primer,
  PrimerHeadlessCheckout,
} from '@primer-io/checkout-web'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { updateUserConfigAction } from 'root-redux/actions/user'
import {
  selectError,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import {
  selectEmail,
  selectUUID,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { useBulkSelector } from 'hooks/useBulkSelector'

import { isPayPalAvailableByCountry } from 'helpers/isPayPalAvailableByCountry'

import {
  INITIAL_PRIMER_CONFIG,
  PRIMER_PAYMENT_ERRORS,
  PRIMER_PAY_PAL_ID,
  PaymentMethod,
  PaymentSystem,
} from 'modules/purchase/constants'
import { useIsPrimerCustomTokenReady } from 'modules/purchase/hooks/useIsPrimerCustomTokenReady'
import {
  CHECK_PAYPAL_REQUEST_BUTTON,
  setAvailableAlternativePaymentMethods,
  setPaymentMethodAction,
} from 'modules/purchase/redux/actions/common'
import {
  PURCHASE_PRIMER,
  SET_IS_PRIMER_RETRY_PROCESSING,
  SET_PRIMER_CLIENT_SESSION_TOKEN,
  getPrimerClientSessionTokenAction,
  primerPurchaseAction,
  primerResumePurchaseAction,
  setIsFirstPaymentRetryPassedAction,
  setIsPrimerRetryProcessing,
  setPrimerClientSessionTokenAction,
} from 'modules/purchase/redux/actions/primer'
import {
  selectAvailablePaymentMethods,
  selectCurrency,
  selectIsFirstPaymentRetryPassed,
  selectPlanId,
  selectPrimerClientSessionToken,
  selectProductId,
  selectSubscription,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

const useSelectors = () =>
  useBulkSelector({
    accountName: selectStripeAccountName,
    accountId: selectStripeAccountId,
    currency: selectCurrency,
    email: selectEmail,
    error: selectError,
    fullPrice: selectSubscriptionFullPrice,
    isFirstPaymentRetryPassed: selectIsFirstPaymentRetryPassed,
    primerClientSessionToken: selectPrimerClientSessionToken,
    productId: selectProductId,
    screenName: selectScreenName,
    selectedSubscription: selectSubscription,
    trialCurrentPrice: selectSubscriptionTrialPeriodPrice,
    uuid: selectUUID,
    countryCode: selectUserCountryCode,
    planId: selectPlanId,
  })

export const usePrimer = () => {
  const [isPaypalAvailable, setIsPaypalAvailable] = useState(false)
  const [hasPaypalMethod, setHasPaypalMethod] = useState(false)

  const primerRef = useRef<PrimerHeadlessCheckout | null>(null)
  const primerFormRef = useRef<HTMLFormElement>(null)
  const paypalManagerRef = useRef<INativePaymentMethodManager | null>(null)

  const store = useSelectors()

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isPrimerCustomTokenReady = useIsPrimerCustomTokenReady()
  const availablePaymentMethod = useSelector(selectAvailablePaymentMethods)

  const hasPaypalByCountry = isPayPalAvailableByCountry(
    store.countryCode.toUpperCase(),
  )

  const logPaymentStarted = (paymentMethod: PaymentMethod) => {
    eventLogger.logPurchaseStarted({
      screenName: store.screenName,
      productId: store.productId,
      priceDetails: {
        price: store.fullPrice,
        trial: !!store.trialCurrentPrice,
        currency: store.currency,
      },
      paymentSystem: PaymentSystem.PRIMER,
      stripeAccountName: store.accountName,
      stripeAccountId: store.accountId,
      paymentMethod,
    })
  }

  const retryPaymentCallback = useCallback(() => {
    if (!store.isFirstPaymentRetryPassed) {
      dispatch(setIsFirstPaymentRetryPassedAction(true))

      const submit = new Event('submit', {
        cancelable: true,
        bubbles: true,
      })

      primerFormRef.current?.dispatchEvent(submit)
      return
    }

    dispatch(
      setErrorAction(store.error || t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)),
    )
    dispatch(setIsPrimerRetryProcessing(false))
    dispatch(stopFetching(PURCHASE_PRIMER))
  }, [dispatch, store.error, store.isFirstPaymentRetryPassed, t])

  const initPayPalButton = async () => {
    if (!paypalManagerRef.current || isPaypalAvailable) return

    setIsPaypalAvailable(true)

    dispatch(startFetching(SET_IS_PRIMER_RETRY_PROCESSING))

    const payPalButton = paypalManagerRef.current.createButton()

    await payPalButton.render(PRIMER_PAY_PAL_ID, {
      style: {
        buttonType: 'responsive',
        buttonColor: 'gold',
        buttonHeight: 50,
      },
    })

    dispatch(stopFetching(SET_IS_PRIMER_RETRY_PROCESSING))
    payPalButton.addEventListener(EventTypes.CLICK, () => {
      eventLogger.logPaymentMethodSelected(PaymentMethod.PAYPAL)
      window.fbq('track', 'AddToCart', {}, { eventID: store.uuid })
      window.snaptr &&
        window.snaptr('track', 'ADD_CART', {
          user_email: store.email,
        })
      googleAnalyticsLogger.logAddingToCart(
        store.selectedSubscription as ISubscription,
      )

      dispatch(setPaymentMethodAction(PaymentMethod.PAYPAL))
      dispatch(updateUserConfigAction({ paymentSystem: PaymentSystem.PRIMER }))
    })
  }

  const setError = (err?: any) => {
    dispatch(stopFetching(PURCHASE_PRIMER))

    if (!err) {
      return dispatch(setErrorAction(t(PRIMER_PAYMENT_ERRORS.COMMON_ERROR)))
    }

    const e = PRIMER_PAYMENT_ERRORS[err?.code]
    if (e) return dispatch(setErrorAction(t(e)))

    if (err instanceof Error) {
      return dispatch(setErrorAction(err.message))
    }

    return dispatch(setErrorAction(JSON.stringify(err)))
  }

  const initPrimer = useCallback(async () => {
    if (!store.primerClientSessionToken || primerRef.current) return
    if (!hasPaypalByCountry) return

    primerRef.current = await Primer.createHeadless(
      store.primerClientSessionToken,
      {
        ...INITIAL_PRIMER_CONFIG,
        async onAvailablePaymentMethodsLoad(
          paymentMethods: PaymentMethodInfo[],
        ) {
          try {
            dispatch(stopFetching(SET_PRIMER_CLIENT_SESSION_TOKEN))

            const hasPayPal = paymentMethods.some(
              ({ type }) => type === PaymentMethodType.PAYPAL,
            )

            setHasPaypalMethod(() => hasPayPal && hasPaypalByCountry)

            if (!primerRef.current) return

            if (hasPaypalByCountry && hasPayPal && !paypalManagerRef.current) {
              paypalManagerRef.current =
                await primerRef.current.createPaymentMethodManager(
                  PaymentMethodType.PAYPAL,
                )

              await initPayPalButton()

              if (!availablePaymentMethod.includes(PaymentMethod.PAYPAL)) {
                dispatch(
                  setAvailableAlternativePaymentMethods(PaymentMethod.PAYPAL),
                )
              }
            }
            dispatch(stopFetching(CHECK_PAYPAL_REQUEST_BUTTON))
          } catch (e) {
            setError(e)
          }
        },
        onTokenizeSuccess({ token, paymentInstrumentType }, handler) {
          const paypalTypes = [
            PaymentInstrumentType.PAYPAL,
            PaymentInstrumentType.PAYPAL_VAULTED,
          ]

          if (paypalTypes.includes(paymentInstrumentType)) {
            logPaymentStarted(PaymentMethod.PAYPAL)
          }

          dispatch(
            primerPurchaseAction({
              token,
              handler,
              retryPaymentCallback,
            }),
          )
        },
        onTokenizeStart() {
          dispatch(startFetching(PURCHASE_PRIMER))
        },
        onTokenizeError() {
          setError()
        },
        onResumeSuccess({ paymentId, resumeToken }, handler) {
          dispatch(
            primerResumePurchaseAction({
              currentPaymentId: paymentId,
              resumeToken,
              handler,
              retryPaymentCallback,
            }),
          )
        },
      },
    )

    await primerRef.current.start()
  }, [
    store.primerClientSessionToken,
    hasPaypalByCountry,
    dispatch,
    retryPaymentCallback,
  ])

  const resetPrimerState = () => {
    setIsPaypalAvailable(false)
    primerRef.current = null
    paypalManagerRef.current = null
  }

  useEffect(() => {
    initPrimer()
  }, [initPrimer])

  useEffect(() => {
    if (store.planId && hasPaypalByCountry && isPrimerCustomTokenReady) {
      dispatch(getPrimerClientSessionTokenAction())
      dispatch(startFetching(SET_PRIMER_CLIENT_SESSION_TOKEN))
    }

    return () => {
      primerRef.current && resetPrimerState()
      dispatch(setPrimerClientSessionTokenAction(''))
      dispatch(updateUserConfigAction({ paymentId: '' }))
      dispatch(setIsFirstPaymentRetryPassedAction(false))
    }
  }, [dispatch, store.planId, hasPaypalByCountry])

  return {
    hasPaypalMethod,
    isPaypalAvailable,
    primerRef,
    primerFormRef,
    paypalManagerRef,
  }
}

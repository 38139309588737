import { useSelector } from 'react-redux'

import { selectActionList } from 'root-redux/selects/common'

import { SET_PRIMER_CLIENT_SESSION_TOKEN } from 'modules/purchase/redux/actions/primer'

export const useIsPrimerCustomTokenReady = () => {
  const fetchingActionsList = useSelector(selectActionList)

  return !fetchingActionsList.includes(SET_PRIMER_CLIENT_SESSION_TOKEN)
}

import { TAnswers, TUtmTags } from 'models/common.model'
import { IAppConfig, IPaymentConfig, TGiaApiKeys } from 'models/config.model'
import { IAppState } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'
import { IStep, IVariant } from 'models/variant.model'

import { ScreenName } from 'root-constants'

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching
export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList
export const selectError = (state: IAppState): any => state.common.error
export const selectCurrentVariant = (state: IAppState): IVariant | null =>
  state.common.currentVariant || null
export const selectCurrentVariantCohort = (state: IAppState): string =>
  state.common.currentVariant?.cohort || ''
export const selectCurrentVariantCohortToUse = (state: IAppState): string =>
  state.common.currentVariant?.parentCohort ||
  state.common.currentVariant?.cohort ||
  ''
export const selectCurrentVariantSteps = (state: IAppState): IStep[] =>
  state.common.currentVariant?.steps || []
export const selectFacebookPixelIds = (state: IAppState): string[] =>
  state.common.currentVariant?.facebookPixelIds || []
export const selectOutbrainPixelId = (state: IAppState): string =>
  state.common.currentVariant?.outbrainPixelId || ''
export const selectTaboolaPixelId = (state: IAppState): string =>
  state.common.currentVariant?.taboolaPixelId || ''
export const selectRedditPixelId = (state: IAppState): string =>
  state.common.currentVariant?.redditPixelId || ''
export const selectHasCancelOffer = (state: IAppState): boolean =>
  state.common.currentVariant?.hasCancelOffer || false
export const selectSubscriptionList = (state: IAppState): ISubscription[] =>
  state.common.subscriptions || []
export const selectConfig = (state: IAppState): IAppConfig | null =>
  state.common.appConfig
export const selectAmplitudeApiKey = (state: IAppState): string | null =>
  state.common.appConfig?.amplitudeApiKey || null
export const selectGiaApiKeys = (state: IAppState): TGiaApiKeys | null =>
  state.common.appConfig?.giaApiKeys || null

export const selectGoogleAnalyticsId = (state: IAppState) =>
  state.common.appConfig?.googleAnalyticsId || null

export const selectGoogleAdsConversions = (state: IAppState) =>
  state.common.currentVariant?.googleAdsConversions || null
export const selectGoogleAdsId = (state: IAppState) =>
  state.common.currentVariant?.googleAnalyticsId || null

export const selectPaymentConfig = (state: IAppState): IPaymentConfig | null =>
  state.common.paymentConfig
export const selectStripeAccountId = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountId || ''
export const selectStripeAccountName = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountName || ''
export const selectStripePublicKey = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.publicKey || ''
export const selectPayPalClientId = (state: IAppState): string =>
  state.common.paymentConfig?.paypal.clientId || ''
export const selectAnswers = (state: IAppState): Partial<TAnswers> | null =>
  state.common.answers
export const selectLanguage = (state: IAppState): string =>
  state.common.language
export const selectScreenName = (state: IAppState): ScreenName =>
  state.common.screenName
export const selectTiktokPixelId = (state: IAppState): string =>
  state.common.currentVariant?.tiktokPixelId || ''
export const selectSnapPixelId = (state: IAppState): string =>
  state.common.currentVariant?.snapPixelId || ''
export const selectUtmTags = (state: IAppState): TUtmTags =>
  state.common.utmTags || {}

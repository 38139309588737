import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PrimerProvider } from 'providers/PrimerProvider'

import { startFetching } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { CheckoutSeparateMethodsLong } from 'modules/purchase/components/CheckoutSeparateMethods'
import { usePurchaseAnalytics } from 'modules/purchase/hooks'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  CHECK_PAYPAL_REQUEST_BUTTON,
} from 'modules/purchase/redux/actions/common'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { ScreenName } from 'root-constants'

import { StyledPaymentLong as S } from './PaymentLong.styles'

export const PaymentLong: React.FC<TPageProps> = () => {
  const selectedSubscription = useSelector(selectSubscription)
  const dispatch: TAppDispatch = useDispatch()
  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.PURCHASE,
  )

  useEffect(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    dispatch(startFetching(CHECK_PAYPAL_REQUEST_BUTTON))

    sendGeneralPurchaseEvents(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (!selectedSubscription) {
      goBack()
    }
  }, [selectedSubscription])

  return (
    selectedSubscription && (
      <S.Wrapper>
        <PrimerProvider>
          <CheckoutSeparateMethodsLong
            selectedSubscription={selectedSubscription}
          />
        </PrimerProvider>
      </S.Wrapper>
    )
  )
}

import styled from 'styled-components'

import { Color } from 'root-constants'

type TCircle = {
  percentage: number
}

export const StyledResults = {
  Root: styled.div`
    margin-bottom: 40px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-bottom: 16px;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
  Result: styled.li`
    display: flex;
    align-items: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;

    & div {
      margin-right: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  Circle: styled.circle<TCircle>`
    stroke-dashoffset: ${({ percentage }) =>
      `calc(126px - (126px * ${percentage}) / 100)`};
  `,
}

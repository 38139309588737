import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { answerWithCheckboxTheme } from 'storybook-ui'

import { AnswerWithRadioIcon } from 'components/AnswerWithRadioIcon'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color, OptionType } from 'root-constants'

import { StyledPersonalityType as S } from './PersonalityType.styles'
import { PERSONALITY_TYPES_OPTION, QUESTION } from './constants'

export const PersonalityType: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId as string,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Title>{t('onboarding.personalityType.title')}</S.Title>

      {PERSONALITY_TYPES_OPTION.map(({ value, text }) => (
        <AnswerWithRadioIcon
          {...optionProps}
          minWidth="327px"
          height="64px"
          margin="0 0 8px 0"
          iconSize="24px"
          checkboxBorderRadius="32px"
          backgroundColor={Color.SKY_BASE}
          checkboxBackgroundColor={Color.SKY_BASE}
          theme={answerWithCheckboxTheme.BLESSED_PATH}
          key={value}
          value={value}
          disabled={isAnswersDisabled}
        >
          <Trans>{text}</Trans>
        </AnswerWithRadioIcon>
      ))}
    </S.Wrapper>
  )
}

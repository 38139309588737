import React from 'react'
import { Trans } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { CURRENCY_SYMBOLS } from '../../constants'
import { usePurchaseStore } from '../../hooks'
import { StyledDisclaimer } from './Disclaimer.styles'

export const DisclaimerV1 = () => {
  const { currency, trialPrice, price } = usePurchaseStore()

  return (
    <StyledDisclaimer>
      <Trans
        i18nKey="purchaseLong.disclaimerMonthly"
        values={{
          currency: CURRENCY_SYMBOLS[currency],
          trialPrice,
          price,
        }}
        components={{
          termsOfUseLink: <TermsOfUseLink />,
        }}
      />
    </StyledDisclaimer>
  )
}

import React from 'react'

import { usePageConfig } from 'hooks/usePageConfig'

import { StyledHeaderV2 as S } from './HeaderV2.styles'

export const HeaderV2: React.FC = () => {
  const { isBackButtonDisabled, isWhiteHeader } = usePageConfig()

  return (
    <S.Wrapper isWhiteHeader={isWhiteHeader}>
      {!isBackButtonDisabled && <S.HeaderBackButton />}
      <S.Text>Blessed Path</S.Text>
    </S.Wrapper>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { HEADER_POSITIONS, ReviewV2 } from 'components/ReviewV2'

import { StyledReviewsLong as S } from './ReviewsLong.styles'
import { EMAILS, REVIEWS } from './constants'

export const ReviewsLong = () => {
  const { t } = useTranslation()
  return (
    <S.Root>
      <S.Subtitle>{t('purchaseLong.reviews.subtitle')}</S.Subtitle>

      <S.Emails>
        {EMAILS.map(({ author, time }) => (
          <S.Email key={author}>
            <span>{t(author)}</span>
            <span>{t(time)}</span>
          </S.Email>
        ))}
      </S.Emails>

      <S.Title>{t('purchase2.reviews.title')}</S.Title>

      <S.Reviews>
        {REVIEWS.map(({ author, comment, age }) => (
          <ReviewV2
            key={author}
            headerPosition={HEADER_POSITIONS.TOP}
            reviewerName={t(author)}
            comment={t(comment)}
            age={t(age)}
          />
        ))}
      </S.Reviews>
    </S.Root>
  )
}

import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import { Gender } from 'root-constants'

import { StyledBeforeAfterCards as S } from './BeforeAfterCards.styles'
import { IDS, OPTIONS_BY_GENDER } from './constants'

export const BeforeAfterCards = () => {
  const userAnswers = useSelector(selectAnswers)

  const cardOptions =
    OPTIONS_BY_GENDER[userAnswers?.selectGender] ||
    OPTIONS_BY_GENDER[Gender.FEMALE]

  return (
    <S.Cards>
      {cardOptions.map(({ id, value, imageUrl, imageAlt }) => {
        return (
          <S.CardContainer key={id} isActive={id === IDS.AFTER}>
            <S.ImageWrapper>
              <S.CardImage src={imageUrl} alt={imageAlt} />
            </S.ImageWrapper>
            <S.ContentWrapper>
              <Trans i18nKey={value} />
            </S.ContentWrapper>
          </S.CardContainer>
        )
      })}
    </S.Cards>
  )
}

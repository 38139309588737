import styled, { css } from 'styled-components'

import { Option } from 'components/Option'

import { Color } from 'root-constants'

export const StyledOptionCard = {
  Option: styled(Option)<{ $imageUrl: string; checked?: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 235px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    background-image: url(${({ $imageUrl }) => $imageUrl});
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    transition: all 200ms ease-out;

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: inherit;
      background-color: ${({ checked }) =>
        checked ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
      transition: background-color 200ms ease-out;
    }

    ${({ checked }) =>
      checked &&
      css`
        box-shadow: 0 0 0 2px ${Color.WHITE},
          0 4px 12px rgba(255, 255, 255, 0.2);
      `}

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &:hover:not(:disabled) {
      transform: scale(1.02);
    }
  `,
  OptionLabel: styled.div`
    position: absolute;
    bottom: 12px;
    left: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 24px);
    padding: 12px 16px;
    border-radius: 48px;
    background-color: rgba(255, 255, 255, 0.9);

    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  `,
}

import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledNotification = {
  Root: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 24px 16px 20px;
    border: 1px solid ${Color.GREEN_DARKEST};
    background-color: ${Color.GREEN_LIGHTER};
    border-radius: 8px;
    gap: 8px;
  `,
  Icon: styled(SvgImage)`
    min-width: 24px;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  Title: styled.p`
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: ${Color.INK_DARKEST};
  `,
  Description: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.INK_LIGHT};
  `,
}

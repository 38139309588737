import React from 'react'

import logo from 'assets/images/logo.png'

import { StyledHeader as S } from './Header.styles'

export const Header: React.FC = () => (
  <S.Wrapper>
    <S.Image src={logo} alt="" />
    <S.Text>BlessedPath</S.Text>
  </S.Wrapper>
)

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import DailyRoutineImage from 'assets/images/spirituality-book.png'
import librarySvg from 'assets/images/sprite/library.svg'

import { StyledSpiritualityBenefits as S } from './SpiritualityBenefits.styles'
import { QUESTION } from './constants'

export const SpiritualityBenefits: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <S.Image src={DailyRoutineImage} />
      </S.ImageContainer>
      <S.Title>{t('onboarding.spiritualityBenefits.title')}</S.Title>
      <S.ImageContainer>
        <SvgImage svg={librarySvg} width={238} />
      </S.ImageContainer>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </S.Wrapper>
  )
}

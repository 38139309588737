export const MEANINGFUL_STUDY_VALUES = {
  CLEAR_PLAN_WITH_DAILY_READINGS: 'clear_plan_with_daily_readings',
  UPLIFTING_MESSAGES: 'uplifting_messages',
  DEEPER_INSIGHTS: 'deeper_insights',
  SUPPORTIVE_COMMUNITY: 'supportive_community',
  PRACTICAL_WAYS: 'practical_ways',
  ALL_OF_THE_ABOVE: 'all_of_the_above',
}

export const SELECT_ALL = {
  value: MEANINGFUL_STUDY_VALUES.ALL_OF_THE_ABOVE,
  text: 'onboarding.meaningfulStudy.allOfTheAbove',
}

export const MEANINGFUL_STUDY_MAP = [
  {
    value: MEANINGFUL_STUDY_VALUES.CLEAR_PLAN_WITH_DAILY_READINGS,
    text: 'onboarding.meaningfulStudy.clearPlanWithDailyReadings',
  },
  {
    value: MEANINGFUL_STUDY_VALUES.UPLIFTING_MESSAGES,
    text: 'onboarding.meaningfulStudy.upliftingMessages',
  },
  {
    value: MEANINGFUL_STUDY_VALUES.DEEPER_INSIGHTS,
    text: 'onboarding.meaningfulStudy.deeperInsights',
  },
  {
    value: MEANINGFUL_STUDY_VALUES.SUPPORTIVE_COMMUNITY,
    text: 'onboarding.meaningfulStudy.supportiveCommunity',
  },
  {
    value: MEANINGFUL_STUDY_VALUES.PRACTICAL_WAYS,
    text: 'onboarding.meaningfulStudy.practicalWays',
  },
]

import React, { ButtonHTMLAttributes } from 'react'

import { StyledNavigationButton as S } from './NavigationButton.styles'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  className?: string
  onClick?: () => void
}

export const NavigationButton: React.FC<IButtonProps> = ({
  disabled,
  className = '',
  onClick,
  children,
  ...props
}) => {
  return (
    <S.Root className={className}>
      <S.NavigationButtonContainer>
        <S.StyledButton disabled={disabled} onClick={onClick} {...props}>
          {children}
        </S.StyledButton>
      </S.NavigationButtonContainer>
    </S.Root>
  )
}

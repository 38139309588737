export const TIME_TO_STUDY_BIBLE_TYPE = {
  MIN_10: 'up_to_10_minutes',
  MIN_20: 'up_to_20_minutes',
  MIN_30: 'up_to_30_minutes',
  MORE_THAN_30_MIN: 'more_than_30_minutes',
}

export const TIME_TO_STUDY_BIBLE_OPTIONS = [
  {
    value: TIME_TO_STUDY_BIBLE_TYPE.MIN_10,
    text: 'onboarding.timeToStudyBible.10min',
  },
  {
    value: TIME_TO_STUDY_BIBLE_TYPE.MIN_20,
    text: 'onboarding.timeToStudyBible.20min',
  },
  {
    value: TIME_TO_STUDY_BIBLE_TYPE.MIN_30,
    text: 'onboarding.timeToStudyBible.30min',
  },
  {
    value: TIME_TO_STUDY_BIBLE_TYPE.MORE_THAN_30_MIN,
    text: 'onboarding.timeToStudyBible.more30min',
  },
]

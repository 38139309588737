import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledHighlightsV1 as S } from './HighlightsV1.styles'

export const HighlightsV1 = () => {
  const { t } = useTranslation()

  return (
    <>
      <S.HighlightsTitle>{t('purchaseLong.highlightsTitle')}</S.HighlightsTitle>

      <S.Highlights>
        {(
          t('purchaseLong.highlights', { returnObjects: true }) as string[]
        ).map((item, index) => (
          <S.Highlight key={item}>
            <Trans i18nKey={`purchaseLong.highlights.${index}`} />
          </S.Highlight>
        ))}
      </S.Highlights>
    </>
  )
}

import { KEYWORD_PARAM } from 'root-constants'

export const getPathFromPageId = ({
  pageId,
  cohort,
  uuid,
  language,
  currentSearch,
}: {
  pageId: string
  cohort: string | null
  uuid: string
  language: string
  currentSearch: string
}): string => {
  const keyword = new URLSearchParams(document.location.search).get(
    KEYWORD_PARAM,
  )

  const searchParams = new URLSearchParams(currentSearch)

  searchParams.set('cohort', cohort || '')
  searchParams.set('uuid', uuid)
  searchParams.set('lang', language)

  keyword && searchParams.set(KEYWORD_PARAM, keyword)

  return `${pageId}?${searchParams.toString()}`
}

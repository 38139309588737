import styled from 'styled-components'

import { Option } from 'components/Option'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledInspirationPerson = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 375px;
    padding: 36px 24px 0;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
    padding-bottom: 24px;
    text-align: center;
  `,
  Image: styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 80px;
  `,
  InspirationCardsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 12px;
    margin: 0 auto;
  `,
  Option: styled(Option)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 101px;
    gap: 12px;
  `,
  ImageWrapper: styled.div<{ $selected?: boolean }>`
    position: relative;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border-radius: 80px;
    box-shadow: ${({ $selected }) =>
      $selected ? `0 0 0 4px ${Color.WHITE}` : 'none'};
    transition: box-shadow 0.3s ease-in-out;
    overflow: hidden;
  `,
  InspirationInfo: styled.p`
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: ${Color.INK_DARKEST};
  `,
  CheckIcon: styled(SvgImage)<{ $visible?: boolean }>`
    position: absolute;
    right: 5px;
    border-radius: 50%;
    background-color: ${Color.GOLD_BASE};
    opacity: ${({ $visible }) => ($visible ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1;
  `,
}

import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSubscriptionsBlock = {
  Wrapper: styled.div`
    width: 100%;
    margin-bottom: 15px;
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.BLUE_DARK};
  `,
}

import styled, { css } from 'styled-components'

const hiddenStyles = css`
  position: absolute;
  z-index: -1;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;
`

export const StyledPrimerPayPalButton = {
  Wrapper: styled.div<{ isVisible: boolean }>`
    ${({ isVisible }) => (isVisible ? 'position: static;' : hiddenStyles)}
  `,
  PayPalButton: styled.div`
    position: relative;
    width: 100%;
    height: 50px;
    margin-bottom: 16px;
    border-radius: 30px;
    overflow: hidden;
    z-index: 1;
    &:active {
      opacity: 0.7;
    }
  `,
}

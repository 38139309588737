import React, { ReactElement } from 'react'

import { ISvg } from 'components/SvgImage'

import { TextPosition } from 'modules/purchase/constants'

import { StyledPaymentMethodOption as S } from './PaymentMethodOption.styles'

export type TTextPosition = typeof TextPosition[keyof typeof TextPosition]

export type TPaymentMethodProps = {
  $isSelected: boolean
  svg: ISvg
  $svgWidth?: number
  text?: ReactElement
  textPosition?: TTextPosition
}

export const PaymentMethodOption: React.FC<
  TPaymentMethodProps & { onClick: () => void }
> = ({
  $isSelected,
  svg,
  $svgWidth,
  textPosition = TextPosition.TOP,
  text,
  onClick,
}) => {
  const textComponent = text && (
    <S.PaymentMethodText textPosition={textPosition}>
      {text}
    </S.PaymentMethodText>
  )

  return (
    <S.PaymentMethod svg={svg} $isSelected={$isSelected} onClick={onClick}>
      {textPosition === TextPosition.TOP && textComponent}
      <S.PaymentMethodImage
        $isSelected={$isSelected}
        svg={svg}
        $svgWidth={$svgWidth}
      />
      {textPosition === TextPosition.BOTTOM && textComponent}
    </S.PaymentMethod>
  )
}

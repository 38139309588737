import styled from 'styled-components'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledNavigationButton = {
  Root: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px 24px 24px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: ${Color.SKY_BASE};
  `,
  NavigationButtonContainer: styled(Container)`
    min-width: 327px;
  `,
  StyledButton: styled(Button)`
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    height: 48px;
    background-color: ${Color.INK_DARKEST};
    color: ${Color.GOLD_LIGHTER};
    white-space: nowrap;
    padding: 16px 32px;
    transition: background-color 0.2s ease-out;

    &:disabled {
      background-color: ${Color.SKY_LIGHT};
      color: ${Color.SKY_DARK};
    }
  `,
}

export const QUESTION =
  'How often do you prioritize others needs and sacrifice your own ones?'

export const SELF_SATISFACTION_TYPE = {
  NEVER: 'never',
  RARELY: 'rarely',
  OFTEN: 'often',
  ALL_THE_TIME: 'all_the_time',
}
export const SELF_SATISFACTION_OPTION = [
  {
    value: SELF_SATISFACTION_TYPE.NEVER,
    text: 'onboarding.selfSatisfaction.never',
  },
  {
    value: SELF_SATISFACTION_TYPE.RARELY,
    text: 'onboarding.selfSatisfaction.rarely',
  },
  {
    value: SELF_SATISFACTION_TYPE.OFTEN,
    text: 'onboarding.selfSatisfaction.often',
  },
  {
    value: SELF_SATISFACTION_TYPE.ALL_THE_TIME,
    text: 'onboarding.selfSatisfaction.allTheTime',
  },
]

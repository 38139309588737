import styled, { css } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { TextPosition } from 'modules/purchase/constants'

import { Color } from 'root-constants'

import { TPaymentMethodProps, TTextPosition } from './PaymentMethodOption'

const topTextStyles = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
`

const bottomTextStyles = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
`

export const StyledPaymentMethodOption = {
  PaymentMethod: styled.div<TPaymentMethodProps>`
    flex-basis: calc(50% - 6px);
    height: 100px;
    border-radius: 12px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    border: ${({ $isSelected }) =>
      $isSelected
        ? `2px solid ${Color.GOLD_DARKEST}`
        : `1px solid rgba(0, 0, 0, 0.10)`};
    background-color: ${({ $isSelected }) =>
      $isSelected ? Color.WHITE : 'transparent'};
  `,
  PaymentMethodText: styled.p<{ textPosition: TTextPosition }>`
    color: ${Color.INK_DARKEST};
    margin-bottom: 8px;
    text-align: center;

    ${({ textPosition }) =>
      textPosition === TextPosition.BOTTOM ? bottomTextStyles : topTextStyles}
  `,
  PaymentMethodImage: styled(SvgImage)<TPaymentMethodProps>`
    width: ${({ $svgWidth: width }) => (width ? `${width}px` : 'auto')};
    ${({ $isSelected }) => !$isSelected && `filter: grayscale(1)`}
  `,
}

import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { NavigationButton } from 'components/NavigationButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import togetherImage from 'assets/images/image-together.png'

import { StyledTogether as S } from './Together.styles'
import { QUESTION } from './constants'

export const Together: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  return (
    <>
      <S.Wrapper>
        <S.ImageContainer>
          <S.Image src={togetherImage} />
        </S.ImageContainer>
        <S.Title>{t('onboarding.together.title')}</S.Title>
        <S.Subtitle>{t('onboarding.together.subtitle')}</S.Subtitle>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

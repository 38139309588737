import men18v2 from 'assets/images/man-18-34-2248-image.png'
import men35v2 from 'assets/images/man-35-44-2248-image.png'
import men45v2 from 'assets/images/man-45-54-2248-image.png'
import men55v2 from 'assets/images/man-55-2248-image.png'
import women18v2 from 'assets/images/women-18-34-2248-image.png'
import women35v2 from 'assets/images/women-35-44-2248-image.png'
import women45v2 from 'assets/images/women-45-54-2248-image.png'
import women55v2 from 'assets/images/women-55-2248-image.png'

export const WOMEN_AGE_OPTIONS = [
  {
    value: '18-34',
    imageUrl: women18v2,
  },
  {
    value: '35-44',
    imageUrl: women35v2,
  },
  {
    value: '45-54',
    imageUrl: women45v2,
  },
  {
    value: '55+',
    imageUrl: women55v2,
  },
]

export const MEN_AGE_OPTIONS = [
  {
    value: '18-34',
    imageUrl: men18v2,
  },
  {
    value: '35-44',
    imageUrl: men35v2,
  },
  {
    value: '45-54',
    imageUrl: men45v2,
  },
  {
    value: '55+',
    imageUrl: men55v2,
  },
]

import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TCircle = {
  percentage: number
}

const CommonCircleStyles = css`
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #e9e9e9;
  stroke-width: 3;
  stroke-linecap: round;
`

export const StyledResultsLong = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  `,
  Title: styled.h2`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
    text-align: center;
    margin-bottom: 16px;
  `,
  Result: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 128px;
    background-color: ${Color.WHITE};
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    padding: 30px 16px;
    gap: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  ResultText: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.INK_DARKEST};
  `,
  CircularContainer: styled.div`
    display: grid;
    place-content: center;
    position: relative;
  `,
  CircularSVG: styled.svg`
    position: relative;
    width: 68px;
    height: 68px;
    transform: rotate(90deg) scaleX(-1);
  `,
  Circle: styled.circle`
    ${CommonCircleStyles}
  `,
  ProgressCircle: styled.circle<TCircle>`
    ${CommonCircleStyles}
    stroke-dasharray: 196px;
    stroke-dashoffset: ${({ percentage }) =>
      `calc(196px - (196px * ${percentage}) / 100)`};
    stroke: ${Color.GREEN_DARKEST};
  `,
  PercentageText: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #17202a;
  `,
}

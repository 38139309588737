import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  sendUserAnswersAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import {
  selectGeneralSubscriptionDuration,
  selectGeneralSubscriptionPrice,
  selectGeneralSubscriptionPriceId,
  selectGeneralSubscriptionTrialPeriod,
  selectGeneralSubscriptionTrialPrice,
  selectUserPaymentCurrency,
  selectUserPaymentMethod,
} from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import logo from 'assets/images/logo-xl.png'

import { StyledDownload as S } from './Download.styles'
import { APP_OUTER_LINK } from './constants'

export const Download: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const paymentCurrency = useSelector(selectUserPaymentCurrency)
  const paymentMethod = useSelector(selectUserPaymentMethod)
  const price = useSelector(selectGeneralSubscriptionPrice)
  const priceId = useSelector(selectGeneralSubscriptionPriceId)
  const subscriptionDuration = useSelector(selectGeneralSubscriptionDuration)
  const trialPeriodDays = useSelector(selectGeneralSubscriptionTrialPeriod)
  const trialPrice = useSelector(selectGeneralSubscriptionTrialPrice)

  useEffect(() => {
    eventLogger.logGettingAppShown()
  }, [])

  useEffect(() => {
    dispatch(sendUserAnswersAction(null, true))
    dispatch(
      sendUserConfigAction({
        is_download_visited: true,
        payment_currency: paymentCurrency,
        payment_method: paymentMethod,
        subscription_price: `${price}`,
        subscription_duration: `${subscriptionDuration}`,
        price_id: `${priceId}`,
        trial_price: `${trialPrice}`,
        trial_period: `${trialPeriodDays}`,
      }),
    )
  }, [
    dispatch,
    paymentCurrency,
    paymentMethod,
    price,
    subscriptionDuration,
    priceId,
    trialPeriodDays,
    trialPrice,
  ])

  const handleContinue = useCallback(() => {
    window.location.href = APP_OUTER_LINK
  }, [])

  const handleClick = useCallback(() => {
    googleAnalyticsLogger.logDownloadButtonPressed()
    eventLogger.logDownloadTap(handleContinue)
  }, [handleContinue])

  return (
    <S.Wrapper>
      <S.Status>{t('gettingApp.description')}</S.Status>
      <S.Title>
        <Trans i18nKey="gettingApp.downloadToContinue" components={[<br />]} />
      </S.Title>
      <S.ImageContainer>
        <S.Image src={logo} alt="" />
        <S.ImageCaption>BlessedPath</S.ImageCaption>
      </S.ImageContainer>
      <S.Button type="button" onClick={handleClick}>
        {t`gettingApp.download`}
      </S.Button>
    </S.Wrapper>
  )
}

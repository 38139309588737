import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledBenefits = {
  Root: styled.div`
    border-radius: 24px;
    border: 1px solid ${Color.GRAY_LIGHT};
    background: ${Color.WHITE};
    padding: 8px;
    margin-bottom: 40px;
  `,
  Content: styled.div`
    border: 1px solid #ecb97a;
    padding: 16px;
    border-radius: 24px;
  `,
  Title: styled.h2`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
    text-align: center;
  `,
  Item: styled.li`
    display: flex;
    align-items: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
    margin-bottom: 16px;

    & span {
      display: grid;
      place-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba(156, 179, 232, 0.16);
      margin-right: 16px;
      flex-shrink: 0;
    }
  `,
}

import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import i18n from 'i18next'

import { Header } from 'components/Header'
import { HeaderV2 } from 'components/HeaderV2'
import { ProgressBar } from 'components/LinearProgressBar'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import {
  selectCurrentVariantCohort,
  selectLanguage,
  selectScreenName,
} from 'root-redux/selects/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useCohortInfo } from 'hooks/useCohortInfo'
import { useEventLoggerInitialization } from 'hooks/useEventLoggerInitialization'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useStartSession } from 'hooks/useStartSession'
import { useTestEnvQueryParameter } from 'hooks/useTestEnvQueryParameter'
import { useUtmTags } from 'hooks/useUtmTags'

import { Cohort } from 'root-constants'

import { RouteList } from '../../RouteList'

export const LayoutProvider: React.FC = () => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const language = useSelector(selectLanguage)
  const screenName = useSelector(selectScreenName)
  const dispatch = useDispatch()

  const { hasHeader, hasProgressbar } = useGetPageInfo()
  const { isLongFlow } = useCohortInfo()

  const HeaderComponent = useMemo(() => {
    return isLongFlow ? HeaderV2 : Header
  }, [isLongFlow])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    dispatch(sendFacebookParamsAction())
  }, [dispatch])

  useUtmTags()
  useGAPageTracker()
  useEventLoggerInitialization(cohort as Cohort)
  useAmplitudeInitialization(cohort as Cohort, screenName)
  useScrollToTop()
  useStartSession()
  useTestEnvQueryParameter()

  return (
    <>
      {hasHeader && <HeaderComponent />}
      {hasProgressbar && <ProgressBar />}
      <RouteList />
    </>
  )
}

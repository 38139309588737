import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sendUserRDTCID } from 'root-redux/actions/user'
import { selectRedditPixelId } from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

export const useRedditPixelInitialization = (): void => {
  const { search } = document.location
  const dispatch = useDispatch()
  const redditPixelId = useSelector(selectRedditPixelId)
  const uuid = useSelector(selectUUID)
  const email = useSelector(selectUserOnboardingEmail)

  const URLParams = new URLSearchParams(search)
  const rdtcid = URLParams.get('rdt_cid')

  useLayoutEffect(() => {
    if (!redditPixelId) return

    const script = document.createElement('script')
    script.text = `!function(w,d)
    {if(!w.rdt){var p=w.rdt=function(){p.sendEvent?
    p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var
    t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var
    s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}
    (window,document);`
    script.async = true
    document.head.appendChild(script)

    window.rdt &&
      window.rdt('init', redditPixelId, {
        email,
        externalId: uuid,
      })
    window.rdt && window.rdt('track', 'PageVisit')

    if (rdtcid && uuid) dispatch(sendUserRDTCID(rdtcid))
  }, [email, redditPixelId, uuid, rdtcid, dispatch])
}

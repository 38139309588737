export const QUESTION =
  'I frequently feel tired, and my energy levels fluctuate.'

export const ENERGY_LEVEL_TYPE = {
  STRONGLY_DISAGREE: 'strongly_disagree',
  SOMEWHAT_DISAGREE: 'somewhat_disagree',
  SOMEWHAT_AGREE: 'somewhat_agree',
  STRONGLY_AGREE: 'strongly_agree',
}
export const ENERGY_LEVEL_OPTION = [
  {
    value: ENERGY_LEVEL_TYPE.STRONGLY_DISAGREE,
    text: 'onboarding.energyLevel.stronglyDisagree',
  },
  {
    value: ENERGY_LEVEL_TYPE.SOMEWHAT_DISAGREE,
    text: 'onboarding.energyLevel.somewhatDisagree',
  },
  {
    value: ENERGY_LEVEL_TYPE.SOMEWHAT_AGREE,
    text: 'onboarding.energyLevel.somewhatAgree',
  },
  {
    value: ENERGY_LEVEL_TYPE.STRONGLY_AGREE,
    text: 'onboarding.energyLevel.stronglyAgree',
  },
]

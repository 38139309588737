import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSelectAge = {
  Wrapper: styled.div`
    max-width: 375px;
    height: 100%;
    max-height: 732px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px 0;
    margin: 0 auto;
  `,
  Container: styled.div`
    width: 375px;
    margin: 0 auto;
  `,
  Topic: styled.h2`
    font-size: 20px;
    font-style: normal;
    color: ${Color.INK_DARKEST};
    font-weight: 700;
    line-height: 24px;
  `,
  CardsContainer: styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    justify-items: center;
    grid-gap: 16px;
    padding: 24px 0;
  `,
}

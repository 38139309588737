export const STAR_IDS = ['star1', 'star2', 'star3', 'star4', 'star5']

export const REVIEW_TYPE = {
  REVIEW_1: 'review1',
  REVIEW_2: 'review2',
  REVIEW_3: 'review3',
}

export const USER_REVIEW_MAP = {
  [REVIEW_TYPE.REVIEW_1]: {
    title: 'onboarding.review1.title',
    subtitle: 'onboarding.review1.subtitle',
    reviewerName: 'onboarding.review1.reviewerName',
    comment: 'onboarding.review1.reviewComment',
  },
  [REVIEW_TYPE.REVIEW_2]: {
    title: 'onboarding.review2.title',
    subtitle: 'onboarding.review2.subtitle',
    reviewerName: 'onboarding.review2.reviewerName',
    comment: 'onboarding.review2.reviewComment',
  },
  [REVIEW_TYPE.REVIEW_3]: {
    title: 'onboarding.review3.title',
    subtitle: 'onboarding.review3.subtitle',
    reviewerName: 'onboarding.review3.reviewerName',
    comment: 'onboarding.review3.reviewComment',
  },
}

export const ADDITIONAL_RESOURCES_VALUES = {
  DAILY_DEVOTIONALS_AND_INSIGHTS: 'daily_devotionals_and_insights',
  PRAYER_PROMPTS: 'prayer_prompts',
  REFLECTION_QUESTIONS: 'reflection_questions',
  AUDIO_BIBLE_AND_MEDITATIONS: 'audio_bible_and_meditations',
  STUDY_GUIDES_AND_COMMENTARIES: 'study_guides_and_meditations',
  NOT_THIS_TIME: 'not_this_time',
}

export const ADDITIONAL_RESOURCES_MAP = [
  {
    value: ADDITIONAL_RESOURCES_VALUES.DAILY_DEVOTIONALS_AND_INSIGHTS,
    text: 'onboarding.additionalResources.dailyDevotionalsAndInsights',
  },
  {
    value: ADDITIONAL_RESOURCES_VALUES.PRAYER_PROMPTS,
    text: 'onboarding.additionalResources.prayerPrompts',
  },
  {
    value: ADDITIONAL_RESOURCES_VALUES.REFLECTION_QUESTIONS,
    text: 'onboarding.additionalResources.reflectionQuestions',
  },
  {
    value: ADDITIONAL_RESOURCES_VALUES.AUDIO_BIBLE_AND_MEDITATIONS,
    text: 'onboarding.additionalResources.audioBibleAndMeditations',
  },
  {
    value: ADDITIONAL_RESOURCES_VALUES.STUDY_GUIDES_AND_COMMENTARIES,
    text: 'onboarding.additionalResources.studyGuidesAndCommentaries',
  },
  {
    value: ADDITIONAL_RESOURCES_VALUES.NOT_THIS_TIME,
    text: 'onboarding.additionalResources.notThisTime',
  },
]

export const BIBLE_JOURNEY_TYPE = {
  BEGINNER: 'beginner',
  KNOW_SOME_PARTS: 'know_some_parts',
  STYDIED_DEEPLY: 'stydied_before',
  NOT_SURE: 'not_sure',
}
export const BIBLE_JOURNEY_OPTION = [
  {
    value: BIBLE_JOURNEY_TYPE.BEGINNER,
    text: 'onboarding.bibleJourney.beginner',
  },
  {
    value: BIBLE_JOURNEY_TYPE.KNOW_SOME_PARTS,
    text: 'onboarding.bibleJourney.knowSomeParts',
  },
  {
    value: BIBLE_JOURNEY_TYPE.STYDIED_DEEPLY,
    text: 'onboarding.bibleJourney.studiedDeeply',
  },
  {
    value: BIBLE_JOURNEY_TYPE.NOT_SURE,
    text: 'onboarding.bibleJourney.notSure',
  },
]

import { AnswerWithCheckbox } from 'storybook-ui'
import styled from 'styled-components'

import checkIcon from 'assets/images/checkbox-check-white.svg'

export const AnswerWithCheckboxIcon = styled(AnswerWithCheckbox)`
  & {
    input:checked + & > span {
      background-image: url(${checkIcon});
    }
  }
`

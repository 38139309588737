import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StyledResultsLong as S } from './ResultsLong.styles'
import { RESULTS } from './constants'

const StaticCircularProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <S.CircularContainer>
      <S.CircularSVG>
        <S.Circle cx="34" cy="34" r="31" />
        <S.ProgressCircle cx="34" cy="34" r="31" percentage={percentage} />
      </S.CircularSVG>
      <S.PercentageText>
        {percentage}
        <span>%</span>
      </S.PercentageText>
    </S.CircularContainer>
  )
}

export const ResultsLong = () => {
  const { t } = useTranslation()

  return (
    <>
      <S.Title>
        <Trans i18nKey="purchaseLong.results.title" />
      </S.Title>
      <S.Root>
        {RESULTS.map(({ text, percentage }) => (
          <S.Result key={text}>
            <StaticCircularProgressBar percentage={percentage} />
            <S.ResultText>{t(text)}</S.ResultText>
          </S.Result>
        ))}
      </S.Root>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import starSvg from 'assets/images/sprite/star-gold.svg'

import { Language } from 'root-constants'

import { StyledUserReview as S } from './UserReview.styles'
import { REVIEW_TYPE, STAR_IDS, USER_REVIEW_MAP } from './constants'

export const UserReview: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [isCTAActive, setIsCTAActive] = useState(false)
  const userReview =
    USER_REVIEW_MAP[pageId] || USER_REVIEW_MAP[REVIEW_TYPE.REVIEW_1]

  const handleContinue = useNextStep({
    pageId,
    question: t(userReview.title, { lng: Language.EN }),
    nextPagePath,
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCTAActive(true)
    }, 2000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <S.Wrapper>
        <Container>
          <S.Title>
            <Trans i18nKey={userReview.title} />
          </S.Title>
          <S.Subtitle>{t(userReview.subtitle)}</S.Subtitle>
        </Container>
        <S.ReviewContainer>
          <S.ReviewHeader>
            <S.ReviewTitle>{t(userReview.reviewerName)}</S.ReviewTitle>
            <S.ReviewRating>
              {STAR_IDS.map((id) => (
                <SvgImage key={id} svg={starSvg} width={16} />
              ))}
            </S.ReviewRating>
          </S.ReviewHeader>
          <S.ReviewComment>{t(userReview.comment)}</S.ReviewComment>
        </S.ReviewContainer>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton
          type="button"
          onClick={() => handleContinue('')}
          disabled={!isCTAActive}
        >
          <Trans i18nKey="actions.continue" />
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

import styled from 'styled-components'

export const StyledReviews = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0 32px;
    background: #fafaff;
    min-height: calc(var(--full-height, 100dvh) - 52px);
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
  `,
}

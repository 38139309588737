import styled, { css } from 'styled-components'

import checkIcon from 'assets/images/checkbox-check-white.svg'
import priceBadgeIcon from 'assets/images/price-badge-gray.svg'
import priceBadgeSelectedIcon from 'assets/images/price-badge-green.svg'

import { Color } from 'root-constants'

type TProps = {
  isSelected: boolean
  isDefault?: boolean
}

export const StyledSelectSubscriptionItemLong = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 16px;
    position: relative;
    background: ${Color.SKY_BASE};
  `,
  Content: styled.div<TProps>`
    height: 90px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 24px 16px;
    position: relative;
    border-radius: 16px;
    background-color: ${Color.SKY_BASE};

    ${({ isSelected }) =>
      isSelected
        ? css`
            border: 2px solid ${Color.GOLD_DARKEST};
            background-color: ${Color.WHITE};
          `
        : css`
            border: 1px solid rgba(0, 0, 0, 0.18);
          `};

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      border: 1px solid ${Color.SKY_DARK};
      border-radius: 48px;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);

      ${({ isSelected }) =>
        isSelected &&
        `
          background: url(${checkIcon}) no-repeat center;
          background-color: ${Color.GOLD_BASE};
          background-size: 22px 22px;
          border: 1px solid ${Color.GOLD_BASE};
        `}
    }
    transition: border 0.2s ease, background-color 0.2s ease;
  `,
  PlanContainer: styled.div`
    padding-left: 32px;
  `,
  MostPopularBadge: styled.div`
    padding: 4px 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 1px;
    border-radius: 24px;
    color: ${Color.WHITE};
    text-transform: uppercase;
    background-color: ${Color.GOLD_DARKEST};
    position: absolute;
    left: 44px;
    z-index: 2;
    top: 0;
    transform: translateY(-50%);
  `,
  PlanPeriod: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-transform: capitalize;
    color: ${Color.INK_DARKEST};
    margin-bottom: 10px;
  `,
  PricesComparisonWrapper: styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
  `,
  PreviousPrice: styled.span`
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${Color.INK_LIGHT};
    margin-right: 8px;
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
    white-space: nowrap;
  `,
  CurrentPrice: styled.span`
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: ${Color.INK_DARKEST};
  `,
  OldDailyPrice: styled.span`
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${Color.INK_LIGHT};
    text-decoration: line-through;
    text-decoration-color: ${Color.RED};
  `,
  CustomPrice: styled.p<TProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: right;
    flex-basis: 87px;
    height: 42px;
    padding: 6px 12px 7px;
    background: ${({ isSelected }) =>
      isSelected
        ? `url(${priceBadgeSelectedIcon}) no-repeat center`
        : `url(${priceBadgeIcon}) no-repeat center`};
    background-size: contain;
    transition: all 0.15s ease-out;

    &::before {
      content: '';
      position: absolute;
      top: 18px;
      left: 9px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: ${({ isSelected }) =>
        isSelected ? Color.WHITE : Color.SKY_BASE};
    }
  `,
  PriceValue: styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-align: right;
    color: ${Color.WHITE};
  `,
  Period: styled.span`
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    text-align: right;
    color: ${Color.SKY_BASE};
  `,
}

import React, {
  RefObject,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useDispatch } from 'react-redux'

import { INativePaymentMethodManager } from '@primer-io/checkout-web'

import { useBulkSelector } from 'hooks/useBulkSelector'
import { usePrimer } from 'hooks/usePrimer'

import { updatePrimerClientSessionAction } from 'modules/purchase/redux/actions/primer'
import {
  selectIsFirstPaymentRetryPassed,
  selectIsPrimerRetryProcessing,
} from 'modules/purchase/redux/selects/common'

type TContext = {
  isPaypalAvailable: boolean
  hasPaypalMethod: boolean
  primerFormRef: RefObject<HTMLFormElement>
  paypalManagerRef: RefObject<INativePaymentMethodManager | null>
}

const useSelectors = () =>
  useBulkSelector({
    isFirstPaymentRetryPassed: selectIsFirstPaymentRetryPassed,
    isFirstPaymentRetryProcessing: selectIsPrimerRetryProcessing,
  })

const PrimerContext = createContext<TContext>({} as TContext)

export const PrimerProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const dispatch = useDispatch()
  const {
    isPaypalAvailable,
    hasPaypalMethod,
    primerFormRef,
    paypalManagerRef,
  } = usePrimer()

  const { isFirstPaymentRetryPassed, isFirstPaymentRetryProcessing } =
    useSelectors()

  const updatePrimerToken = useCallback(() => {
    if (!isFirstPaymentRetryProcessing && isFirstPaymentRetryPassed) {
      dispatch(updatePrimerClientSessionAction())
    }
  }, [dispatch, isFirstPaymentRetryPassed, isFirstPaymentRetryProcessing])

  const values = useMemo(
    () => ({
      isPaypalAvailable,
      hasPaypalMethod,
      primerFormRef,
      paypalManagerRef,
      updatePrimerToken,
    }),
    [
      hasPaypalMethod,
      isPaypalAvailable,
      paypalManagerRef,
      primerFormRef,
      updatePrimerToken,
    ],
  )

  return (
    <PrimerContext.Provider value={values}>{children}</PrimerContext.Provider>
  )
}

export const usePrimerContext = () => {
  const context = useContext(PrimerContext)

  if (!context) {
    throw new Error('usePrimerContext must be used within a PrimerProvider')
  }

  return context
}

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OptionCard } from 'components/OptionCard'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import starSvg from 'assets/images/sprite/star-gold.svg'

import { Language, OptionType } from 'root-constants'

import {
  StyledSelectGenderV2 as S,
  SEPARATOR_VARIANT,
} from './SelectGenderV2.styles'
import { GENDER_OPTIONS_V2 } from './constants'

export const SelectGenderV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const [answer, setAnswer] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.selectGenderV2.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId as string,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
      setAnswer(value)
    },
  }

  return (
    <S.Container>
      <S.RatingContainer>
        <SvgImage svg={starSvg} width={14} height={14} />
        <S.RatingText>4.9 out of 5</S.RatingText>
        <S.Separator variant={SEPARATOR_VARIANT.RATING} />
        <S.RatingText>23,578 reviews</S.RatingText>
      </S.RatingContainer>

      <S.Title>{t(`onboarding.selectGenderV2.title`)}</S.Title>
      <S.Topic>{t('onboarding.selectGenderV2.selectTitle')}</S.Topic>

      <S.CardsContainer>
        {GENDER_OPTIONS_V2.map(({ value, label, imageUrl }) => (
          <OptionCard
            key={value}
            imageUrl={imageUrl}
            value={value}
            label={label}
            checked={answer === value}
            disabled={isAnswersDisabled}
            {...optionProps}
          />
        ))}
      </S.CardsContainer>

      <S.Terms>
        <p>{t`onboarding.selectGenderV2.termsTitle`}</p>
        <TermsOfUseLink translationKey="onboarding.selectGenderV2.termsOfUse" />
        <S.Separator />
        <PrivacyPolicyLink />
        <S.Separator variant={SEPARATOR_VARIANT.DEFAULT} />
        <TermsOfUseLink translationKey="onboarding.selectGenderV2.subscriptionPolicy" />
      </S.Terms>

      <S.TermsNote>{t`onboarding.selectGenderV2.termsNote`}</S.TermsNote>
    </S.Container>
  )
}

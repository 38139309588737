import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BottomBarPortal } from 'components/BottomBar'
import { DecisionButtons } from 'components/DecisionButtons'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import wisdomOfProverbsImage from 'assets/images/proverbs-image.png'

import { Color, Language, OptionType } from 'root-constants'

import { StyledWisdomOfProverbs as S } from './WisdomOfProverbs.styles'

export const WisdomOfProverbs: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.wisdomOfProverbs.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.ImageContainer>
        <S.Image src={wisdomOfProverbsImage} />
      </S.ImageContainer>
      <S.Title>
        <Trans i18nKey="onboarding.wisdomOfProverbs.title" />
      </S.Title>
      <BottomBarPortal>
        <DecisionButtons
          {...optionProps}
          disabled={isAnswersDisabled}
          activeBackgroundColor={Color.WHITE}
        />
      </BottomBarPortal>
    </S.Wrapper>
  )
}

import femaleV2Image from 'assets/images/gender-female-2248-image.png'
import maleV2Image from 'assets/images/gender-male-2248-image.png'

import { Gender } from 'root-constants'

export const GENDER_OPTIONS_V2 = [
  {
    value: Gender.FEMALE,
    label: 'Female',
    imageUrl: femaleV2Image,
  },
  {
    value: Gender.MALE,
    label: 'Male',
    imageUrl: maleV2Image,
  },
]

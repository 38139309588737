import { useEffect } from 'react'

import 'root-redux/actions/user'
import {
  selectGoogleAdsConversions,
  selectGoogleAdsId,
  selectGoogleAnalyticsId,
} from 'root-redux/selects/common'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { useBulkSelector } from './useBulkSelector'

const useSelectors = () =>
  useBulkSelector({
    googleAnalyticsId: selectGoogleAnalyticsId,
    googleAdsId: selectGoogleAdsId,
    googleAdsConversions: selectGoogleAdsConversions,
  })

export const useGoogleAnalytics = (): void => {
  const { googleAnalyticsId, googleAdsConversions, googleAdsId } =
    useSelectors()

  useEffect(() => {
    if (googleAnalyticsId && googleAdsConversions && googleAdsId) {
      const initGtag = () => {
        const script = document.createElement('script')
        script.text = `window.dataLayer = window.dataLayer || [];
        gtag = function () { dataLayer.push(arguments); }
        gtag('js', new Date());
        `
        script.async = true
        document.head.appendChild(script)
      }

      const setUpGTM = () => {
        const script = document.createElement('script')
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
        script.async = true
        document.head.appendChild(script)
      }

      const setUpAdsGTM = () => {
        const script = document.createElement('script')
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`
        script.async = true
        document.body.appendChild(script)
      }

      googleAnalyticsLogger.configure(googleAdsConversions)
      initGtag()
      setUpGTM()
      setUpAdsGTM()

      if (!window.gtag) return

      window.gtag('config', googleAnalyticsId, {
        send_page_view: false,
      })
    }
  }, [googleAdsConversions, googleAdsId, googleAnalyticsId])
}

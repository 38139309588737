import styled, { css } from 'styled-components'

import closeSvg from 'assets/images/close-icon.svg'

import { Color } from 'root-constants'

type TContentContainerProps = {
  isVisible: boolean
}

const hiddenStyles = css`
  position: absolute;
  z-index: -1;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;
`

export const StyledCheckoutSeparateMethodsLong = {
  Root: styled.div`
    padding-top: 24px;
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
  ContentContainer: styled.div<TContentContainerProps>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    max-width: 375px;
    margin: 0 auto;
  `,
  PaymentsMethodsContainer: styled.div`
    max-width: 327px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  CloseButton: styled.button`
    display: block;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 24px 24px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
  `,
  PaymentMethodsTitle: styled.h2`
    color: ${Color.INK_DARKEST};
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 24px;
  `,
  PaymentMethodsWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px auto 0;

    & > div:not(:last-child) {
      margin-right: 12px;
    }
  `,
  PriceContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  `,
  PriceInfo: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
  PriceText: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.INK_LIGHT};
  `,
  PriceTextAmount: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
  `,
  PriceTextDiscount: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.GREEN_DARKEST};
  `,
  DailyPaymentContainer: styled.div`
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: ${Color.GREEN_DARKEST};
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  `,
  DailyPaymentText: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.SKY_LIGHT};
  `,
  DailyPaymentAmount: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.WHITE};
  `,
  TotalContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
  `,
  TotalText: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
  `,
  TotalAmount: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.INK_LIGHT};

    & strong {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: ${Color.INK_DARKEST};
    }
  `,
  Separator: styled.div`
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 24px 0;
  `,
  Guarantee: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 24px 0 48px;
  `,
  GuaranteeText: styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.INK_LIGHT};
  `,
  PaymentFormWrapper: styled.div``,

  StripePaymentWrapper: styled.div``,
  StripePaymentContainer: styled.div<TContentContainerProps>`
    ${({ isVisible }) => (isVisible ? 'position: static;' : hiddenStyles)}
  `,
}

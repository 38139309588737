import { PaymentMethod } from 'modules/purchase/constants'

import applePayActiveImage from 'assets/images/apple-pay-active.png'
import cardActiveImage from 'assets/images/card-icons-active.png'
import googlePayActiveImage from 'assets/images/google-pay-active.png'
import applePayActiveLongImage from 'assets/images/sprite/apple-pay-active-payment.svg'
import creditCardActiveImage from 'assets/images/sprite/card-icons-active-payment.svg'
import creditCardActiveAltImage from 'assets/images/sprite/credit-card-short-icon.svg'
import googlePayActiveLongImage from 'assets/images/sprite/google-pay-active-payment.svg'
import payPalActiveLongImage from 'assets/images/sprite/paypal-active-payment.svg'
import payPalActiveLongAltImage from 'assets/images/sprite/paypal-short-icon.svg'

export const PAYMENT_METHODS_IMAGES = {
  [PaymentMethod.APPLE_PAY]: applePayActiveImage,
  [PaymentMethod.GOOGLE_PAY]: googlePayActiveImage,
  [PaymentMethod.CREDIT_CARD]: cardActiveImage,
}

export const PAYMENT_METHODS_IMAGES_LONG = {
  [PaymentMethod.APPLE_PAY]: applePayActiveLongImage,
  [PaymentMethod.GOOGLE_PAY]: googlePayActiveLongImage,
  [PaymentMethod.CREDIT_CARD]: creditCardActiveImage,
  [PaymentMethod.PAYPAL]: payPalActiveLongImage,
}

export const PAYMENT_METHODS_IMAGES_LONG_ALT = {
  [PaymentMethod.CREDIT_CARD]: creditCardActiveAltImage,
  [PaymentMethod.PAYPAL]: payPalActiveLongAltImage,
}

export const SUPPORTED_DEFAULT_ALTERNATIVE_PAYMENT_METHODS: PaymentMethod[] = [
  PaymentMethod.GOOGLE_PAY,
  PaymentMethod.APPLE_PAY,
  PaymentMethod.PAYPAL,
]

export const SUPPORTED_DIGITAL_WALLET_PAYMENT_METHODS = [
  PaymentMethod.GOOGLE_PAY,
  PaymentMethod.APPLE_PAY,
]

export const DYNAMIC_DISCOUNT_DEFAULT_TEXT = '50% Off Mega Sale'

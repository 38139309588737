import React from 'react'

import { Progress } from 'components/Progress'

import { Color } from 'root-constants'

import { StyledProgressAnalyzer as S } from './ProgressAnalyzer.styles'

type TProps = {
  value: number
  color: string
  isInProgress: boolean
  isFinished: boolean
  children: React.ReactNode
}

export const ProgressAnalyzer: React.FC<TProps> = ({
  color,
  value,
  isInProgress,
  isFinished,
  children,
}) => (
  <S.Wrapper>
    <S.Description isInProgress={isInProgress} isFinished={isFinished}>
      <S.Text>{children}</S.Text>
      <S.Value>{value}%</S.Value>
    </S.Description>
    <S.ProgressWrapper>
      <Progress
        background={Color.WHITE}
        color={color}
        value={value}
        max={100}
      />
    </S.ProgressWrapper>
  </S.Wrapper>
)

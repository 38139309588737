import { PageId } from 'page-constants'

type TPageConfig = {
  isBackButtonDisabled: boolean
  isWhiteHeader?: boolean
}

export const DEFAULT_PAGE_CONFIG = {
  isBackButtonDisabled: false,
  isWhiteHeader: false,
}

export const PAGES_CONFIG: { [key in PageId]?: TPageConfig } = {
  [PageId.SELECT_GENDER]: {
    isBackButtonDisabled: true,
  },
  [PageId.PURCHASE_LONG]: {
    isBackButtonDisabled: true,
    isWhiteHeader: true,
  },
  [PageId.PAYMENT]: {
    isBackButtonDisabled: true,
  },
  [PageId.DOWNLOAD]: {
    isBackButtonDisabled: true,
  },
  [PageId.SIGNUP]: {
    isBackButtonDisabled: true,
  },
}

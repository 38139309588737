import styled from 'styled-components'

import checkIcon from 'assets/images/check-icon-gold.svg'

import { Color } from 'root-constants'

export const StyledGodSpeakToYou = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 375px;
    padding: 36px 24px 0;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    padding: 22px 0 16px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 1/1;
  `,
  Image: styled.img`
    width: 100%;
  `,
  Benefits: styled.ul`
    margin-bottom: 32px;
  `,
  Benefit: styled.li`
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 24px;
    margin-bottom: 8px;
    color: ${Color.INK_DARKEST};

    & strong {
      color: ${Color.BLUE_DARK};
    }

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url(${checkIcon}) no-repeat center;
      background-size: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
}

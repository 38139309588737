import { SubscriptionProduct } from 'root-constants'

export const DEFAULT_DISCOUNT = 50

export const TEN_MINUTES = {
  IN_SECONDS: 600,
  AS_STRING: '10:00',
}

export const HIGHLIGHTS_ORDER = [1, 2, 3, 4, 5]

export const LONG_PAYWALL_TRACKED_BREAKPOINTS: number[] = [
  16, 33, 50, 66, 83, 100,
]

export const DISCLAIMER = {
  [SubscriptionProduct.SEVEN_DAY]: 'purchase2.disclaimerWeekly',
  [SubscriptionProduct.ONE_MONTH]: 'purchase2.disclaimerMonthly',
  [SubscriptionProduct.THREE_MONTHS]: 'purchase2.disclaimerQuarterly',
}

export const SECURED_INFO = [
  {
    title: 'purchaseLong.securedInfo.title1',
    text: 'purchaseLong.securedInfo.description1',
  },
  {
    title: 'purchaseLong.securedInfo.title2',
    text: 'purchaseLong.securedInfo.description2',
  },
  {
    title: 'purchaseLong.securedInfo.title3',
    text: 'purchaseLong.securedInfo.description3',
    hasLink: true,
  },
]

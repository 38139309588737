import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { answerWithCheckboxTheme } from 'storybook-ui'

import { AnswerWithRadioIcon } from 'components/AnswerWithRadioIcon'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Color, Language, OptionType } from 'root-constants'

import { StyledRelationshipWithGod as S } from './RelationshipWithGod.styles'
import { RELATIONSHIP_WITH_GOD_OPTION } from './constants'

export const RelationshipWithGod: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.relationshipWithGod.title', { lng: Language.EN }),
    nextPagePath,
  })

  const optionProps = {
    name: pageId as string,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  return (
    <S.Wrapper>
      <S.Title>{t('onboarding.relationshipWithGod.title')}</S.Title>
      {RELATIONSHIP_WITH_GOD_OPTION.map(({ value, text }) => (
        <AnswerWithRadioIcon
          {...optionProps}
          minWidth="327px"
          height="64px"
          lineHeight="16px"
          padding="20px 24px"
          margin="0 0 8px 0"
          iconSize="24px"
          checkboxBorderRadius="32px"
          backgroundColor={Color.SKY_BASE}
          checkboxBackgroundColor={Color.SKY_BASE}
          theme={answerWithCheckboxTheme.BLESSED_PATH}
          key={value}
          value={value}
          disabled={isAnswersDisabled}
        >
          <Trans>{text}</Trans>
        </AnswerWithRadioIcon>
      ))}
    </S.Wrapper>
  )
}

import { Cohort } from 'root-constants'

export interface IAnalyzingAnswersStep {
  progressTitle: string
  modalQuestion: string
  stopCounterAt: number
  disablePoint: number
}

export interface IAnalyzingAnswersConfig {
  title: string
  subtitle: string
  progressSteps: IAnalyzingAnswersStep[]
}

export const QUESTION = 'Analyzing answers'

export const BASE = 'onboarding.analyzingAnswers'
export const BASE_V2 = 'onboarding.analyzingAnswersV2'

export const FIRST_STOP_COUNTER_AT = 20
export const SECOND_STOP_COUNTER_AT = 10
export const THIRD_STOP_COUNTER_AT = 30

export const STATIC_ANIMATION_DURATION = 4000
export const MODAL_HIDING_DELAY = 500
export const REVIEW_HIDING_DELAY = 300

export const FIRST_MODAL_DISABLE_POINT = 0
export const SECOND_MODAL_DISABLE_POINT = 1
export const THIRD_MODAL_DISABLE_POINT = 2

export const REVIEW_NUMBER = [1, 2, 3]

export const REVIEWS = [
  {
    title: `${BASE}.reviews.firstTitle`,
    author: `${BASE}.reviews.firstAuthor`,
    comment: `${BASE}.reviews.firstComment`,
  },
  {
    title: `${BASE}.reviews.secondTitle`,
    author: `${BASE}.reviews.secondAuthor`,
    comment: `${BASE}.reviews.secondComment`,
  },
  {
    title: `${BASE}.reviews.thirdTitle`,
    author: `${BASE}.reviews.thirdAuthor`,
    comment: `${BASE}.reviews.thirdComment`,
  },
]

export const DEFAULT_ANALYZING_ANSWERS_CONFIG: IAnalyzingAnswersConfig = {
  title: `${BASE}.title`,
  subtitle: `${BASE}.subtitle`,
  progressSteps: [
    {
      progressTitle: `${BASE}.progressBarTitle1`,
      modalQuestion: `${BASE}.modal1Question`,
      stopCounterAt: FIRST_STOP_COUNTER_AT,
      disablePoint: FIRST_MODAL_DISABLE_POINT,
    },
    {
      progressTitle: `${BASE}.progressBarTitle2`,
      modalQuestion: `${BASE}.modal2Question`,
      stopCounterAt: SECOND_MODAL_DISABLE_POINT,
      disablePoint: SECOND_MODAL_DISABLE_POINT,
    },
    {
      progressTitle: `${BASE}.progressBarTitle3`,
      modalQuestion: `${BASE}.modal3Question`,
      stopCounterAt: THIRD_STOP_COUNTER_AT,
      disablePoint: THIRD_MODAL_DISABLE_POINT,
    },
  ],
}

export const ANALYZING_ANSWERS_CONFIG: Partial<
  Record<Cohort, IAnalyzingAnswersConfig>
> = {
  [Cohort.FLOW_1_INTRO_LONG]: DEFAULT_ANALYZING_ANSWERS_CONFIG,
  [Cohort.FLOW_1_INTRO_LONG_2248]: {
    title: `${BASE_V2}.title`,
    subtitle: `${BASE_V2}.subtitle`,
    progressSteps: [
      {
        progressTitle: `${BASE_V2}.progressBarTitle1`,
        modalQuestion: `${BASE_V2}.modal1Question`,
        stopCounterAt: FIRST_STOP_COUNTER_AT,
        disablePoint: FIRST_MODAL_DISABLE_POINT,
      },
      {
        progressTitle: `${BASE_V2}.progressBarTitle2`,
        modalQuestion: `${BASE_V2}.modal2Question`,
        stopCounterAt: SECOND_MODAL_DISABLE_POINT,
        disablePoint: SECOND_MODAL_DISABLE_POINT,
      },
      {
        progressTitle: `${BASE_V2}.progressBarTitle3`,
        modalQuestion: `${BASE_V2}.modal3Question`,
        stopCounterAt: THIRD_STOP_COUNTER_AT,
        disablePoint: THIRD_MODAL_DISABLE_POINT,
      },
    ],
  },
}

export const BIBLE_LESSONS_STYLE_TYPE = {
  SHORT_DAILY_LESSONS: 'short_daily_lessons',
  INDEPTH_STUDIES: 'indepth_studies',
  MIX_OF_BOTH: 'mix_of_both',
  OTHER: 'other',
}

export const BIBLE_LESSONS_STYLE_OPTIONS = [
  {
    value: BIBLE_LESSONS_STYLE_TYPE.SHORT_DAILY_LESSONS,
    text: 'onboarding.bibleLessonsStyle.shortDailyLessons',
  },
  {
    value: BIBLE_LESSONS_STYLE_TYPE.INDEPTH_STUDIES,
    text: 'onboarding.bibleLessonsStyle.inDepthStudies',
  },
  {
    value: BIBLE_LESSONS_STYLE_TYPE.MIX_OF_BOTH,
    text: 'onboarding.bibleLessonsStyle.aMixOfBoth',
  },
  {
    value: BIBLE_LESSONS_STYLE_TYPE.OTHER,
    text: 'onboarding.bibleLessonsStyle.other',
  },
]

import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BottomBarPortal } from 'components/BottomBar'
import { Container } from 'components/Container'
import { NavigationButton } from 'components/NavigationButton'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import godSpeakFemaleImage from 'assets/images/good-speak-to-you-female-image.png'
import godSpeakMaleImage from 'assets/images/good-speak-to-you-male-image.png'

import { Gender, Language } from 'root-constants'

import { StyledGodSpeakToYou as S } from './GodSpeakToYou.styles'
import { BENEFITS_ORDER } from './constants'

export const GodSpeakToYou: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)

  const isFemale = userAnswers?.selectGenderV2 === Gender.FEMALE
  const godSpeakToYouImage = isFemale ? godSpeakFemaleImage : godSpeakMaleImage

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.godSpeakToYou.title', { lng: Language.EN }),
    nextPagePath,
  })

  useEffect(() => {
    googleAnalyticsLogger.logMidFunnelCompleted()
  }, [])

  return (
    <>
      <S.Wrapper>
        <S.ImageContainer>
          <S.Image src={godSpeakToYouImage} />
        </S.ImageContainer>
        <Container>
          <S.Title>
            <Trans i18nKey="onboarding.godSpeakToYou.title" />
          </S.Title>
        </Container>
        <S.Benefits>
          {BENEFITS_ORDER.map((benefitIndex) => {
            return (
              <S.Benefit key={benefitIndex}>
                <Trans
                  i18nKey={`onboarding.godSpeakToYou.benefit${benefitIndex}`}
                />
              </S.Benefit>
            )
          })}
        </S.Benefits>
      </S.Wrapper>
      <BottomBarPortal>
        <NavigationButton type="button" onClick={() => handleContinue('')}>
          {t('actions.continue')}
        </NavigationButton>
      </BottomBarPortal>
    </>
  )
}

export const QUESTION = 'Are you satisfied with your current romantic life?'

export const ROMANTIC_SATISFACTION_TYPE = {
  NOT_SATISFIED: 'not_satisfied',
  SOMEWHAT_SATISFIED: 'somewhat_satisfied',
  MOSTLY_SATISFIED: 'mostly_satisfied',
  COMPLETELY_SATISFIED: 'completely_satisfied',
}
export const ROMANTIC_SATISFACTION_OPTION = [
  {
    value: ROMANTIC_SATISFACTION_TYPE.NOT_SATISFIED,
    text: 'onboarding.romanticSatisfaction.notSatisfied',
  },
  {
    value: ROMANTIC_SATISFACTION_TYPE.SOMEWHAT_SATISFIED,
    text: 'onboarding.romanticSatisfaction.somewhatSatisfied',
  },
  {
    value: ROMANTIC_SATISFACTION_TYPE.MOSTLY_SATISFIED,
    text: 'onboarding.romanticSatisfaction.mostlySatisfied',
  },
  {
    value: ROMANTIC_SATISFACTION_TYPE.COMPLETELY_SATISFIED,
    text: 'onboarding.romanticSatisfaction.completelySatisfied',
  },
]

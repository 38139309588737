import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledUserReview = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 375px;
    padding: 16px 24px 0;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
  `,
  Subtitle: styled.h3`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.INK_DARKEST};
    text-align: center;
    padding: 8px 0 24px;
  `,
  ReviewContainer: styled(Container)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 16px;
    background-color: ${Color.WHITE};
    border: 1px solid rgba(0, 0, 0, 0.18);
  `,
  ReviewHeader: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  ReviewTitle: styled.span`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: ${Color.INK_DARKEST};
  `,
  ReviewRating: styled.div`
    display: flex;
    flex-direction: row;
    gap: 2px;
  `,
  ReviewComment: styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.INK_DARKEST};
  `,
}

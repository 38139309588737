import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantCohortToUse,
} from 'root-redux/selects/common'

import { Cohort } from 'root-constants'

interface ICohortInfo {
  isLongFlow: boolean
  cohortToUse: Cohort
}

export const useCohortInfo = (): ICohortInfo => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantCohortToUse)
  const cohortToUse = (cohort || parentCohort) as Cohort

  const isLongFlow =
    cohortToUse === Cohort.FLOW_1_INTRO_LONG ||
    cohortToUse === Cohort.FLOW_1_INTRO_LONG_2248 ||
    cohortToUse === Cohort.FLOW_1_INTRO_LONG_2247

  return {
    isLongFlow,
    cohortToUse,
  }
}

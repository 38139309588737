import React from 'react'
import { useTranslation } from 'react-i18next'

import { SvgImage } from 'components/SvgImage'

import bookIcon from 'assets/images/sprite/book.svg'
import smileEmoji from 'assets/images/sprite/smile-emoji.svg'
import starsIcon from 'assets/images/sprite/stars.svg'

import { StyledBenefits as S } from './Offer.styles'

export const Offer = () => {
  const { t } = useTranslation()

  return (
    <S.Root>
      <S.Content>
        <S.Title>{t('purchase2.offer.title')}</S.Title>
        <ul>
          <S.Item>
            <span>
              <SvgImage svg={smileEmoji} width={24} />
            </span>
            {t('purchase2.offer.firstItem')}
          </S.Item>
          <S.Item>
            <span>
              <SvgImage svg={bookIcon} width={24} />
            </span>
            {t('purchase2.offer.secondItem')}
          </S.Item>
          <S.Item>
            <span>
              <SvgImage svg={starsIcon} width={24} />
            </span>
            {t('purchase2.offer.thirdItem')}
          </S.Item>
        </ul>
      </S.Content>
    </S.Root>
  )
}

export const QUESTION =
  'People just like you achieved great results with BlessedPath!'

export const CHART_DATA = [
  {
    title: 'onboarding.peopleLikeYou.mentalHealth',
    height: 100,
    background: 'linear-gradient(180deg, #7dde86 0%, #4cd471 100%), #7dde86',
  },
  {
    title: 'onboarding.peopleLikeYou.purpose',
    height: 50,
    background: 'linear-gradient(180deg, #FF9898 0%, #FF6D6D 100%), #FF9898',
  },
  {
    title: 'onboarding.peopleLikeYou.decisionMaking',
    height: 62.5,
    background: 'linear-gradient(180deg, #FFD188 0%, #D7B555 100%), #FFD188',
  },
  {
    title: 'onboarding.peopleLikeYou.relationships',
    height: 75,
    background: 'linear-gradient(180deg, #C6C4FF 0%, #9990FF 100%), #C6C4FF ',
  },
]

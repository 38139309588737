import React from 'react'
import { useTranslation } from 'react-i18next'

import secureImage from 'assets/images/secure.png'

import { SUPPORT_MAIL } from 'root-constants'

import { StyledSecurityInfo as S } from './SecurityInfo.styles'

type TSecurityInfoProps = {
  className?: string
}

export const SecurityInfo: React.FC<TSecurityInfoProps> = ({
  className = '',
}) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper className={className}>
      <S.Badge src={secureImage} alt="" />
      <S.Title>{t`purchase2.security.title1`}</S.Title>
      <S.Text>{t('purchase2.security.text1')}</S.Text>
      <S.Title>{t`purchase2.security.title2`}</S.Title>
      <S.Text>{t('purchase2.security.text2')}</S.Text>
      <S.Title>{t`purchase2.security.title3`}</S.Title>
      <S.Text>
        {t('purchase2.security.text3')}{' '}
        <a href={`mailto: ${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>
      </S.Text>
    </S.Wrapper>
  )
}

import styled from 'styled-components'

import { Button } from 'components/Button'

import checkIcon from 'assets/images/check-icon-gold.svg'

import { Color } from 'root-constants'

export const StyledPurchaseLong = {
  Wrapper: styled.div`
    padding-bottom: 40px;
    max-width: 375px;
    margin: 0 auto;
    padding: 0 24px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    margin: 32px 0 16px;
  `,
  HighlightsTitle: styled.h2`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin: 32px 0 16px;
    text-align: center;
  `,
  Highlights: styled.ul`
    margin-bottom: 32px;
  `,
  Highlight: styled.li`
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-left: 24px;
    margin-bottom: 16px;
    color: ${Color.INK_DARKEST};

    & strong {
      color: ${Color.BLUE_DARK};
    }

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url(${checkIcon}) no-repeat center;
      background-size: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  TimerContainer: styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: ${Color.WHITE};
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  `,
  SubscriptionsContainer: styled.div``,
  DuplicateTimerTitle: styled.h2`
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    margin-bottom: 16px;
  `,
  DuplicateTimerContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 8px 16px;
    background: ${Color.BLUE_DARKER};
    margin-bottom: 16px;
  `,
  DuplicateTimerText: styled.span`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.SKY_LIGHT};
  `,
  DuplicateTimer: styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.SKY_LIGHT};
  `,
  ClaimButton: styled(Button)`
    width: 327px;
    height: 48px;
    border-radius: 48px;
    background-color: ${Color.INK_DARKEST};
    padding: 16px 32px;

    & p {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: ${Color.GOLD_LIGHTER};
    }
  `,
  Separator: styled.div`
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 32px 0;
  `,
  GuaranteeWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  GuaranteeTitle: styled.h2`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    margin: 24px 0 8px;
  `,
  GuaranteeText: styled.h3`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: ${Color.INK_DARKEST};

    & a {
      color: ${Color.INK_DARKEST};
      text-decoration: underline;
    }
  `,
  SecuredInfoWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  `,
  SecureInfoItem: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  SecuredInfoTitle: styled.p`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${Color.INK_DARKEST};
  `,
  SecuredInfoText: styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: ${Color.INK_DARKEST};

    & a {
      font-weight: 500;
      text-decoration: underline;
      color: ${Color.INK_DARKEST};
    }
  `,
  RightsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 64px 0 40px;
  `,
  RightsText: styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: ${Color.INK_LIGHTER};
  `,
  FeaturedWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  `,
  FeaturedLogos: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  `,
  FeaturedTitle: styled.p`
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    color: ${Color.INK_DARKEST};
  `,
}

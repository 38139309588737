import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'
import { selectUUID, selectUserOnboardingEmail } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

export const useStartSession = (): void => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const email = useSelector(selectUserOnboardingEmail)

  useEffect(() => {
    eventLogger.logSessionStarted(language)
  }, [dispatch, language])

  useEffect(() => {
    if (uuid) {
      window.fbq('track', 'ViewContent', {}, { eventID: uuid })
      window.rdt &&
        window.rdt('track', 'ViewContent', {
          email,
          externalId: uuid,
        })
    }
  }, [uuid, email])
}

import React from 'react'

import { SvgImage } from 'components/SvgImage'

import starSvg from 'assets/images/sprite/star-gold.svg'

import { StyledReviewV2 as S } from './ReviewV2.styles'

export const STAR_IDS = ['star1', 'star2', 'star3', 'star4', 'star5']

export const HEADER_POSITIONS = {
  TOP: 'top',
  BOTTOM: 'bottom',
} as const

export type THeaderPositions =
  typeof HEADER_POSITIONS[keyof typeof HEADER_POSITIONS]

export type TReviewV2Props = {
  reviewerName: string
  comment: string
  headerPosition?: THeaderPositions
  title?: string
  age?: string
}

export const ReviewV2: React.FC<TReviewV2Props> = ({
  reviewerName,
  comment,
  headerPosition = HEADER_POSITIONS.TOP,
  title,
  age,
}) => {
  const header = (
    <S.Header position={headerPosition}>
      <S.NameWrapper>
        <S.Title>{reviewerName}</S.Title>
        {age && <S.ReviewerAge>{age}</S.ReviewerAge>}
      </S.NameWrapper>
      <S.Rating>
        {STAR_IDS.map((id) => (
          <SvgImage key={id} svg={starSvg} width={16} />
        ))}
      </S.Rating>
    </S.Header>
  )

  return (
    <S.ReviewContainer>
      {headerPosition === HEADER_POSITIONS.TOP && header}
      {title && <S.Title extra>{title}</S.Title>}
      <S.Comment>{comment}</S.Comment>
      {headerPosition === HEADER_POSITIONS.BOTTOM && header}
    </S.ReviewContainer>
  )
}

import styled from 'styled-components'

import { Button } from 'components/Button'

type TRootProps = {
  isVisible?: boolean
}

type TWrapperProps = {
  buttonHeight: number
  marginBottom: number
  borderRadius: number
}

export const StyledPaymentRequestButton = {
  Root: styled.div<TRootProps>`
    position: relative;

    ${({ isVisible }) =>
      !isVisible &&
      `
        position: absolute;
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
      `}
  `,
  Wrapper: styled.div<TWrapperProps>`
    height: ${({ buttonHeight }) => `${buttonHeight}px`};
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
    opacity: 0.000000001;

    & * {
      border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    }
  `,
  Button: styled(Button)`
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  `,
}

export const QUESTION =
  'How often do you find yourself dwelling on past events longer than necessary?'

export const PAST_FOCUS_TYPE = {
  NEVER: 'strongly_disagree',
  RARELY: 'somewhat_disagree',
  OFTEN: 'somewhat_agree',
  ALL_THE_TIME: 'strongly_agree',
}
export const PAST_FOCUS_OPTION = [
  {
    value: PAST_FOCUS_TYPE.NEVER,
    text: 'onboarding.pastFocus.never',
  },
  {
    value: PAST_FOCUS_TYPE.RARELY,
    text: 'onboarding.pastFocus.rarely',
  },
  {
    value: PAST_FOCUS_TYPE.OFTEN,
    text: 'onboarding.pastFocus.often',
  },
  {
    value: PAST_FOCUS_TYPE.ALL_THE_TIME,
    text: 'onboarding.pastFocus.allTheTime',
  },
]

import styled from 'styled-components'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export const StyledDownloadLong = {
  Wrapper: styled.div`
    width: 375px;
    height: 100%;
    max-height: calc(100dvh - 48px);
    max-height: calc(100vh - 48px);
    margin: 0 auto;
    padding: 40px 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 48px;
    overflow: hidden;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    margin: 24px 0 8px;
  `,
  Subtitle: styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: ${Color.INK_DARKEST};
    margin-bottom: 16px;
  `,
  SvgImage: styled(SvgImage)`
    align-self: center;
    aspect-ratio: 1/1;
    object-fit: contain;
  `,
  SaleImage: styled.img`
    width: 100%;
  `,
  Button: styled(Button)`
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 48px;
    width: 100%;
    height: 48px;
    padding: 16px 32px;
    margin-top: 16px;
    background-color: ${Color.INK_DARKEST};
    color: ${Color.GOLD_LIGHTER};
  `,
}

import { ConfidenceResult } from 'pages/ConfidenceResult'
import { FinalTouch } from 'pages/FinalTouch'
import { GodSpeakToYou } from 'pages/GodSpeakToYou'
import { HappyYouHere } from 'pages/HappyYouHere/HappyYouHere'
import { InspirationPersonDetails } from 'pages/InspirationPersonDetails'
import { IntroVariant1 } from 'pages/Intro'
import { PeopleLikeYou } from 'pages/PeopleLikeYou'
import {
  PersonalGrowthProgress,
  PersonalGrowthProgressV2,
} from 'pages/PersonalGrowthProgress'
import { PersonalityResult } from 'pages/PersonalityResult'
import { Presence } from 'pages/Presence'
import { SatisfactionResult } from 'pages/SatisfactionResult'
import { SpiritualityBenefits } from 'pages/SpiritualityBenefits'
import { Together } from 'pages/Together'
import { TransformFaith } from 'pages/TransformFaith'
import { TransformLife } from 'pages/TransformLife'
import { UserReview } from 'pages/UserReview'
import { WelcomePath } from 'pages/WelcomePath'
import { WritingYourStory } from 'pages/WritingYourStory'
import {
  AdditionalResources,
  AriasToImprove,
  AspectToImprove,
  BibleGuidance,
  BibleJourney,
  BibleLessonsStyle,
  BibleVersion,
  ChristianValues,
  DailyPractice,
  DailyRoutine,
  DecisionMaking,
  DecisionOutcome,
  DeepingFaith,
  DiveDeeper,
  EnergyLevel,
  FaithBelieve,
  FeelScripture,
  FeelingFulfilled,
  FinancialConfidence,
  FindGuidance,
  GoalsAlignment,
  GrowthMotivation,
  GrowthTimeInvestment,
  IdealPlace,
  InspirationPerson,
  InspiredScripture,
  LifeVision,
  ListenToBible,
  MeaningfulStudy,
  MentalHealth,
  MostInspired,
  PartsToExplore,
  PastFocus,
  PersonalityType,
  ProfessionalConfidence,
  PsalmsUnderstanding,
  PursuePassion,
  Relationship,
  RelationshipWithGod,
  ReligiousHabits,
  Reviews,
  RomanticSatisfaction,
  SelectAge,
  SelectAgeV2,
  SelectGender,
  SelectGenderV2,
  SelfSatisfaction,
  SocialConfidence,
  StressLevel,
  StudyTheBible,
  TimeToStudyBible,
  WisdomOfProverbs,
} from 'pages/questions'
import { AnalyzingAnswers } from 'pages/questions/AnalyzingAnswers'

import { Email, EmailV2, SignUp } from 'modules/email/pages'
import { PaymentLong } from 'modules/purchase/pages/Payment/PaymentLong'
import {
  Purchase,
  PurchaseLong,
  PurchaseV2,
} from 'modules/purchase/pages/Purchase'

import { TIdToPageMap } from 'models/common.model'

export const enum PageId {
  INTRO = 'intro',
  FIND_GUIDANCE = 'findGuidance',
  LISTEN_STORIES = 'listenStories',
  REVIEWS = 'reviews',
  PURCHASE = 'purchase',
  PURCHASE_V2 = 'purchaseV2',
  DOWNLOAD = 'download',
  TRANSFORM_FAITH = 'transformFaith',
  SELECT_GENDER = 'selectGender',
  SELECT_GENDER_V2 = 'selectGenderV2',
  SELECT_AGE = 'selectAge',
  SELECT_AGE_V2 = 'selectAgeV2',
  WELCOME_PATH = 'welcomePath',
  BIBLE_JOURNEY = 'bibleJourney',
  FEEL_SCRIPTURE = 'feelScripture',
  RELATIONSHIP_WITH_GOD = 'relationshipWithGod',
  INSPIRED_SCRIPTURE = 'inspiredScripture',
  TRANSFORM_LIFE = 'transformLife',
  DEEPING_FAITH = 'deepingFaith',
  PARTS_TO_EXPLORE = 'partsToExplore',
  STUDY_THE_BIBLE = 'studyTheBible',
  MEANINGFUL_STUDY = 'meaningfulStudy',
  PRESENCE = 'presence',
  ASPECT_TO_IMPROVE = 'aspectToImprove',
  DAILY_PRACTICE = 'dailyPractice',
  LIFE_VISION = 'lifeVision',
  WRITING_YOUR_STORY = 'writingYourStory',
  MOST_INSPIRED = 'mostInspired',
  IDEAL_PLACE = 'idealPlace',
  TIME_TO_STUDY_BIBLE = 'timeToStudyBible',
  BIBLE_VERSION = 'bibleVersion',
  BIBLE_LESSONS_STYLE = 'bibleLessonsStyle',
  ADDITIONAL_RESOURCES = 'additionalResources',
  GOD_SPEAK_TO_YOU = 'godSpeakToYou',
  DIVE_DEEPER = 'diveDeeper',
  PSALMS_UNDERSTANDING = 'psalmsUnderstanding',
  WISDOM_OF_PROVERBS = 'wisdomOfProverbs',
  HAPPY_YOU_HERE = 'happyYouHere',
  ARIAS_TO_IMPROVE = 'ariasToImprove',
  TOGETHER = 'together',
  PERSONALITY_TYPE = 'personalityType',
  PERSONALITY_RESULT = 'personalityResult',
  DAILY_ROUTINE = 'dailyRoutine',
  ENERGY_LEVEL = 'energyLevel',
  STRESS_LEVEL = 'stressLevel',
  SPIRITUALITY_BENEFITS = 'spiritualityBenefits',
  DECISION_OUTCOME = 'decisionOutcome',
  PAST_FOCUS = 'pastFocus',
  GOALS_ALIGNMENT = 'goalsAlignment',
  FEELING_FULFILLED = 'feelingFulfilled',
  PURSUE_PASSIONS = 'pursuePassion',
  INSPIRATION_PERSON = 'inspirationPerson',
  INSPIRATION_PERSON_DETAILS = 'inspirationPersonDetails',
  FINANCIAL_CONFIDENCE = 'financialConfidence',
  PROFESSIONAL_CONFIDENCE = 'professionalConfidence',
  SOCIAL_CONFIDENCE = 'socialConfidence',
  CONFIDENCE_RESULT = 'confidenceResult',
  ROMANTIC_SATISFACTION = 'romanticSatisfaction',
  SELF_SATISFACTION = 'selfSatisfaction',
  SATISFACTION_RESULT = 'satisfactionResult',
  FAITH_BELIEVE = 'faithBelieve',
  RELIGIOUS_HABITS = 'religiousHabits',
  CHRISTIAN_VALUES = 'christianValues',
  BIBLE_GUIDANCE = 'bibleGuidance',
  PEOPLE_LIKE_YOU = 'peopleLikeYou',
  FINAL_TOUCH = 'finalTouch',
  MENTAL_HEALTH = 'mentalHealth',
  REVIEW_1 = 'review1',
  RELATIONSHIP = 'relationship',
  REVIEW_2 = 'review2',
  DECISION_MAKING = 'decisionMaking',
  REVIEW_3 = 'review3',
  GROWTH_MOTIVATION = 'growthMotivation',
  GROWTH_TIME_INVESTMENT = 'growthTimeInvestment',
  PERSONAL_GROWTH_PROGRESS = 'personalGrowthProgress',
  PERSONAL_GROWTH_PROGRESS_V2 = 'personalGrowthProgressV2',
  ANALYZING_ANSWERS = 'analyzingAnswers',
  EMAIL = 'email',
  EMAIL_V2 = 'emailV2',
  PURCHASE_LONG = 'purchaseLong',
  PAYMENT = 'payment',
  SIGNUP = 'signup',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  [PageId.INTRO]: IntroVariant1,
  [PageId.FIND_GUIDANCE]: FindGuidance,
  [PageId.LISTEN_STORIES]: ListenToBible,
  [PageId.REVIEWS]: Reviews,
  [PageId.PURCHASE]: Purchase,
  [PageId.PURCHASE_V2]: PurchaseV2,
  [PageId.TRANSFORM_FAITH]: TransformFaith,
  [PageId.SELECT_GENDER]: SelectGender,
  [PageId.SELECT_GENDER_V2]: SelectGenderV2,
  [PageId.SELECT_AGE]: SelectAge,
  [PageId.SELECT_AGE_V2]: SelectAgeV2,
  [PageId.WELCOME_PATH]: WelcomePath,
  [PageId.BIBLE_JOURNEY]: BibleJourney,
  [PageId.FEEL_SCRIPTURE]: FeelScripture,
  [PageId.RELATIONSHIP_WITH_GOD]: RelationshipWithGod,
  [PageId.INSPIRED_SCRIPTURE]: InspiredScripture,
  [PageId.TRANSFORM_LIFE]: TransformLife,
  [PageId.DEEPING_FAITH]: DeepingFaith,
  [PageId.PARTS_TO_EXPLORE]: PartsToExplore,
  [PageId.STUDY_THE_BIBLE]: StudyTheBible,
  [PageId.MEANINGFUL_STUDY]: MeaningfulStudy,
  [PageId.PRESENCE]: Presence,
  [PageId.ASPECT_TO_IMPROVE]: AspectToImprove,
  [PageId.DAILY_PRACTICE]: DailyPractice,
  [PageId.LIFE_VISION]: LifeVision,
  [PageId.WRITING_YOUR_STORY]: WritingYourStory,
  [PageId.MOST_INSPIRED]: MostInspired,
  [PageId.IDEAL_PLACE]: IdealPlace,
  [PageId.TIME_TO_STUDY_BIBLE]: TimeToStudyBible,
  [PageId.BIBLE_VERSION]: BibleVersion,
  [PageId.BIBLE_LESSONS_STYLE]: BibleLessonsStyle,
  [PageId.ADDITIONAL_RESOURCES]: AdditionalResources,
  [PageId.GOD_SPEAK_TO_YOU]: GodSpeakToYou,
  [PageId.DIVE_DEEPER]: DiveDeeper,
  [PageId.PSALMS_UNDERSTANDING]: PsalmsUnderstanding,
  [PageId.WISDOM_OF_PROVERBS]: WisdomOfProverbs,
  [PageId.HAPPY_YOU_HERE]: HappyYouHere,
  [PageId.ARIAS_TO_IMPROVE]: AriasToImprove,
  [PageId.TOGETHER]: Together,
  [PageId.PERSONALITY_TYPE]: PersonalityType,
  [PageId.PERSONALITY_RESULT]: PersonalityResult,
  [PageId.DAILY_ROUTINE]: DailyRoutine,
  [PageId.ENERGY_LEVEL]: EnergyLevel,
  [PageId.STRESS_LEVEL]: StressLevel,
  [PageId.SPIRITUALITY_BENEFITS]: SpiritualityBenefits,
  [PageId.DECISION_OUTCOME]: DecisionOutcome,
  [PageId.PAST_FOCUS]: PastFocus,
  [PageId.GOALS_ALIGNMENT]: GoalsAlignment,
  [PageId.FEELING_FULFILLED]: FeelingFulfilled,
  [PageId.PURSUE_PASSIONS]: PursuePassion,
  [PageId.INSPIRATION_PERSON]: InspirationPerson,
  [PageId.INSPIRATION_PERSON_DETAILS]: InspirationPersonDetails,
  [PageId.FINANCIAL_CONFIDENCE]: FinancialConfidence,
  [PageId.PROFESSIONAL_CONFIDENCE]: ProfessionalConfidence,
  [PageId.SOCIAL_CONFIDENCE]: SocialConfidence,
  [PageId.CONFIDENCE_RESULT]: ConfidenceResult,
  [PageId.ROMANTIC_SATISFACTION]: RomanticSatisfaction,
  [PageId.SELF_SATISFACTION]: SelfSatisfaction,
  [PageId.SATISFACTION_RESULT]: SatisfactionResult,
  [PageId.FAITH_BELIEVE]: FaithBelieve,
  [PageId.RELIGIOUS_HABITS]: ReligiousHabits,
  [PageId.CHRISTIAN_VALUES]: ChristianValues,
  [PageId.BIBLE_GUIDANCE]: BibleGuidance,
  [PageId.PEOPLE_LIKE_YOU]: PeopleLikeYou,
  [PageId.FINAL_TOUCH]: FinalTouch,
  [PageId.MENTAL_HEALTH]: MentalHealth,
  [PageId.REVIEW_1]: UserReview,
  [PageId.RELATIONSHIP]: Relationship,
  [PageId.REVIEW_2]: UserReview,
  [PageId.DECISION_MAKING]: DecisionMaking,
  [PageId.REVIEW_3]: UserReview,
  [PageId.GROWTH_MOTIVATION]: GrowthMotivation,
  [PageId.GROWTH_TIME_INVESTMENT]: GrowthTimeInvestment,
  [PageId.PERSONAL_GROWTH_PROGRESS]: PersonalGrowthProgress,
  [PageId.PERSONAL_GROWTH_PROGRESS_V2]: PersonalGrowthProgressV2,
  [PageId.ANALYZING_ANSWERS]: AnalyzingAnswers,
  [PageId.EMAIL]: Email,
  [PageId.EMAIL_V2]: EmailV2,
  [PageId.PURCHASE_LONG]: PurchaseLong,
  [PageId.PAYMENT]: PaymentLong,
  [PageId.SIGNUP]: SignUp,
}

export const POST_PAYMENT_PAGES: PageId[] = [PageId.SIGNUP, PageId.DOWNLOAD]

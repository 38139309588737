import { CountryCode } from 'root-constants'

export const COUNTRIES_WITHOUT_PAYPAL: string[] = [
  CountryCode.ARGENTINA,
  CountryCode.COLOMBIA,
  CountryCode.PERU,
  CountryCode.CHILE,
  CountryCode.DOMINICAN_REPUBLIC,
  CountryCode.URUGUAY,
  CountryCode.ECUADOR,
  CountryCode.GUATEMALA,
  CountryCode.VENEZUELA,
  CountryCode.PARAGUAY,
  CountryCode.BOLIVIA,
  CountryCode.NICARAGUA,
  CountryCode.HONDURAS,
  CountryCode.COSTA_RICA,
  CountryCode.PANAMA,
  CountryCode.EL_SALVADOR,
  CountryCode.MEXICO,
  CountryCode.PUERTO_RICO,
  CountryCode.BRAZIL,
  CountryCode.INDIA,
  CountryCode.PHILIPPINES,
  CountryCode.SOUTH_AFRICA,
  CountryCode.NIGERIA,
  CountryCode.KENYA,
  CountryCode.ZAMBIA,
  CountryCode.TANZANIA,
  CountryCode.ETHIOPIA,
  CountryCode.ZIMBABWE,
  CountryCode.SENEGAL,
  CountryCode.NAMIBIA,
]

export const isPayPalAvailableByCountry = (countryCode: string): boolean =>
  !COUNTRIES_WITHOUT_PAYPAL.includes(countryCode.toLowerCase())

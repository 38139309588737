import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { resetErrorAction } from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import {
  AnimationState,
  DEFAULT_ERROR_DESCRIPTION,
  FINAL_POPUP_TAP,
} from 'modules/purchase/constants'

import { eventLogger } from 'services/eventLogger.service'

import paymentErrorAnimation from 'assets/animation/paymentErrorAnimation.json'
import paymentSuccessAnimation from 'assets/animation/paymentSuccessAnimation.json'
import paymentWaitingAnimation from 'assets/animation/paymentWaitingAnimation.json'

import { CHECK_3D_SECURE, PURCHASE } from '../../redux/actions/common'
import { PURCHASE_PRIMER } from '../../redux/actions/primer'
import { select3DSecureIframeUrl } from '../../redux/selects/common'
import { StyledPaymentWaitingModalLong as S } from './PaymentWaitingModalLong.styles'

type TProps = {
  isPaymentWaitingShown: boolean
  resetErrorCallback?: () => void
  successCallback?: () => void
  setIsPaymentWaitingShown: (value: boolean) => void
  buttonText?: string
  errorButtonText?: string
  successSubtitle?: string
}

export const PaymentWaitingModalLong: React.FC<TProps> = ({
  isPaymentWaitingShown,
  setIsPaymentWaitingShown,
  resetErrorCallback,
  successCallback,
  buttonText,
  errorButtonText,
  successSubtitle,
}) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const uuid = useSelector(selectUUID)
  const fetchingActionsList = useSelector(selectActionList)
  const error = useSelector(selectError)
  const threeDSecureIframeURL = useSelector(select3DSecureIframeUrl)
  const [animationState, setAnimationState] = useState<AnimationState>(
    AnimationState.LOADING,
  )

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(CHECK_3D_SECURE) ||
      fetchingActionsList.includes(PURCHASE_PRIMER),
    [fetchingActionsList],
  )

  const loadingAnimationRef = useRef<HTMLDivElement | null>(null)
  const completeAnimationRef = useRef<HTMLDivElement | null>(null)
  const errorAnimationRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    return () => {
      dispatch(getUserStatusAction(uuid))
    }
  }, [dispatch, uuid])

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsPaymentWaitingShown(true)
    }

    if (isPurchaseInProcess && loadingAnimationRef.current) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        animationData: paymentWaitingAnimation,
        loop: true,
      })
    }

    if (!isPurchaseInProcess && isPaymentWaitingShown && error) {
      setAnimationState(AnimationState.ERROR)
    }

    if (
      !isPurchaseInProcess &&
      isPaymentWaitingShown &&
      !error &&
      !threeDSecureIframeURL
    ) {
      setAnimationState(AnimationState.SUCCESS)
    }

    return () => lottie.destroy()
  }, [
    error,
    isPurchaseInProcess,
    isPaymentWaitingShown,
    setIsPaymentWaitingShown,
    threeDSecureIframeURL,
  ])

  const handleResetError = useCallback(() => {
    setIsPaymentWaitingShown(false)
    setAnimationState(AnimationState.LOADING)
    dispatch(resetErrorAction())

    eventLogger.logPaymentFinalMethodPopupTap(FINAL_POPUP_TAP.ERROR)
    resetErrorCallback && resetErrorCallback()
  }, [dispatch, resetErrorCallback, setIsPaymentWaitingShown])

  const handleSuccessButtonClick = useCallback(() => {
    dispatch(getUserStatusAction(uuid))
    setIsPaymentWaitingShown(false)

    eventLogger.logPaymentFinalMethodPopupTap(FINAL_POPUP_TAP.SUCCESS)
    successCallback && successCallback()
  }, [dispatch, uuid, successCallback, setIsPaymentWaitingShown])

  useEffect(() => {
    switch (animationState) {
      case AnimationState.SUCCESS:
        if (completeAnimationRef.current) {
          lottie.loadAnimation({
            container: completeAnimationRef.current,
            animationData: paymentSuccessAnimation,
            loop: false,
          })
        }
        break
      case AnimationState.ERROR:
        if (errorAnimationRef.current) {
          lottie.loadAnimation({
            container: errorAnimationRef.current,
            animationData: paymentErrorAnimation,
            loop: false,
          })
        }
        break
      default:
        if (loadingAnimationRef.current) {
          lottie.loadAnimation({
            container: loadingAnimationRef.current,
            animationData: paymentWaitingAnimation,
            loop: true,
          })
        }
        break
    }

    return () => lottie.destroy()
  }, [animationState])

  useEffect(() => {
    if (isPaymentWaitingShown && wrapperRef.current) {
      wrapperRef.current.focus()
    }
  }, [isPaymentWaitingShown])

  return (
    <S.Wrapper isShown={isPaymentWaitingShown} ref={wrapperRef} tabIndex={-1}>
      <S.Content>
        <S.LottieContainer>
          {animationState === AnimationState.LOADING && (
            <>
              <S.Animation ref={loadingAnimationRef} />
              <S.Title>{t`paymentLong.paymentWaiting.processing`}</S.Title>
              <S.Subtitle>
                <Trans i18nKey="paymentLong.paymentWaiting.processingPayment" />
              </S.Subtitle>
            </>
          )}
          {animationState === AnimationState.SUCCESS && (
            <>
              <S.Animation ref={completeAnimationRef} />
              <S.Title>
                {t`paymentLong.paymentWaiting.paymentWasSuccessful`}
              </S.Title>
              {successSubtitle && (
                <S.Subtitle>
                  <Trans i18nKey={successSubtitle} />
                </S.Subtitle>
              )}
              <S.SuccessButton type="button" onClick={handleSuccessButtonClick}>
                {buttonText || t`purchase.payment.toDownloadPage`}
              </S.SuccessButton>
            </>
          )}
          {animationState === AnimationState.ERROR && (
            <>
              <S.Animation ref={errorAnimationRef} />
              <S.ErrorTitle>
                {t`paymentLong.paymentWaiting.errorDuringPayment`}
              </S.ErrorTitle>
              <S.Subtitle>
                {error?.description || error || DEFAULT_ERROR_DESCRIPTION}
              </S.Subtitle>
              <S.ErrorButton type="button" onClick={handleResetError}>
                {errorButtonText || t`actions.tryAgain`}
              </S.ErrorButton>
            </>
          )}
        </S.LottieContainer>
      </S.Content>
    </S.Wrapper>
  )
}

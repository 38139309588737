import React from 'react'
import { Trans } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { SUPPORT_MAIL } from 'root-constants'

import { CURRENCY_SYMBOLS } from '../../constants'
import { usePurchaseStore } from '../../hooks'
import { StyledDisclaimer } from './Disclaimer.styles'

export const DisclaimerV2 = () => {
  const { currency, price } = usePurchaseStore()

  return (
    <StyledDisclaimer>
      <Trans
        i18nKey="purchaseLong.disclaimerMonthlyV2"
        values={{
          currency: CURRENCY_SYMBOLS[currency],
          price,
          email: SUPPORT_MAIL,
        }}
        components={{
          email: <a href={`mailto: ${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a>,
          subscriptionTerms: (
            <TermsOfUseLink translationKey="commonComponents.subscriptionPolicy" />
          ),
        }}
      />
    </StyledDisclaimer>
  )
}
